const getters = {
  sidebar: state => state.app.sidebar,
  customerid: state => state.app.customerid,
  fathercustomerid: state => state.app.fathercustomerid,
  customername: state => state.app.customername,
  usercustomerid: state => state.user.userCustomerId,
  usercustomerfatherid: state => state.user.userCustomerFatherId,
  token: state => state.user.token,
  username: state => state.user.name,
  roles: state => state.user.roles,
  roletype: state => state.user.roletype,
  mobilephone: state => state.user.mobilephone,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters
}
export default getters