<!--  -->
<template>
  <div class="ArticleDetail">
      <div class="btn"><el-button @click="toHome">返回列表</el-button></div>
    <h1 class="title">{{ArticleDetail.Title}}</h1>
    <div class="publishtime">发布时间：{{ArticleDetail.CreatedAt}}</div>
    <div class="picture">
      <img alt v-if="ArticleDetail.Image!=null" :src="ArticleDetail.picture.src" />
      <img v-else :src="defultimg" />
    </div>
    <div class="content" v-html="ArticleDetail.Content"></div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {

    //这里存放数据
    return {
      defultimg: require('@/assets/defultimg.png'),
      ArticleDetail: {
        TiTle: '',
        Content: '',
        Image: '',
        picture: new Image(),
        CreatedAt:new Date()
      }

    };
  },
  //方法集合
  methods: {
    //获取宣传内容
    getAdvert() {
      this.axios.get("/admin/advert").then(reply => {
        if (reply.data.code === 0) {
          this.ArticleDetail = reply.data.values.list.find(advert => {
            return advert.Id = this.$route.params.id
          })
           this.ArticleDetail.CreatedAt = this.$moment().format('YYYY年MM月DD日 hh:mm')
          this.ArticleDetail.picture = new Image()
          console.log(this.ArticleDetail)
          if (this.ArticleDetail.Image != null) {
            this.ArticleDetail.picture.src = '/uploads/' + this.ArticleDetail.Image
          } else {
            this.ArticleDetail.picture.src = this.defaultImg
          }
        } else {
          this.$message({
            message: `查询出错，错误码${reply.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },

    toHome(){
        this.$router.push('/')
    }

  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getAdvert()

  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.ArticleDetail {
  padding: 15px;
  .btn{
      float: right;
  }
  .publishtime{
      height: 30px;
    background: #f4f4f4;
    line-height: 30px;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;

  }
  .picture {
    text-align: center;
    margin:20px 0;
    img {
      min-width: 200px;
      max-width: 500px;
    }
  }
  .title {
    text-align: center;
  }
  .content {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}
</style>