<template>
    <section>
        <!--工具条-->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
            <el-form :inline="true" :model="search" :rules="searchRules">
                <el-form-item>
                    <el-select v-model="search.operationtype" placeholder="请选择操作类型" clearable filterable>
                        <el-option v-for="item in operationOption" :label="item.operation" :key="item.operation" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="roletype == 2 || roletype == 4">
                    <el-select v-model="search.userid" placeholder="请选择用户" clearable filterable>
                        <el-option v-for="item in userModels" :label="item.username" :key="item.userid" :value="item.userid"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <template>
                        <span>起止时间</span>
                        <el-date-picker
                            v-model="search.daterange"
                            type="datetimerange"
                            format="yyyy-MM-dd HH:mm"
                            :picker-options="pickerOptions"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                        >
                        </el-date-picker>
                    </template>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!--列表-->
        <el-table :data="resultList" v-loading="listLoading" stripe highlight-current-row>
            <el-table-column type="index" width="65"> </el-table-column>
            <el-table-column prop="CreatedAt" :formatter="formatTime" label="操作时间" sortable> </el-table-column>
            <el-table-column prop="OperationType" label="操作类型" :formatter="formatType" sortable> </el-table-column>
            <el-table-column prop="UserId" label="操作人员">
                <template slot-scope="scope">
                    <el-tag>{{ scope.row.Account.UserName }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IP" label="IP"> </el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24">
            <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" :current-page="page" style="float:right;"> </el-pagination>
        </el-col>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import NProgress from 'nprogress'
import moment from 'moment'
const operationType = {
    1: '设备变更操作',
    2: '传感器变更操作',
    3: '库房变更操作',
    11: '超标报警变更操作',
    12: '离线报警变更操作',
    13: '断电报警变更操作',
    14: '低电压报警变更操作',
    15: '运输报警变更操作',
}
export default {
    data() {
        return {
            search: {
                daterange: [moment().add(-7, 'days'), moment()],
                userid: null,
                operationtype: null,
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '一天前',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24)
                            picker.$emit('pick', [start, end])
                        },
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            picker.$emit('pick', [start, end])
                        },
                    },
                    {
                        text: '最近半个月',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
                            picker.$emit('pick', [start, end])
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit('pick', [start, end])
                        },
                    },
                ],
            },
            total: 0,
            page: 1,
            size: 10,
            listLoading: false,
            searchRules: {},
            resultList: [],
            userModels: [],
            operationOption: [
                { id: 1, operation: '设备变更操作' },
                { id: 2, operation: '传感器变更操作' },
                { id: 3, operation: '库房变更操作' },
                { id: 11, operation: '超标报警变更操作' },
                { id: 12, operation: '离线报警变更操作' },
                { id: 13, operation: '断电报警变更操作' },
                { id: 14, operation: '低电压报警变更操作' },
                { id: 15, operation: '运输报警变更操作' },
            ],
        }
    },
    computed: {
        ...mapGetters(['customerid', 'roletype']),
        searchUseable: function() {
            if (this.search.daterange == '' || this.search.daterange == null) return true
            else return false
        },
    },
    methods: {
        formatTime: (row) => {
            return moment(row.CreatedAt).format('MM月DD日 HH:mm')
        },
        formatType: (row) => {
            return operationType[row.OperationType]
        },

        resetPageAndSearch() {
            this.page = 1
            this.getList()
        },

        handleCurrentChange(val) {
            this.page = val
            this.getList()
        },

        getList() {
            console.log('getList')
            let from, to
            if (this.search.daterange && this.search.daterange.length && this.search.daterange[0] !== null) {
                from = moment(this.search.daterange[0]).format('yyyy-MM-DD HH:mm')
                to = moment(this.search.daterange[1]).format('YYYY-MM-DD HH:mm')
            }

            let params = {
                from,
                to,
                page: this.page,
                size: this.size,
                customerid: this.customerid,
                userid: this.search.userid,
                operationtype: this.search.operationtype,
            }
            this.listLoading = true

            NProgress.start()
            this.axios.post('/supervisor/operation', params).then((res) => {
                this.listLoading = false
                NProgress.done()
                if (res.data.code === 0) {
                    this.resultList = res.data.values.operationlogs.rows
                    this.total = res.data.values.operationlogs.count
                } else {
                    console.log(JSON.stringify(res.data.error))
                    this.$message({
                        message: `查询出错，错误码${res.data.code},请联系管理员`,
                        type: 'warning',
                    })
                }
            })
        },
        //获取人员
        getUser() {
            this.axios
                .post('/usermanagement/getusers', {
                    name: '',
                    customerid: this.customerid,
                })
                .then((reply) => {
                    this.userModels = reply.data.values.users
                })
        },
    },
    mounted() {
        if (this.roletype == 2 || this.roletype == 4) this.getUser()
        this.getList()
    },
}
</script>
