<!--
 * @Date: 2022-07-22 11:49:05
 * @LastEditors: zhouhua
 * @LastEditTime: 2022-07-28 20:28:20
 * @FilePath: \coldcloud-vue-web\src\pages\cloudwarehouse\alarmTab\OverAlarm.vue
-->
<template>
    <div>
        <el-table :data="overalarmlist" v-loading="loading" style="width: 100%;" stripe highlight-current-row>
            <el-table-column prop="sensorcode" label="传感器号" min-width="180" show-overflow-tooltip></el-table-column>
            <el-table-column prop="phone" label="报警短信号码" min-width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.phone | phoneFilter(phoneObj) }}
                </template>
            </el-table-column>
            <el-table-column prop="alarminterval" label="连续报警间隔(分)" min-width="150"></el-table-column>
            <el-table-column prop="issmsenable" label="短信报警" :formatter="formatSMSEnable" min-width="150"></el-table-column>
            <el-table-column prop="iswechatenable" label="微信报警" :formatter="formatWeChatEnable" min-width="150"></el-table-column>
            <el-table-column prop="isappenable" label="APP报警" :formatter="formatAppEnable" min-width="150"></el-table-column>
            <el-table-column prop="iswarningenable" label="预警功能" :formatter="formatEnable" min-width="150"></el-table-column>
            <el-table-column prop="iswarningsmsenable" label="预警短信报警" :formatter="formatEnable" min-width="150"></el-table-column>
            <el-table-column prop="iswarningwechatenable" label="预警微信报警" :formatter="formatEnable" min-width="150"></el-table-column>
            <el-table-column prop="iswarningappenable" label="预警APP报警" :formatter="formatEnable" min-width="150"></el-table-column>
            <el-table-column prop="alarmmode" label="报警类型" :formatter="formatMode" min-width="150"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150" min-width="150">
                <template slot-scope="scope">
                    <el-button :disabled="roletype > 4" size="small" @click="dataFormShow('edit', scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button :disabled="roletype > 4" type="warning" size="small" @click="handleDel(scope.$index, scope.row)">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="toolbar">
            <el-button type="primary" @click="dataFormShow('add')" :disabled="roletype > 4">新增</el-button>
        </el-col>
        <!--编辑超标报警界面-->
        <el-dialog :title="title" :visible.sync="dataFormVisible" :close-on-click-modal="false">
            <el-form :model="dataForm" label-width="120px" :rules="dataFormRules" ref="dataForm">
                <el-form-item label="传感器号" prop="sensorid">
                    <el-select v-model="dataForm.sensorid" auto-complete="off" multiple>
                        <el-option v-for="item in sensorsOptionList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报警号码" prop="phone">
                    <el-select v-model="dataForm.phone" multiple filterable allow-create :filter-method="handleFilter" placeholder="请选择">
                        <el-option v-for="item in filterOptions" :key="item.name" :label="item.name" :value="item.phone"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="连续报警间隔" prop="alarminterval" required>
                    <el-input-number v-model.number="dataForm.alarminterval" :step="1" :min="1" :max="120"></el-input-number>
                    <span>分钟</span>
                </el-form-item>
                <el-form-item label="启用短信" prop="issmsenable" required>
                    <el-switch v-model="dataForm.issmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用微信" prop="iswechatenable" required>
                    <el-switch v-model="dataForm.iswechatenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用APP" prop="isappenable" required>
                    <el-switch v-model="dataForm.isappenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="预警功能" prop="iswarningenable" required>
                    <el-switch v-model="dataForm.iswarningenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用预警短信" prop="iswarningsmsenable" required>
                    <el-switch v-model="dataForm.iswarningsmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用预警微信" prop="iswarningwechatenable" required>
                    <el-switch v-model="dataForm.iswarningwechatenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用预警APP" prop="iswarningappenable" required>
                    <el-switch v-model="dataForm.iswarningappenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="报警类型" prop="alarmmode" required>
                    <el-radio-group v-model="dataForm.alarmmode">
                        <el-radio :label="0">温湿度报警</el-radio>
                        <el-radio :label="1">温度报警</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dataFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="comfirmLoading">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import NProgress from 'nprogress'
import validator from 'validator'
export default {
    name: 'OverAlarmTable',
    props: {
        formatSMSEnable: { type: Function },
        formatWeChatEnable: { type: Function },
        formatEnable: { type: Function },
        formatAppEnable: { type: Function },
        formatMode: { type: Function },
        validateSMSPhone: { type: Function },
        roletype: { type: Number },
        customerid: { type: Number },
        phoneList: { type: Array },
    },
    filters: {
        phoneFilter(phone, phoneObj) {
            let newArr = phone.split(',').map((e) => (Object.prototype.hasOwnProperty.call(phoneObj, e) ? phoneObj[e] : e))
            return newArr.join()
        },
    },
    data() {
        const validatorPhone = (rule, value, callback) => {
            console.log('🚀 ~ file: OverAlarm.vue ~ line 116 ~ validatorPhone ~ value', value)
            if (value.length > 0) {
                value.forEach((phone) => {
                    if (!validator.isMobilePhone(phone, 'zh-CN')) {
                        return callback(new Error('请输入正确的手机号码'))
                    }
                })
            }
            return callback()
        }
        return {
            overalarmlist: [], //超标报警
            sensorsOptionList: [], //新增/编辑 超标传感器选择下拉列表
            originSensorsOptionList: [], //新增/编辑 超标传感器选择下拉列表
            editedsensorid: [],
            loading: false,
            dataFormVisible: false,
            title: '',
            formType: {
                add: '新增超标报警',
                edit: '编辑超标报警',
            },
            comfirmLoading: false,
            //编辑超标界面数据
            dataForm: {
                sensorid: [],
                phone: [],
                alarminterval: '',
                issmsenable: true,
                iswechatenable: true,
                isappenable: true,
                iswarningenable: false,
                iswarningsmsenable: false,
                iswarningwechatenable: false,
                iswarningappenable: false,
                alarmmode: 0,
            },
            dataFormRules: {
                sensorid: [
                    {
                        type: 'array',
                        required: true,
                        message: '请选择传感器',
                        trigger: 'change',
                    },
                ],
                phone: [
                    {
                        validator: validatorPhone,
                        trigger: 'change',
                    },
                ],
            },
            phoneObj: {},
            originPhoneList: [],
            filterOptions: [],
        }
    },
    components: {},
    watch: {
        phoneList: function(val) {
            this.originPhoneList = this.filterOptions = val.map((e) => {
                let obj = {
                    name: e.Name,
                    phone: e.Phone,
                }
                return obj
            })
            val.forEach((e) => {
                this.phoneObj[e.Phone] = e.Name
            })
        },
    },
    mounted() {
        this.getCloudOverAlarmInfo()
    },
    methods: {
        // 号码人员筛选
        handleFilter(val) {
            console.log(val)
            let restaurants = this.originPhoneList
            let results = val ? restaurants.filter((e) => e.name.includes(val) || e.phone.includes(val)) : restaurants
            console.log('results', results)
            this.filterOptions = results
        },
        //获取超标报警数据
        getCloudOverAlarmInfo() {
            let para = {
                customerid: this.customerid,
            }
            this.loading = true
            NProgress.start()
            this.axios.post('/cloudmanagement/getcloudsensors', para).then((reply) => {
                this.originSensorsOptionList = this.sensorsOptionList = reply.data.values.sensors
                this.axios.post('/cloudmanagement/getalarmruleslist', para).then((reply) => {
                    this.loading = false
                    NProgress.done()
                    if (reply.data.code === 0) {
                        if (reply.data.total === 0)
                            this.$message({
                                message: `没有数据`,
                                type: 'warning',
                            })
                        this.overalarmlist = reply.data.values.ruleslist
                        this.originSensorsOptionList = this.sensorsOptionList.map((s) => {
                            s.disabled = false
                            this.overalarmlist.map((rule) => {
                                rule.sensorid.map((a) => {
                                    if (a == s.value) {
                                        s.disabled = true
                                    }
                                })
                            })
                            return s
                        })
                    } else {
                        this.$message({
                            message: `查询出错，错误码${reply.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },

        //删除超标
        handleDel: function(index, row) {
            this.$confirm('确认删除该设备吗?', '提示', {
                type: 'warning',
            }).then(() => {
                let params = {}
                params = Object.assign({}, row)
                this.loading = true
                NProgress.start()
                this.axios.post('/cloudmanagement/deletealarmrule', params).then((res) => {
                    this.loading = false
                    NProgress.done()
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success',
                        })
                        this.getCloudOverAlarmInfo()
                    } else if (res.data.code === 102) {
                        this.$message({
                            message: '您的权限不足',
                            type: 'warning',
                        })
                    } else {
                        this.$message({
                            message: `删除失败，错误码${res.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },
        //显示超标界面
        dataFormShow: function(type, row) {
            this.dataFormVisible = true
            this.title = this.formType[type]
            if (type == 'edit') {
                this.dataForm = Object.assign(this.dataForm, row)
                this.dataForm.issmsenable = !!row.issmsenable
                this.dataForm.iswechatenable = !!row.iswechatenable
                this.dataForm.isappenable = !!row.isappenable
                this.dataForm.iswarningenable = !!row.iswarningenable
                this.dataForm.iswarningsmsenable = !!row.iswarningsmsenable
                this.dataForm.iswarningwechatenable = !!row.iswarningwechatenable
                this.dataForm.iswarningappenable = !!row.iswarningappenable
                this.editedsensorid = this.dataForm.sensorid = row.sensorid.map((n) => Number(n))
                this.dataForm.phone = row.phone.length ? row.phone.split(',') : []
            } else {
                this.dataForm = {
                    sensorid: [],
                    phone: '',
                    alarminterval: '',
                    issmsenable: true,
                    iswechatenable: true,
                    isappenable: true,
                    iswarningenable: false,
                    iswarningsmsenable: false,
                    iswarningwechatenable: false,
                    iswarningappenable: false,
                    customerid: '',
                    alarmmode: 0,
                }
            }
            // 先深拷贝还原初始值
            this.sensorsOptionList = JSON.parse(JSON.stringify(this.originSensorsOptionList))
            row &&
                this.sensorsOptionList.forEach((s) => {
                    row.sensorid.map((a) => {
                        if (a == s.value) {
                            s.disabled = false
                        }
                    })

                    return s
                })
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate()
            })
        },

        //超标编辑
        handleSubmit: function() {
            this.$refs.dataForm.validate((valid) => {
                let params = {}
                Object.assign(params, this.dataForm, {
                    editedsensorid: this.editedsensorid,
                })
                if (!this.validateSMSPhone(params)) {
                    return this.$message({
                        message: `必须输入手机号码`,
                        type: 'warning',
                    })
                }
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.comfirmLoading = true
                        NProgress.start()
                        params.phone = params.phone.length ? params.phone.join(',') : ''
                        params.issmsenable = params.issmsenable ? 1 : 0
                        params.iswechatenable = params.iswechatenable ? 1 : 0
                        params.isappenable = params.isappenable ? 1 : 0
                        params.iswarningenable = params.iswarningenable ? 1 : 0
                        params.iswarningsmsenable = params.iswarningsmsenable ? 1 : 0
                        params.iswarningwechatenable = params.iswarningwechatenable ? 1 : 0
                        params.iswarningappenable = params.iswarningappenable ? 1 : 0
                        let api = !params.ruleid ? '/cloudmanagement/addalarmrule' : '/cloudmanagement/editalarmrule'
                        this.axios
                            .post(api, params)
                            .then((res) => {
                                this.comfirmLoading = false
                                NProgress.done()
                                if (res.data.code === 0) {
                                    this.$notify({
                                        title: '成功',
                                        message: params.ruleid ? '编辑成功' : '新增成功',
                                        type: 'success',
                                    })
                                    this.$refs['dataForm'].resetFields()
                                    this.dataFormVisible = false
                                    this.getCloudOverAlarmInfo()
                                } else if (res.data.code === 102) {
                                    this.$message({
                                        message: '您的权限不足',
                                        type: 'warning',
                                    })
                                } else {
                                    this.$message({
                                        message: `保存失败，错误码${res.data.code},请联系管理员`,
                                        type: 'warning',
                                    })
                                }
                            })
                            .catch((e) => {})
                            .finally(() => {
                                this.comfirmLoading = false
                            })
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>
