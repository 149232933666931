<template>
  <el-form style="margin:10px;">
    <el-form-item label="用户列表">
      <el-select v-model="selectedUser" placeholder="请选择用户" @change="userChange">
        <el-option v-for="item in usersList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="权限分配">
      <el-transfer v-model="bindingDevice" :data="devicesList" :titles="['未绑定设备', '已绑定设备']" @change="handleSubmit">
      </el-transfer>
    </el-form-item>
  </el-form>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      usersList: [],
      selectedUser: null,
      devicesList: [],
      bindingDevice: [],
      listLoading: false
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"])
  },
  methods: {
    userChange(selectedVal) {
      this.axios
        .post("/usermanagement/getbinding", {
          userid: selectedVal,
          customerid: this.customerid
        })
        .then(reply => {
          this.bindingDevice = reply.data.values.devices.map(
            device => device.deviceid
          );
        });
    },
    handleSubmit(valArray, direction) {
      if (this.selectedUser == null) {
        return this.$message({
          message: `请选择用户`,
          type: "warning"
        });
      }
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/usermanagement/setbinding", {
          userid: this.selectedUser,
          customerid: this.customerid,
          binding: this.bindingDevice
        })
        .then(reply => {
          if (reply.data.code === 0) {
            this.$notify({
              message: `绑定成功`,
              type: "success"
            });
          } else {
            this.$message({
              message: `查询出错，错误码${reply.data.code},请联系管理员`,
              type: "warning"
            });
            this.userChange(this.selectedUser);
          }
        });
    }
  },
  mounted() {
    this.axios
      .post("/usermanagement/getcommonusers", {
        customerid: this.customerid
      })
      .then(reply => {
        if (reply.data.code === 0) {
          this.usersList = reply.data.values.users;
          this.devicesList = reply.data.values.devices;
        } else {
          this.$message({
            message: `查询出错，错误码${reply.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
  }
};
</script>

<style lang="scss" scoped>
  .el-transfer ::v-deep .el-transfer-panel {
    width: 360px;
  }
</style>

