/*
 * @Author: zhou hua
 * @Date: 2021-06-20 14:23:20
 * @LastEditTime: 2022-08-05 11:27:13
 * @LastEditors: zhouhua
 * @Description:
 * @FilePath: \coldcloud-vue-web\src\utils\fetch.js
 */
import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
const service = axios.create({
    baseURL: process.env.BASE_API, // api的base_url
    // baseURL: 'http://47.100.233.43:3007/', // api的base_url
    timeout: 90000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
        }
        return config
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    (response) => {
        return response
    },

    (error) => {
        console.log('http error: ' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 3 * 1000,
        })
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    store.dispatch('LogOut').then(() => {
                        next({
                            path: '/login',
                        })
                    })
            }
        }
        return Promise.reject(error)
    }
)

export default service
