<template>
  <section>
    <!--工具条-->
    <el-col :span="24" style="padding-bottom: 0px;">
      <el-form :inline="true" :rules="searchRules">
        <el-form-item>
          <span>选择仓库</span>
          <el-select v-model="search.warehouse" placeholder="关键字搜索" clearable filterable>
            <el-option
              v-for="item in warehousesList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template>
            <span>起止时间</span>
            <el-date-picker
              v-model="search.daterange"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            ></el-date-picker>
          </template>
        </el-form-item>
        <el-form-item>
          <span>合并报警数据</span>
          <el-switch v-model="search.alarmflag"></el-switch>
        </el-form-item>

        <el-form-item>
          <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-switch
            inactive-icon-class="el-icon-s-grid"
            inactive-color="#409EFF"
            inactive-value="grid"
            active-value="map"
            active-icon-class="el-icon-map-location"
            v-model="mode"
          ></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="$router.push('/datacenter/histable')" type="text">旧版查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24">
      <transition>
        <div v-if="mode=='grid'">
          <el-table
            :data="resultList"
            v-loading="listLoading"
            style="width: 100%;"
            stripe
            highlight-current-row
          >
            <el-table-column type="index" width="54"></el-table-column>
            <el-table-column prop="operdate" label="采集时间" width="140" sortable></el-table-column>
            <el-table-column prop="sensorcode" label="传感器号" width="130" min-width="130" sortable></el-table-column>
            <el-table-column prop="warehousename" label="库房" width="150"></el-table-column>
            <el-table-column label="温度(℃)" width="100" min-width="100">
              <template slot-scope="scope">
                <span
                  style="color:red"
                  v-if="scope.row.isover&0b0010"
                >{{ Number(scope.row.temperature).toFixed(2) }} ↑</span>
                <span
                  style="color:red"
                  v-else-if="scope.row.isover&0b0001"
                >{{ Number(scope.row.temperature).toFixed(2) }} ↓</span>
                <span v-else>{{ Number(scope.row.temperature).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="humidity" label="湿度(%)" width="100" min-width="100">
              <template slot-scope="scope">
                <span
                  style="color:red"
                  v-if="scope.row.isover&0b1000"
                >{{ Number(scope.row.humidity).toFixed(2) }} ↑</span>
                <span
                  style="color:red"
                  v-else-if="scope.row.isover&0b0100"
                >{{ Number(scope.row.humidity).toFixed(2) }} ↓</span>
                <span
                  v-else
                >{{ scope.row.humidity==-100?"---":Number(scope.row.humidity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="vcc" label="电压" min-width="90">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.vcc>=3">{{scope.row.vcc}} V</el-tag>
                <el-tag type="warning" v-else>{{scope.row.vcc}} V</el-tag>
              </template>
            </el-table-column>

            <el-table-column prop="dbm" label="信号" min-width="100">
              <template slot-scope="scope">
                <span>{{scope.row.dbm}} dBm</span>
              </template>
            </el-table-column>
            <el-table-column prop="mintemperature" label="温度下限" width="105" min-width="105"></el-table-column>
            <el-table-column prop="maxtemperature" label="温度上限" width="105" min-width="105"></el-table-column>
            <el-table-column prop="minhumidity" label="湿度下限" width="105" min-width="105">
              <template slot-scope="scope">
                <span v-if="scope.row.minhumidity==-100">---</span>
                <span v-else>{{scope.row.minhumidity}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="maxhumidity" label="湿度上限" width="105" min-width="105">
              <template slot-scope="scope">
                <span v-if="scope.row.maxhumidity==-100">---</span>
                <span v-else>{{scope.row.maxhumidity}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注" min-width="150"></el-table-column>
          </el-table>
        </div>
        <div class="map-container" v-else>
          <card-map :points="resultList" style="height:100%" />
        </div>
      </transition>
    </el-col>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import NProgress from "nprogress";
import CardMap from "../../components/baiduMap.vue";
export default {
  components: { CardMap },
  data() {
    return {
      page: 1,
      total: 0,
      mode: "grid",
      warehousesList: [],
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 3600 * 1000 * 24 * 365 * 5 - 8.64e7
          ); //最多五年前
        },
        shortcuts: [
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      search: { warehouse: "", daterange: null, alarmflag: false },
      searchRules: {},
      resultList: [],
      listLoading: false
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    searchUseable: function() {
      if (this.search.warehouse == "" || this.search.daterange == null)
        return true;
      else return false;
    }
  },
  mounted() {
    this.loadCardDevices();
  },
  methods: {
    loadCardDevices() {
      let para = {
        customerid: this.customerid
      };
      NProgress.start();
      this.axios.post("/datacenter/getdeviceslist", para).then(res => {
        NProgress.done();
        res.data.values.deviceslist.map(dev => {
          if (dev.devicetype == 7) {
            this.warehousesList.push({
              value: dev.warehouseid,
              label: dev.warehousename
            });
          }
        });
      });
    },
    resetPageAndSearch() {
      if (this.mode == "map") {
        this.page = 0;
        this.searchHistoryList();
      } else {
        this.page = 1;
        this.searchHistoryList();
      }
    },
    searchHistoryList() {
      NProgress.start();
      let starttime = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let endtime = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let warehouseid = this.search.warehouse;
      let isintervalrecord = this.search.alarmflag ? 1 : 0;
      let para = {
        customerid: this.customerid,
        page: this.page,
        starttime,
        endtime,
        warehouseid,
        isintervalrecord
      };
      this.axios.post("/datacenter/cardhistory", para).then(res => {
        NProgress.done();
        this.total = res.data.values.count;
        this.resultList = res.data.values.rows;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.map-container {
  height: calc(100vh - 220px);
}
</style>