<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="库房名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getCloudDeviceInfo">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="roletype>4" type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table :data="getclouddevicelist" style="width: 100%" stripe v-loading="listLoading">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-table :data="scope.row.sensorslist" style="width: 100%">
            <el-table-column prop="devicename" label="设备名" width="120"></el-table-column>
            <el-table-column prop="sensorcode" label="传感器号" width="100"></el-table-column>
            <el-table-column prop="isenable" label="状态" :formatter="formatEnable"></el-table-column>
            <el-table-column prop="mintemperature" label="温度下限(℃)" min-width="110"></el-table-column>
            <el-table-column prop="maxtemperature" label="温度上限(℃)" min-width="110"></el-table-column>
            <el-table-column prop="minhumidity" label="湿度下限(%)" min-width="120"></el-table-column>
            <el-table-column prop="maxhumidity" label="湿度上限(%)" min-width="120"></el-table-column>
            <el-table-column
              prop="createdat"
              :formatter="formatCreatedAt"
              label="安装日期"
              min-width="170"
            ></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="warehousename" label="库房名称" min-width="180"></el-table-column>
      <el-table-column
        prop="warehousetype"
        label="库房类型"
        :formatter="formatWarehouse"
        min-width="150"
      ></el-table-column>
      <el-table-column label="操作" width="150" min-width="150">
        <template slot-scope="scope">
          <el-button
            :disabled="roletype>4"
            size="small"
            @click="handleEdit(scope.$index, scope.row)"
          >
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button
            :disabled="roletype>4"
            type="warning"
            size="small"
            @click="handleDel(scope.$index, scope.row)"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <!--<el-col :span="24">
							<el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
							</el-pagination>
						</el-col>
    -->
    <!--编辑界面-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm">
        <el-form-item label="库房名称" prop="warehousename">
          <el-input v-model="editForm.warehousename"></el-input>
        </el-form-item>
        <el-form-item label="库房类型">
          <el-select
            v-model="editForm.warehousetype"
            placeholder="选择库房类型"
            @change="editselectModel"
            clearable
          >
            <el-option
              v-for="item in warehousemodels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="传感器" prop="sensorid" width="100%">
          <template>
            <el-select v-model="editForm.sensorid" multiple placeholder="请选择传感器">
              <el-option
                v-for="item in sensorsOptionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm">
        <el-form-item label="库房名称" prop="warehousename">
          <el-input v-model="addForm.warehousename"></el-input>
        </el-form-item>
        <el-form-item label="库房类型">
          <el-select
            v-model="addForm.warehousetype"
            placeholder="选择库房类型"
            @change="addselectModel"
            clearable
          >
            <el-option
              v-for="item in warehousemodels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="传感器" prop="sensorid">
          <template>
            <el-select v-model="addForm.sensorid" multiple placeholder="请选择传感器">
              <el-option
                v-for="item in sensorsOptionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filters: {
        name: ""
      },
      getclouddevicelist: [],

      warehousemodels: [
        {
          label: "云库房",
          value: 2
        },
        {
          label: "保温箱",
          value: 3
        },
        {
          label: "冷藏车",
          value: 4
        },
        {
          label: "STL冷链箱",
          value: 6
        }
      ], //新增/编辑 库房类型下拉列表
      sensorsOptionList: [], //新增/编辑 传感器选择下拉列表
      originSensorsOptionList: [], //新增/编辑 传感器选择下拉列表
      total: 0,
      //  page: 1,
      listLoading: false,

      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        warehousename: [
          {
            required: true,
            message: "请输入库房名称",
            trigger: "blur"
          }
        ]
      },
      //编辑界面数据
      editForm: {
        warehouseid: "",
        warehousename: "",
        warehousetype: "",
        deviceid: "",
        phone: "",
        sensorid: [],
        datainterval: ""
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        warehousename: [
          {
            required: true,
            message: "请输入库房名称",
            trigger: "blur"
          }
        ],
        warehousetype: [
          {
            type: "array",
            required: true,
            message: "请选择库房类型",
            trigger: "blur"
          }
        ]
      },
      //新增界面数据
      addForm: {
        warehousename: "",
        warehousetype: "",
        sensorid: [],
        phone: "",
        datainterval: ""
      }
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"])
  },
  methods: {
    editselectModel(index) {
      Object.assign(this.editForm, this.warehousemodels[index]);
    },
    addselectModel(index) {
      Object.assign(this.addForm, this.warehousemodels[index]);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCloudDeviceInfo();
    },
    formatCreatedAt: (row, column) => {
      return new Date(row.createdat).format("yyyy年mm月dd日");
    },
    formatEnable: (row, column) => {
      return (row.isenable = true ? "启用" : "停用");
    },
    formatWarehouse(row, column) {
      return this.warehousemodels.map(x => {
        if (x.value == row.warehousetype) return x.label;
      });
    },
    //获取云设备列表
    getCloudDeviceInfo() {
      let para = {
        customerid: this.customerid,
        name: this.filters.name
      };
      this.listLoading = true;
      NProgress.start();
      this.axios.post("/cloudmanagement/getcloudsensors", para).then(reply => {
        this.originSensorsOptionList = this.sensorsOptionList =
          reply.data.values.sensors;
      });
      this.axios
        .post("/cloudmanagement/getcloudwarehouseslist", para)
        .then(reply => {
          this.listLoading = false;
          NProgress.done();
          if (reply.data.code === 0) {
            this.getclouddevicelist = reply.data.values.warehouseslist;
          } else {
            this.$message({
              message: `查询出错，错误码${res.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    },
    //删除
    handleDel: function(index, row) {
      this.$confirm("<strong>删除该设备后，原有历史数据将一并删除！此操作将永久删除该设备或库房,请务必谨慎操作！！是否继续?", "提示", {
        dangerouslyUseHTMLString: true,
        type: "warning"
      }).then(() => {
        let params = {
          warehouseid: row.warehouseid
        };
        this.listLoading = true;
        NProgress.start();
        this.axios
          .post("/cloudmanagement/deletecloudwarehouse", params)
          .then(res => {
            this.listLoading = false;
            NProgress.done();
            if (res.data.code === 0) {
              this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success"
              });
              this.getCloudDeviceInfo();
            } else if (res.data.code === 102) {
              this.$message({
                message: "您的权限不足",
                type: "warning"
              });
            } else {
              this.$message({
                message: `删除失败，错误码${res.data.code},请联系管理员`,
                type: "warning"
              });
            }
          });
      });
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign(this.editForm, row);
      this.editForm.sensorid = [];
      row.sensorslist.map(sensor => {
        this.editForm.sensorid.push(sensor.sensorid);
      });
      // 先深拷贝还原初始值
      this.sensorsOptionList = JSON.parse(
        JSON.stringify(this.originSensorsOptionList)
      );

      this.sensorsOptionList.forEach(sensor => {
        row.sensorslist.map(s => {
          if (s.sensorid == sensor.value) {
            sensor.disabled = false;
          }
        });
        return sensor;
      });
    },
    //编辑
    editSubmit: function() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.editForm);
            this.axios
              .post("/cloudmanagement/editcloudwarehouse", params)
              .then(res => {
                this.editLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$notify({
                    title: "成功",
                    message: "编辑成功",
                    type: "success"
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getCloudDeviceInfo();
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "您的权限不足",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存失败，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    },
    //显示新增界面
    handleAdd: function() {
      this.addFormVisible = true;
      this.addForm = {
        warehousename: "",
        warehousetype: "",
        datainterval: "",
        sensorid: [],
        isenable: true
      };
      this.sensorsOptionList = JSON.parse(
        JSON.stringify(this.originSensorsOptionList)
      );
    },
    //新增
    addSubmit: function() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.addForm);
            params.sensorinfos = [];
            
            this.axios
              .post("/cloudmanagement/addcloudwarehouse", params)
              .then(res => {
                this.addLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getCloudDeviceInfo();
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "保存失败，权限不足,请联系管理员",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存出错，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    }
  },
  mounted() {
    this.getCloudDeviceInfo();
  }
};
</script>
<style scope>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>