<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="传感器号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getLocalSensorsInfo">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd" :disabled="roletype>4">新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="localsensorslist" v-loading="listLoading" style="width: 100%;" stripe highlight-current-row>
      <el-table-column type="index" width="54">
      </el-table-column>
      <el-table-column prop="sensorcode" label="传感器号" min-width="120" sortable>
        <template slot-scope="scope">
          <div class="haslink" @click="loadWechatDialog(scope.row)">{{ scope.row.sensorcode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="sn" label="SN" sortable width="165" min-width="120">
      </el-table-column>
      <el-table-column label="安装位置" min-width="180" :formatter="formatAddr" sortable>
      </el-table-column>
      <el-table-column prop="isenable" label="状态" width="70" :formatter="formatEnable" min-width="70">
      </el-table-column>
      <el-table-column prop="createdat" :formatter="formatCreatedAt" label="安装日期" min-width="150">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150" min-width="150">
        <template slot-scope="scope">
          <el-button :disabled="roletype>4" size="small" @click="handleEdit(scope.$index, scope.row)">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button :disabled="roletype>4" type="warning" size="small" @click="handleDel(scope.$index, scope.row)">
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-col :span="24">
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="100px" ref="editForm">
        <el-form-item label="传感器号" prop="sensorcode">
          <el-input v-model="editForm.sensorcode"></el-input>
        </el-form-item>
        <el-form-item label="Serial No." prop="sn">
          <el-input v-model="editForm.sn">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">传感器唯一标识<br/>可用于绑定微信报警功能</div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>

        <el-form-item label="安装位置" required>
          <el-select v-model="editForm.warehouseid" placeholder="安装位置">
            <el-option v-for="item in warehousemodels" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用" prop="isenable" required>
          <el-switch v-model="editForm.isenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" label-width="100px" ref="addForm">
        <el-form-item label="传感器号" required>
        <el-input-number
            v-model="addForm.sensorcodestart"
            :min="1"
            :max="1024"
          ></el-input-number>-
          <el-input-number
            v-model="addForm.sensorcodeend"
            :min="1"
            :max="1024"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Serial No." prop="sn">
          <el-input v-model="addForm.sn">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">传感器唯一标识<br/>可用于绑定微信报警功能</div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item label="安装位置" required>
          <el-select v-model="addForm.warehouseid" placeholder="安装位置">
            <el-option v-for="item in warehousemodels" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用" prop="isenable" required>
          <el-switch v-model="addForm.isenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog title="微信报警管理" :visible.sync="wechatDialogVisible">
      <div v-loading="wechatDialogLoading">
        <div v-if="wechatUserList.length==0">暂无人员绑定微信报警</div>
        <div v-else>
          <wechat-head v-for="item in wechatUserList" :key="item.unionid" :imagesrc="item.headimgurl" :openid="item.openid" :sensorid="item.sensorid" :nickname="item.nickname" @update="loadWechatDialog(item)"></wechat-head>
        </div>
        <div class="weixin" slot="footer">
          <span>还没有绑定微信公众号？</span>
          <span style="color:#20a0ff;text-decoration: underline;">现在就扫码</span><img src="../../assets/qrcode_for_gh_8d11da1d3c1b_258.png" /></div>
      </div>
    </el-dialog>
  </section>
</template>
<style lang="scss" scoped>
.weixin {
  float: right;
  img {
    position: absolute;
    right: 0px;
    bottom: 30px;
    max-width: 200px;
    transform: scale(0);
    transform-origin: right bottom 2px;
    opacity: 1;
    border-radius: 0.25rem;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 999;
  }
}
.weixin:hover img {
  transform: scale(1);
  opacity: 1;
}
</style>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
import WechatHead from "@/components/wechathead";

export default {
  components: { WechatHead },
  data() {
    return {
      filters: {
        name: ""
      },
      localsensorslist: [],
      warehousemodels: [],
      total: 0,
      page: 1,
      listLoading: false,

      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editForm: {
        sensorcodestart:null,
        sensorcodeend:null,
        sensorcode: "",
        warehouseid: null,
        sn: "",
        isenable: true
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,

      //新增界面数据
      addForm: {
        sensorcodestart:null,
        sensorcodeend:null,
        sensorcode: "",
        warehouseid: null,
        sn: "",
        isenable: true
      },
      addFormRules: {
        sensorcode: [
          {
            required: true,
            message: "请输入传感器号",
            trigger: "blur"
          }
        ]
      },
      wechatDialogVisible: false,
      wechatDialogLoading: false,
      wechatUserList: []
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"])
  },
  methods: {
    loadWechatDialog(sensor) {
      this.wechatDialogVisible = true;
      this.wechatDialogLoading = true;
      this.axios.post("/wechatapi/snuserrelation", sensor).then(res => {
        this.wechatUserList = res.data.values.list;
        this.wechatDialogLoading = false;
        ``;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getLocalSensorsInfo();
    },
    formatAddr: (row, column) => {
      return `${row.warehousename}[${row.devicename}]`;
    },
    formatCreatedAt: (row, column) => {
      return new Date(row.createdat).format("yyyy年mm月dd日");
    },
    formatEnable: (row, column) => {
      return row.isenable ? "启用" : "停用";
    },

    //获取传感器管理列表
    getLocalSensorsInfo() {
      let para = {
        customerid: this.customerid,
        name: this.filters.name,
        page: this.page
      };
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/localmanagement/getlocalsensorslist", para)
        .then(res => {
          this.listLoading = false;
          NProgress.done();
          if (res.data.code === 0) {
            this.localsensorslist = res.data.values.sensorslist;
            this.total = res.data.values.count;
          } else {
            console.log(JSON.stringify(res.data.error));
            this.$message({
              message: `查询出错，错误码${res.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    },
    //删除
    handleDel: function(index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning"
      }).then(() => {
        this.listLoading = true;
        NProgress.start();
        let params = {
          sensorid: []
        };
        params.sensorid.push(row.sensorid)
        this.axios
          .post("/localmanagement/deletelocalsensor", params)
          .then(res => {
            this.listLoading = false;
            NProgress.done();
            if (res.data.code === 0) {
              this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success"
              });
              this.getLocalSensorsInfo();
            } else {
              this.$message({
                message: `删除失败，错误码${res.data.code},请联系管理员`,
                type: "warning"
              });
            }
          });
      });
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign({}, row);
      this.editForm.isenable = row.isenable ? true : false;
    },

    //编辑
    editSubmit: function() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            let params = Object.assign({}, this.editForm);
            params.isenable = params.isenable ? 1 : 0;
            this.warehousemodels.map(d => {
              if (d.value == params.warehouseid) {
                params.deviceid = d.deviceid;
                params.devicecode = d.devicecode;
                params.devicetype = d.devicetype;
              }
            });
            debugger;
            this.axios
              .post("/localmanagement/editlocalsensor", params)
              .then(res => {
                this.editLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$notify({
                    title: "成功",
                    message: "编辑成功",
                    type: "success"
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getLocalSensorsInfo();
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "您的权限不足",
                    type: "warning"
                  });
                } else if (res.data.code === 104) {
                  this.$message({
                    message: "传感器号重复",
                    type: "warning"
                  });
                } else if (res.data.code === 119) {
                  this.$message({
                    message: "传感器SN号重复",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存失败，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    },
    //显示新增界面
    handleAdd: function() {
      this.addFormVisible = true;
      this.addForm = {
        sensorcodestart: this.total + 1,
        sensorcodeend: this.total + 1,
        sensorcode: [],
        sn: "",
        customerid: this.customerid,
        warehouseid: null,
        isenable: true
      };
    },
    //新增
    addSubmit: function() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            let params = Object.assign({}, this.addForm);
            params.sensorcode=Array(params.sensorcodeend - params.sensorcodestart + 1).fill(0).map((v, i) => i + params.sensorcodestart)
            this.warehousemodels.map(d => {
              if (d.value == params.warehouseid) {
                params.deviceid = d.deviceid;
                params.devicecode = d.devicecode;
                params.devicetype = d.devicetype;
              }
            });
            params.isenable = params.isenable ? 1 : 0;
            this.axios
              .post("/localmanagement/addlocalsensor", params)
              .then(res => {
                this.addLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getLocalSensorsInfo();
                  this.$notify({
                    title: "成功",
                    message: "新增成功",
                    type: "success"
                  });
                } else if (res.data.code === 104) {
                  this.$message({
                    message: "保存失败，传感器号重复",
                    type: "warning"
                  });
                } else if (res.data.code === 119) {
                  this.$message({
                    message: "传感器SN号重复",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存出错，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    }
  },
  mounted() {
    this.getLocalSensorsInfo();
    let para = {
      customerid: this.customerid,
      name: ""
    };
    this.axios
      .post("/localmanagement/getlocalwarehouseslist", para)
      .then(reply => {
        this.warehousemodels = reply.data.values.warehouseslist.map(c => {
          return {
            label: `${c.devicename}:${c.warehousename}`,
            value: c.warehouseid,
            deviceid: c.deviceid,
            devicecode: c.devicecode,
            devicetype: c.devicetype
          };
        });
      });
  }
};
</script>
<style scoped>
.haslink{
    text-decoration:underline;
    color:#4091ff;
    cursor: pointer;
}
</style>