<template>
    <el-dialog title="传感器数据校验" :visible.sync="visible" top="20vh" width="30%" @cancel="onCloses">
        <el-form :model="form" ref="form" size="mini">
            <el-form-item v-for="(xy, index) in form.xyPairs" :key="index" :label="'输入(X)输出(Y)值' + (index + 1) + '：'"
                :prop="'xyPairs.' + index" label-width="150px">
                <el-row :gutter="4">
                    <el-col :span="6">
                        <el-form-item label='X'>
                            <el-input-number v-model="xy.x" placeholder="x" :controls="false" style="width: 60px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label='Y'>
                            <el-input-number v-model="xy.y" placeholder="y" :controls="false" style="width: 60px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item>
                            <template v-if="form.xyPairs.length > 2">
                                <el-button type="dashed" icon="el-icon-minus" @click="removeRow(index)"></el-button>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form-item>
            <el-form-item>
                <el-button v-if="form.xyPairs.length < 5" type="dashed" icon="el-icon-plus" @click="addRow()">
                    新增一行
                </el-button>
            </el-form-item>
        </el-form>
        <span v-if="validatorPass && calculate && calculate.a">a:{{ calculate.a }},b:{{ calculate.b }}</span>
        <span v-if="!validatorPass" style="color: red;">{{ errorMessage }} </span>
        <template slot="footer">
            <el-button key="cancel" @click="onCloses">取消</el-button>
            <el-button @click="handleCalculate(form.xyPairs)" type="primary">获取</el-button>
            <el-button v-if="validatorPass && calculate && calculate.a" type="success"
                @click="sendResult">确定</el-button>
        </template>
    </el-dialog>
</template>
<script>

export default {
    name: 'calculateModal',

    data() {
        return {
            visible: false,
            calculate: {
                a: 0,
                b: 0,
            },
            form: { xyPairs: [] },
            validatorPass: true,
            errorMessage: "",
            label: "",
            index: ""
        }
    },
    methods: {
        show(label, index) {
            this.label = label;
            this.index = index;
            this.calculate = {}
            this.form.xyPairs = [{ x: null, y: null }, { x: null, y: null }]
            this.visible = true
        },
        onCloses() {
            this.$refs['form'].resetFields()
            this.visible = !this.visible;
        },
        handleCalculate(data) {
            try {
                if (data.length > 1) {
                    let counts = data.length
                    let result = []
                    for (let index = 0; index < counts; index++) {
                        const element = data[index];
                        for (let id = index + 1; id < counts; id++) {
                            const pair = data[id];
                            result.push([element, pair])
                        }
                    }
                    const fabic = Math.pow(10, 3)
                    let collection = result.map(r => {
                        let a = (r[1].y * fabic - r[0].y * fabic) / (r[1].x * fabic - r[0].x * fabic)
                        let b = (r[0].y * fabic - (r[0].x * a * fabic)) / fabic
                        return { a, b }
                    }).filter(value => {
                        return Number.isFinite(value.a) || Number.isNaN(value.b)
                    })
                    let a_avg = collection.map(col => col.a).reduce((a, b) => a + b) / collection.length
                    let b_avg = collection.map(col => col.b).reduce((a, b) => a + b) / collection.length
                    const a = Math.round(a_avg * 100) / 100
                    const b = Math.round(b_avg * 100) / 100
                    if (!isFinite(a) || isNaN(a) || !isFinite(b) || isNaN(b)) {
                        this.validatorPass = false
                        this.errorMessage = `数据有误，无法计算，请检查`
                    } else {
                        this.calculate = { a, b }
                        this.validatorPass = true
                    }
                } else {
                    this.validatorPass = false
                    this.errorMessage = `${this.label}数据不足，无法计算，请检查`
                }
            } catch (e) {
                console.log(e)
                this.validatorPass = false;
                this.errorMessage = `输入${this.label}值有误，无法计算，请检查`
            }
        },
        sendResult() {
            this.$emit('updateSource', { ...this.calculate, label: this.label, index: this.index })
            this.onCloses()
        },
        removeRow(index) {
            if (index !== -1) {
                this.form.xyPairs.splice(index, 1)
            }
        },
        addRow() {
            this.form.xyPairs.push({ x: null, y: null });
        }
    }
}
</script>