import axios from '@/utils/fetch'
import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    mobilephone: '',
    roles: [],
    roletype: null,
    userCustomerId: -1,
    userCustomerFatherId: -1,
  },

  mutations: {

    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_PHONE: (state, mobilephone) => {
      state.mobilephone = mobilephone
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ROLETYPE: (state, roletype) => {
      state.roletype = roletype
    },
    SET_USERCUSTOMERID: (state, customerid) => {
      state.userCustomerId = customerid
    },
    SET_USERCUSTOMERFATHERID: (state, customerid) => {
      state.userCustomerFatherId = customerid
    }
  },

  actions: {
    // 获取用户信息
    GetUserInfo({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        axios.post('/user/info').then(response => {
          const { user: userinfo, customer: belongCustomer } = response.data.values
          const ROLE = ['superadmin', 'superuser', 'agentadmin', 'agentuser', 'admin', 'user']
          if (userinfo.UserTypeId == 0) {
            commit('SET_ROLES', ['superadmin'])
          } else if (userinfo.UserTypeId == 2) {
            commit('SET_ROLES', ['agentadmin'])
          } else if (userinfo.UserTypeId == 4) {
            commit('SET_ROLES', ['admin'])
          } else if (userinfo.UserTypeId == 5) {
            commit('SET_ROLES', ['user'])
          } else {
            commit('SET_ROLES', ['other'])
          }
          commit('SET_PHONE', userinfo.MobilePhone)
          commit('SET_ROLETYPE', userinfo.UserTypeId)
          commit('SET_NAME', userinfo.UserName)
          commit('SET_USERCUSTOMERID', userinfo.CustomerId)
          commit('SET_USERCUSTOMERFATHERID', userinfo.FatherCustomerId)
          resolve({ ...userinfo, EditFlag: belongCustomer.EditFlag })
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        axios.post("/user/logout").then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_NAME', '')
          commit('SET_ROLETYPE', null)
          commit('SET_PHONE', '')
          commit('SET_CUSTOMERID', null)
          commit('SET_CUSTOMERNAME', "")
          localStorage.removeItem('_customerid')
          localStorage.removeItem('_customername')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user