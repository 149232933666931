/*
 * @Date: 2021-06-20 14:23:20
 * @LastEditors: zhouhua
 * @LastEditTime: 2022-07-08 09:51:07
 * @FilePath: \coldcloud-vue-web\src\store\modules\app.js
 */
import axios from '@/utils/fetch'
const app = {
    state: {
        sidebar: {
            opened: !+localStorage.getItem('sidebarStatus'),
        },
        customerid: null,
        customername: '',
        firstLoadFlag: true,
    },
    mutations: {
        TOGGLE_SIDEBAR: (state) => {
            if (state.sidebar.opened) {
                localStorage.setItem('sidebarStatus', 1)
            } else {
                localStorage.setItem('sidebarStatus', 0)
            }
            state.sidebar.opened = !state.sidebar.opened
        },
        SET_CUSTOMERID: (state, id) => {
            state.customerid = id
        },
        SET_CUSTOMERNAME: (state, name) => {
            state.customername = name
        },
        FIRSTLOADFLAG: (state) => {
            state.firstLoadFlag = true
        },
        NOTFIRSTLOADFLAG: (state) => {
            state.firstLoadFlag = false
        },
    },
    actions: {
        ToggleSideBar({ commit }) {
            commit('TOGGLE_SIDEBAR')
        },
        SetCustomerInfo({ commit }, info) {
            commit('SET_CUSTOMERID', info.customerid)
            commit('SET_CUSTOMERNAME', info.customername)
            localStorage.setItem('_customerid', info.customerid)
            localStorage.setItem('_customername', info.customername)
        },
        // 获取用户信息
        GetCustomerInfo({ commit }) {
            return new Promise((resolve, reject) => {
                if (localStorage.getItem('_customerid')) {
                    let customerid = Number(localStorage.getItem('_customerid'))
                    console.log('🚀 ~ file: app.js ~ line 54 ~ returnnewPromise ~ customerid', customerid)
                    commit('SET_CUSTOMERID', customerid)
                    commit('SET_CUSTOMERNAME', localStorage.getItem('_customername'))
                    resolve(customerid)
                } else {
                    axios
                        .post('/user/getcustomerslist')
                        .then((response) => {
                            if (response.data.code == 0) {
                                if (response.data.values.length) {
                                    let customerid = null
                                    for (let index = 0; index < response.data.values.length; index++) {
                                        let customer = response.data.values[index]
                                        if (customer.disabled == false) {
                                            commit('SET_CUSTOMERID', customer.value)
                                            commit('SET_CUSTOMERNAME', customer.label)
                                            customerid = customer.value
                                            break
                                        } else {
                                            continue
                                        }
                                    }
                                    resolve(customerid)
                                } else {
                                    commit('SET_CUSTOMERID', null)
                                    commit('SET_CUSTOMERNAME', '')
                                    localStorage.removeItem('_customerid')
                                    localStorage.removeItem('_customername')
                                    resolve(null)
                                }
                            } else {
                                reject(new Error('can not get customerinfo'))
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        })
                }
            })
        },

        getDevices() {},
    },
}

export default app
