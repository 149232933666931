import App from './App.vue'
import Vue from 'vue'
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'babel-polyfill' // 用于IE支持
import './permission' // 权限
import 'font-awesome/css/font-awesome.min.css'
import dateutil from 'date-util'
import moment from "moment";

import '@/styles/index.scss' // global css
import '@/styles/quill.snow.css' // global css

import router from './router'
import store from './store'
import axios from './utils/fetch'
// 将axios挂载到prototype上，在组件中可以直接使用this.axios访问
Vue.prototype.axios = axios
Vue.prototype.$moment = moment

Vue.use(ElementUI)

new Vue({
    el: '#application',
    router,
    store,
    render: h => h(App)
})