<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-tabs v-model="tabSelected" class="el-col el-col-24" @tab-click="handleTabClick">
        <el-tab-pane label="本地设备" name="first" v-if="usercustomerid != 76 || usercustomerid != 289 || usercustomerid != 381">
          <el-form :inline="true" :rules="searchRules">
            <el-form-item>
              <div>
                <span>选择设备或仓库</span>
                <el-cascader :options="localSelectedOptions" v-model="search.getdeviceslist" :show-all-levels="false"
                  placeholder="关键字搜索" @change="getSensorsList" clearable change-on-select filterable></el-cascader>
              </div>
            </el-form-item>
            <el-form-item>
              <span>传感器号</span>
              <el-select v-model="search.sensorsOptions" placeholder="所有传感器" clearable>
                <el-option v-for="item in sensorslist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template>
                <span>起止时间</span>
                <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间"></el-date-picker>
              </template>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
            </el-form-item>
            <el-form-item v-show="!exportUseable">
              <el-select v-model="exportType" style="width:45%">
                <el-option v-for="item in exportOptions" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
              <el-button @click="exportAlarm">导出</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="云端设备" name="second">
          <el-form :inline="true" :rules="searchRules">
            <el-form-item>
              <div>
                <span>选择仓库</span>
                <el-cascader :options="cloudSelectedOptions" v-model="search.getdeviceslist" :show-all-levels="false"
                  placeholder="关键字搜索" @change="getSensorsList" clearable filterable></el-cascader>
              </div>
            </el-form-item>
            <el-form-item>
              <span>传感器号</span>
              <el-select v-model="search.sensorsOptions" placeholder="所有传感器" clearable>
                <el-option v-for="item in sensorslist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template>
                <span>起止时间</span>
                <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间"></el-date-picker>
              </template>
            </el-form-item>
            <el-form-item>
              <el-button v-show="!exportUseable" @click="handleException(search)">异常处理</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
            </el-form-item>
            <el-form-item v-show="!exportUseable">
              <el-select v-model="exportType" style="width:45%">
                <el-option v-for="item in exportOptions" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
              <el-button @click="exportAlarm">导出</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-col>

    <!--列表-->
    <el-table :data="resultList" v-loading="listLoading" style="width: 100%;" stripe highlight-current-row>
      <el-table-column type="index" width="54"></el-table-column>
      <el-table-column prop="operdate" :formatter="formatTime" label="报警日期" width="140" sortable></el-table-column>
      <el-table-column prop="sensorcode" label="传感器号" width="130" min-width="130" sortable></el-table-column>
      <el-table-column prop="warehousename" label="库房" width="150"></el-table-column>
      <el-table-column label="温度(℃)" width="100" min-width="100">
        <template slot-scope="scope">
          <span style="color:red" v-if="scope.row.isover & 0b0010">{{ Number(scope.row.temperature).toFixed(2) }} ↑</span>
          <span style="color:red" v-else-if="scope.row.isover & 0b0001">{{ Number(scope.row.temperature).toFixed(2) }}
            ↓</span>
          <span v-else>{{ Number(scope.row.temperature).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="humidity" label="湿度(%)" width="100" min-width="100">
        <template slot-scope="scope">
          <span style="color:red" v-if="scope.row.isover & 0b1000">{{ Number(scope.row.humidity).toFixed(2) }} ↑</span>
          <span style="color:red" v-else-if="scope.row.isover & 0b0100">{{ Number(scope.row.humidity).toFixed(2) }}
            ↓</span>
          <span v-else>{{ scope.row.humidity == -100 ? "---" : Number(scope.row.humidity).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mintemperature" label="温度下限" width="105" min-width="105"></el-table-column>
      <el-table-column prop="maxtemperature" label="温度上限" width="105" min-width="105"></el-table-column>
      <el-table-column prop="minhumidity" label="湿度下限" width="105" min-width="105">
        <template slot-scope="scope">
          <span v-if="scope.row.minhumidity == -100">---</span>
          <span v-else>{{ scope.row.minhumidity }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="maxhumidity" label="湿度上限" width="105" min-width="105">
        <template slot-scope="scope">
          <span v-if="scope.row.maxhumidity == -100">---</span>
          <span v-else>{{ scope.row.maxhumidity }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="processstate" :label="tabSelected !== 'first' ? '处理状态' : ''" min-width="105">
        <template slot-scope="scope">
          <el-tag type="success" v-if="tabSelected !== 'first' && scope.row.processstate">已处理</el-tag>
          <span v-else></span>
        </template>
      </el-table-column>
      <!-- <el-table-column  min-width="105"></el-table-column> -->
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <!-- <span>统计：温度：最大值{{summary.maxt}}℃，最小值{{summary.mint}}℃，平均值{{summary.avgt}}℃;湿度：最大值{{summary.maxh}}%，最小值{{summary.minh}}%，平均值{{summary.avgh}}%</span> -->
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20"
        :total="total" :current-page="page" style="float:right;"></el-pagination>
    </el-col>

    <!--异常处理弹窗-->
    <el-dialog :visible.sync="handleDisplay" title="异常处理" :close-on-click-modal="false">
      <el-form ref="exceptionForm" :model="exceptionHandle" :rules="exceptionHandleRules" label-width="100px">
        <el-form-item label="处理对象">
          <span style="font-weight:bold;">{{ handleObject }}</span>
        </el-form-item>
        <el-form-item prop="daterange" label="异常时段">
          <span>{{ exceptionHandle.starttime }}</span> -
          <span>{{ exceptionHandle.endtime }}</span>
        </el-form-item>
        <el-form-item prop="description" label="异常描述">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入异常描述" auto-complete="true"
            v-model="exceptionHandle.description"></el-input>
        </el-form-item>
        <el-form-item prop="reason" label="异常原因">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入异常产生原因的说明" auto-complete="true"
            v-model="exceptionHandle.reason"></el-input>
        </el-form-item>
        <el-form-item prop="result" label="处理说明">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入异常所做处置的说明" auto-complete="true"
            v-model="exceptionHandle.result"></el-input>
        </el-form-item>
        <el-form-item prop="person" label="签名">
          <el-input placeholder="请输入处理异常的操作人员姓名" v-model="exceptionHandle.person"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="handleDisplay = false">取 消</el-button>
        <el-button type="primary" @click="exceptionSubmit" :loading="submitLoading">提 交</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      exportType: "PDF",
      tabSelected: "first",
      localSelectedOptions: [],
      cloudSelectedOptions: [
        {
          value: 2,
          label: "云库房",
          children: []
        },
        {
          value: 3,
          label: "保温箱",
          children: []
        },
        {
          value: 4,
          label: "冷藏车",
          children: []
        },
        {
          value: 6,
          label: "STL冷链箱",
          children: []
        }
      ],
      sensorslist: [],
      search: {
        getdeviceslist: [],
        sensorsOptions: null,
        daterange: null
      },
      summary: {
        maxt: "--",
        mint: "--",
        avgt: "--",
        maxh: "--",
        minh: "--",
        avgh: "--"
      },
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 3600 * 1000 * 24 * 365 * 5 - 8.64e7
          ); //最多五年前
        },
        shortcuts: [
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      total: 0,
      page: 1,
      listLoading: false,
      searchRules: {},
      resultList: [],
      handleDisplay: false,
      exceptionHandle: {
        starttime: "",
        endtime: "",
        warehouseid: null,
        sensorid: null,
        description: "",
        reason: "",
        result: "",
        person: ""
      },
      submitLoading: false,
      exceptionHandleRules: {
        description: [
          { required: true, message: "请输入异常描述", trigger: "blur" },
          { max: 200, message: "异常描述过长", trigger: "blur" }
        ],
        reason: [
          { required: true, message: "请输入异常原因", trigger: "blur" },
          { max: 200, message: "异常原因过长", trigger: "blur" }
        ],
        result: [
          { required: true, message: "请输入处理说明", trigger: "blur" },
          { max: 200, message: "处理说明过长", trigger: "blur" }
        ],
        person: [
          { required: true, message: "请输入处理人员姓名", trigger: "blur" },
          { max: 10, message: "签名过长", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid", 'usercustomerid']),
    handleObject: function () {
      let label = "";
      if (this.search.getdeviceslist.length == 2) {
        this.cloudSelectedOptions.map(wa => {
          wa.children.map(ch => {
            if (ch.value == this.search.getdeviceslist[1]) {
              label = ch.label;
            }
          });
        });
      }
      if (this.search.sensorsOptions != null) {
        this.sensorslist.map(sl => {
          if (sl.value == this.search.sensorsOptions) {
            label += " - " + sl.label;
          }
        });
      }
      return label;
    },
    searchUseable: function () {
      if (this.search.getdeviceslist == "" || this.search.daterange == null)
        return true;
      else return false;
    },
    exportUseable: function () {
      if (this.search.getdeviceslist == "" || this.search.daterange == null)
        return true;
      else return false;
    },
    exportOptions: function () {
      if (this.roletype > 4) {
        return ['PDF']
      }
      else {
        return ['EXCEL', 'PDF', 'CSV']
      }
    }
  },
  methods: {
    formatTime: (row, column) => {
      return new Date(row.operdate).format("mm月dd日 HH:MM:ss");
    },

    handleException(data) {
      this.handleDisplay = true;
      this.exceptionHandle.starttime = data.daterange[0].format(
        "yyyy-mm-dd HH:MM"
      );
      this.exceptionHandle.endtime = data.daterange[1].format(
        "yyyy-mm-dd HH:MM"
      );
      this.exceptionHandle.warehouseid = data.getdeviceslist[1] || null;
      this.exceptionHandle.sensorid = data.sensorsOptions || null;
    },
    exceptionSubmit() {
      this.$refs.exceptionForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.submitLoading = true;
            NProgress.start();
            let params = Object.assign(
              { customerid: this.customerid },
              this.exceptionHandle
            );
            this.axios.post("/datacenter/exceptionhandle", params).then(res => {
              this.submitLoading = false;
              NProgress.done();
              if (res.data.code === 0) {
                this.$refs["exceptionForm"].resetFields();
                this.handleDisplay = false;
                this.$notify({
                  title: "成功",
                  offset: 40,
                  message: "异常处理成功",
                  type: "success"
                });
                this.searchAlarmList();
              } else if (res.data.code === 111) {
                this.$message({
                  message: "该时段的数据已处理，请修改时间段",
                  type: "warning"
                });
              } else if (res.data.code === 112) {
                this.$message({
                  message: "该时段的没有异常数据，请修改处理对象或时间段",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: `处理失败，错误码${res.data.code},请联系管理员`,
                  type: "warning"
                });
              }
            });
          });
        }
      });
    },
    handleTabClick() {
      this.search.getdeviceslist = [];
      this.search.sensorsOptions = null;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchAlarmList();
    },
    resetPageAndSearch() {
      this.page = 1;
      this.searchAlarmList();
    },
    //获取设备列表
    getDevicesList() {
      let para = {
        customerid: this.customerid
      };
      NProgress.start();
      this.axios.post("/datacenter/getdeviceslist", para).then(res => {
        NProgress.done();
        if (res.data.code === 0) {
          res.data.values.deviceslist.map(device => {
            if (device.devicetype == 1) {
              //本地库房
              let matched = 0;
              this.localSelectedOptions.map(opt => {
                if (opt.value == device.deviceid) {
                  matched = 1;
                  opt.children.push({
                    value: device.warehouseid,
                    label: device.warehousename
                  });
                }
                return opt;
              });
              if (matched == 0) {
                this.localSelectedOptions.push({
                  value: device.deviceid,
                  label: device.devicename,
                  children: [
                    {
                      value: device.warehouseid,
                      label: device.warehousename
                    }
                  ]
                });
              }
            } else {
              this.cloudSelectedOptions.map(opt => {
                if (opt.value == device.devicetype) {
                  opt.children.push({
                    value: device.warehouseid,
                    label: device.warehousename
                  });
                }
                return opt;
              });
            }
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },
    getSensorsList() {
      let para = {};
      let len = this.search.getdeviceslist.length;
      if (len == 2) {
        para = {
          searchtype: len,
          warehouseid: this.search.getdeviceslist[1]
        };
      } else if (len == 1) {
        para = {
          searchtype: len,
          deviceid: this.search.getdeviceslist[0]
        };
      } else {
        return this.$message({
          message: `请选择仓库`,
          type: "warning"
        });
      }
      NProgress.start();
      this.axios.post("/datacenter/getsensorslist", para).then(res => {
        NProgress.done();
        if (res.data.code === 0) {
          this.sensorslist = [];
          this.search.sensorsOptions = null;
          res.data.values.sensorslist.map(sensor => {
            this.sensorslist.push({
              value: sensor.sensorid,
              label: `${sensor.sensorcode}#`
            });
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },
    exportAlarm() {
      let type = this.tabSelected == "first" ? 1 : 2; //用于区分当前查询的类型是本地还是云端
      let customerid = this.customerid;
      let deviceid = this.search.getdeviceslist[0];
      let searchtype = this.search.getdeviceslist.length;
      let warehouseid = searchtype == 2 ? this.search.getdeviceslist[1] : null;
      let fromdate = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let todate = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let sensorid = this.search.sensorsOptions || null;
      let page = this.page;
      let params = {
        type: this.exportType,
        searchtype,
        customerid,
        fromdate,
        todate,
        deviceid,
        warehouseid,
        sensorid
      };
      NProgress.start();
      this.axios.post("/datacenter/getexportalarm", params).then(res => {
        NProgress.done();
        if (res.data.code == 0) {
          window.open(`/export/${res.data.values.filename}`);
        } else {
          this.$message({
            message: `${res.data.message}`,
            type: "warning"
          });
        }
      });
    },
    searchAlarmList() {
      let type = this.tabSelected == "first" ? 1 : 2; //用于区分当前查询的类型是本地还是云端
      let customerid = this.customerid;
      let deviceid = this.search.getdeviceslist[0];
      let searchtype = this.search.getdeviceslist.length;
      let warehouseid = searchtype == 2 ? this.search.getdeviceslist[1] : null;
      let fromdate = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let todate = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let sensorid = this.search.sensorsOptions || null;
      let page = this.page;
      let params = {
        type,
        searchtype,
        customerid,
        fromdate,
        todate,
        deviceid,
        warehouseid,
        sensorid,
        page
      };
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/datacenter/getalarmtable", params)
        .then(res => {
          this.listLoading = false;
          NProgress.done();
          if (res.data.code === 0) {
            this.resultList = res.data.values.data;
            this.total = res.data.values.count;
          } else {
            this.$message({
              message: `查询出错，错误码${res.data.code},请联系管理员`,
              type: "warning"
            });
          }
        })
        .catch(err => {
          this.$router.push("/login");
        });
    }
  },
  mounted() {
    this.getDevicesList();
    if (this.usercustomerid != 76 || this.usercustomerid != 289 || this.usercustomerid != 381) {
      this.tabSelected = 'first'
    } else {
      this.tabSelected = 'second'
    }
  }
};
</script>
