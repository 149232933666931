<template>
    <section>
        <el-form :model="formulaForm" ref="formulaForm" label-width="150px" size="mini" label-suffix="：">
            <el-form-item v-for="(formulaRow, index) in formulaForm.formulaList" :key="index"
                :label="label + '偏差公式' + (index + 1)" :prop="'formulaList.' + index" :rules="itemValidator">
                <el-row :gutter="10">
                    <el-col :span="3">
                        a:<el-input-number v-model="formulaRow.a" placeholder="a" :controls="false"
                            style="width: 60px;" />
                    </el-col>
                    <el-col :span="3">
                        b:<el-input-number v-model="formulaRow.b" placeholder="b" :controls="false"
                            style="width: 60px;" />
                    </el-col>
                    <el-col :span="3">
                        F1:<el-input-number v-model="formulaRow.f1" placeholder="F1" :controls="false"
                            style="width: 60px;" />
                    </el-col>
                    <el-col :span="3">
                        F2:<el-input-number v-model="formulaRow.f2" placeholder="F2" :controls="false"
                            style="width: 60px;" />
                    </el-col>
                    <el-col :span="2">
                    </el-col>
                    <el-col :span="8" style="font-weight: bold;">
                        <el-button size="mini" type="primary" @click="showModal(label, index)">计算a,b</el-button>
                        y={{ formulaRow.a }}x+{{ formulaRow.b }}&nbsp;({{ formulaRow.f1
                        }}~{{ formulaRow.f2 }})
                    </el-col>
                    <el-col :span="2">
                        <el-form-item>
                            <template>
                                <el-button circle size="mini" icon="el-icon-minus"
                                    @click.prevent="removeRow(formulaRow)" class="minusRowBtn"></el-button>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button v-if="formulaForm.formulaList.length < 5" icon="el-icon-plus" @click="addRow()">新增一行{{ label
                    }}</el-button>
            </el-form-item>
        </el-form>
    </section>
</template>
<script>
import { assign } from 'core-js/core/object';

export default {
    name: 'FormulaRow',
    components: {},
    props: {
        label: {
            type: String,
            default: ''
        },
        formulas: {
            type: Array,
            default: []
        }
    },
    data() {
        const validatorItem = (rule, value, callback) => {
            const { a, b, f1, f2 } = value || {};
            // 检查所有参数是否都已定义且不为null或undefined  
            if (a === undefined || a === null ||
                b === undefined || b === null ||
                f1 === undefined || f1 === null ||
                f2 === undefined || f2 === null) {
                return callback(new Error("参数不能有空值"));
            }

            // 使用Number()函数尝试将参数转换为数字，并检查转换后的值是否为NaN
            const _a = Number(a);
            const _b = Number(b);
            const _f1 = Number(f1);
            const _f2 = Number(f2);

            // 检查转换后的值是否为NaN（即不是数字）  
            if (isNaN(_a) || isNaN(_b) || isNaN(_f1) || isNaN(_f2)) {
                return callback(new Error("参数必须是数字"));
            }

            // 检查a是否为0  
            if (_a === 0) {
                return callback(new Error("参数a不能为0"));
            }

            // 检查f1是否小于f2  
            if (_f1 > _f2) {
                callback(new Error("公式作用范围错误，请检查F1，F2"));
            }

            // 所有条件都满足，返回true  
            return callback();

        }
        return {
            validatorPass: true,
            formulaForm: {
                formulaList: [],
            },
            itemValidator: {
                validator: validatorItem,
                trigger: 'blur'
            }
        };
    },
    watch: {
        formulaForm: {
            handler: function (v) {
                this.$refs['formulaForm'].validate((valid) => {
                    this.validatorPass = valid
                });
            },
            deep: true
        }
    },
    created() {
        if (this.formulas.length === 0) {
            this.formulaForm.formulaList = []
        }
        else {
            this.formulaForm.formulaList = JSON.parse(JSON.stringify(this.formulas))
        }
    },
    methods: {
        showModal(label, index) {
            this.$emit('modalShow', { label, index })
        },
        removeRow(item) {
            var index = this.formulaForm.formulaList.indexOf(item)
            if (index !== -1) {
                this.formulaForm.formulaList.splice(index, 1)
            }
        },
        addRow() {
            const initF1 = this.formulaForm.formulaList[this.formulaForm.formulaList.length - 1]?.f2 || 0
            this.formulaForm.formulaList.push({
                a: 1,
                b: 0,
                f1: initF1,
                f2: initF1 + 10
            });
        },
        getData() {
            return {
                pass: this.validatorPass,
                formulas: this.formulaForm.formulaList
            };
        },
        updateByIndex(index, a, b) {
            const selected = this.formulaForm.formulaList[index]
            selected.a = a;
            selected.b = b;
        }
    }
}
</script>