<template>
    <el-row class="container">
        <el-col :span="24" class="header">
            <el-col :span="20" class="logo">
                <img v-if="usercustomerid != 1023 && usercustomerfatherid != 1023" @click="toggleSideBar"
                    src="../assets/coldcloud.png" />
                <img v-else @click="toggleSideBar" src="../assets/coldcloud_hl.png" />
                <span>
                    <i class="txt">{{ usercustomerid != 1023 && usercustomerfatherid != 1023 ? '冷 云' : '华冷智加' }}</i>
                </span>
            </el-col>
            <el-col :span="4" class="userinfo">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link userinfo-inner">
                        <img src="../assets/head.png" />
                        {{ username }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-show="roletype < 4" @click.native="mycustomer"> <i class="fa fa-user-o"
                                style="padding-right:7px;"></i>我的客户 </el-dropdown-item>
                        <el-dropdown-item @click.native="mydevice"> <i class="fa fa-sitemap"
                                style="padding-right:7px;"></i>我的设备 </el-dropdown-item>
                        <el-dropdown-item @click.native="customerinfo"> <i class="fa fa-file-text-o"
                                style="padding-right:7px;"></i>基本信息 </el-dropdown-item>
                        <el-dropdown-item @click.native="notice"> <i class="fa fa-envelope-o"
                                style="padding-right:7px;"></i>消息中心 </el-dropdown-item>
                        <el-dropdown-item divided @click.native="logout">
                            退出登录&nbsp;
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-col>
        <el-col :span="24" class="main">
            <el-menu :default-active="$route.path" @open="handleopen" @close="handleclose" @select="handleselect"
                class="aside" theme="light" unique-opened router :collapse="isCollapse">
                <template v-for="(item, index) in permission_routers">
                    <el-submenu :index="index + ''" v-if="!item.leaf && !item.hidden" :key="index">
                        <template slot="title">
                            <i :class="item.iconCls"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <template v-for="child in item.children">
                            <el-menu-item :key="child.value" :index="child.path" v-if="!child.hidden">{{ child.name
                                }}</el-menu-item>
                        </template>
                    </el-submenu>
                    <el-menu-item v-if="item.leaf && item.children.length > 0" :index="item.children[0].path"
                        :key="index">
                        <i :class="item.iconCls"></i>
                        <span slot="title">{{ item.children[0].name }}</span>
                    </el-menu-item>
                </template>
            </el-menu>
            <section :class="{ 'content-container': true, 'content-container--collapse': isCollapse }">
                <div class="grid-content bg-purple-light">
                    <el-col :span="24" class="breadcrumb-container">
                        <strong class="title">{{ $route.name }}</strong>
                        <strong class="breadcrumb-inner">当前客户：{{ customername }}</strong>
                    </el-col>
                    <el-col :span="24" class="content-wrapper">
                        <transition>
                            <router-view></router-view>
                        </transition>
                    </el-col>
                </div>
            </section>
        </el-col>
    </el-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import twix from 'twix'
import moment from 'moment'
export default {
    data() {
        return {
            deviceslist: [],
            expiryDevicesList: [],
        }
    },
    computed: {
        ...mapGetters(['permission_routers', 'sidebar', 'username', 'roletype', 'customerid', 'usercustomerfatherid', 'usercustomerid', 'customername']),
        isCollapse() {
            return !this.sidebar.opened
        },
    },
    methods: {
        ...mapActions(['LogOut', 'ToggleSideBar']),
        toggleSideBar() {
            this.ToggleSideBar()
        },
        //获取设备列表
        getDevices() {
            this.axios
                .post('/user/getdevices', {
                    page: 0,
                    name: '',
                    customerid: this.customerid,
                })
                .then((reply) => {
                    if (reply.data.code === 0) {
                        this.deviceslist = reply.data.values.devices.map((dev) => {
                            dev.day = this.$moment()
                                .twix(dev.expiredat)
                                .count('days')
                            return dev
                        })
                        this.expiryDevicesList = this.deviceslist.filter((d) => d.day < 30)
                        if (this.expiryDevicesList.length) {
                            let deviceArray = [0, 0, 0, 0, 0, 0, 0]
                            let deviceString = ''
                            this.expiryDevicesList.map((d) => {
                                deviceArray[d.devicetype - 1]++
                            })
                            deviceArray.map((t, index) => {
                                if (t != 0) {
                                    switch (index) {
                                        case 0: {
                                            deviceString += `${t}个库房设备 `
                                            break
                                        }
                                        case 1: {
                                            deviceString += `${t}个云库房设备 `
                                            break
                                        }
                                        case 3: {
                                            deviceString += `${t}个冷藏车设备 `
                                            break
                                        }
                                        case 4: {
                                            deviceString += `${t}个报警器设备 `
                                            break
                                        }
                                        default: {
                                            deviceString += `${t}个保温箱设备 `
                                            break
                                        }
                                    }
                                }
                            })
                            this.$message({
                                message: `您有${deviceString}即将到期或已到期`,
                                duration: 0,
                                showClose: true,
                                type: 'warning',
                            })
                        }
                    } else {
                        this.$message({
                            message: `查询出错，错误码${reply.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
        },
        onSubmit() { },
        handleopen() { },
        handleclose() { },
        handleselect: function (a, b) { },
        //退出登录
        logout: function () {
            this.$confirm('确认退出吗?', '提示', {
                type: 'warning',
            }).then(() => {
                this.LogOut().then(() => {
                    this.$router.push('/login')
                })
            })
        },
        mycustomer: function () {
            this.$router.push('/mycustomer')
        },
        mydevice: function () {
            this.$router.push('/mydevice')
        },
        customerinfo: function () {
            this.$router.push('/customerinfo')
        },
        notice: function () {
            this.$router.push('/notice')
        },
    },
    mounted() {
        //this.getDevices(); 
    },
}
</script>

<style scoped lang="scss">
.container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;

    .header {
        height: 60px;
        line-height: 60px;
        background: #1f2d3d;
        color: #c0ccda;
        border-bottom: 5px solid #20a0ff;

        .userinfo {
            text-align: right;
            padding-right: 35px;

            .userinfo-inner {
                color: #c0ccda;
                cursor: pointer;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    margin: 10px 0px 10px 10px;
                    float: left;
                }
            }
        }

        .logo {
            font-size: 22px;

            img {
                width: 42px;
                float: left;
                margin: 10px 10px 10px 18px;
            }

            .txt {
                margin-left: 5px;
                color: #20a0ff;
                font-size: 17px;
                font-weight: bold;
                font-style: normal;
            }
        }
    }

    .main {
        background: #eef1f6;
        position: absolute;
        top: 60px;
        bottom: 0px;
        overflow: hidden;

        .aside:not(.el-menu--collapse) {
            width: 230px;
            height: 100vh;
            border-right: 1px #ebeef5 solid;
        }

        .content-container--collapse {
            left: 64px !important;
        }

        .content-container {
            transition: 0.6s;
            background: #f2f2f4;
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            left: 231px;
            overflow-y: scroll;
            padding: 20px;

            .breadcrumb-container {
                margin-bottom: 15px;
                border-left: 5px solid #50bfff;
                padding: 12px;
                background: #fff;

                .title {
                    width: 200px;
                    float: left;
                    color: #475669;
                }

                .breadcrumb-inner {
                    float: right;
                    color: #676669;
                }
            }

            .content-wrapper {
                background-color: #fff;
                box-sizing: border-box;
                padding: 12px;

            }
        }
    }
}
</style>
