<template>
    <el-row class="container">
        <el-col :span="24" class="header">
            <el-col :span="20" class="logo">
                 <img v-if="usercustomerid != 1023 && usercustomerfatherid != 1023" @click="toggleSideBar"
                    src="../assets/coldcloud.png" />
                <img v-else @click="toggleSideBar" src="../assets/coldcloud_hl.png" />
                <span>
                    <i class="txt">{{ usercustomerid != 1023 && usercustomerfatherid != 1023 ? '冷 云' : '华冷智加' }}</i>
                </span>
            </el-col>
            <el-col :span="4" class="userinfo">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link userinfo-inner">
                        <img src="../assets/head.png" />
                        {{ username }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-show="roletype < 4" @click.native="mycustomer"> <i class="fa fa-user-o"
                                style="padding-right:7px;"></i>我的客户 </el-dropdown-item>
                        <el-dropdown-item @click.native="mydevice"> <i class="fa fa-sitemap"
                                style="padding-right:7px;"></i>我的设备 </el-dropdown-item>
                        <el-dropdown-item @click.native="customerinfo"> <i class="fa fa-file-text-o"
                                style="padding-right:7px;"></i>基本信息 </el-dropdown-item>
                        <el-dropdown-item @click.native="notice"> <i class="fa fa-envelope-o"
                                style="padding-right:7px;"></i>消息中心 </el-dropdown-item>
                        <el-dropdown-item divided @click.native="logout">
                            退出登录&nbsp;
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-col>
        <el-col :span="24" class="main">
            <el-menu :default-active="$route.path" @open="handleopen" @close="handleclose" @select="handleselect"
                class="aside" theme="light" unique-opened router :collapse="isCollapse">
                <template v-for="(item, index) in permission_routers">
                    <el-submenu :index="index + ''" v-if="!item.leaf && !item.hidden" :key="index">
                        <template slot="title">
                            <i :class="item.iconCls"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <template v-for="child in item.children">
                            <el-menu-item :key="child.value" :index="child.path" v-if="!child.hidden">{{ child.name
                            }}</el-menu-item>
                        </template>
                    </el-submenu>
                    <el-menu-item v-if="item.leaf && item.children.length > 0" :index="item.children[0].path" :key="index">
                        <i :class="item.iconCls"></i>
                        <span slot="title">{{ item.children[0].name }}</span>
                    </el-menu-item>
                </template>
            </el-menu>

            <section :class="{ 'content-container': true, 'content-container--collapse': isCollapse }">
                <div class="grid-content">
                    <el-row class="carousel" v-show="showAdvert">
                        <el-carousel :interval="5000" arrow="always">
                            <template v-for="(item, index) in advertsList">
                                <el-carousel-item v-if="item.IsEnabled" :key="index">
                                    <div class="advertcontent">
                                        <div class="picture">
                                            <img alt v-if="item.Image != null" :src="item.picture.src" width="100%" />
                                            <img v-else :src="defultimg" />
                                        </div>

                                        <div class="introduce">
                                            <h3>
                                                <router-link :to="`/content/${item.Id}`">{{ item.Title }}</router-link>
                                            </h3>
                                            <div class="content ql-editor" v-html="item.Content"
                                                style="white-space: pre-wrap;"></div>
                                        </div>
                                    </div>
                                </el-carousel-item>
                            </template>
                        </el-carousel>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="grid-content align-items-center shadow h-100">
                                <div class="align-items-center">
                                    <div class="dot bg-rtlist"></div>
                                    <div class="text">
                                        <router-link to="/rtlist">实时数据</router-link>
                                    </div>
                                </div>
                                <div class="icon bg-rtlist text-white">
                                    <i class="el-icon-document"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content align-items-center shadow h-100">
                                <div class="align-items-center">
                                    <div class="dot bg-history"></div>
                                    <div class="text">
                                        <router-link to="/dataCenter/histable">历史数据</router-link>
                                    </div>
                                </div>
                                <div class="icon bg-history text-white">
                                    <i class="el-icon-time"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content align-items-center shadow h-100">
                                <div class="align-items-center">
                                    <div class="dot bg-device"></div>
                                    <div class="text">
                                        <router-link to="/mydevice">我的设备</router-link>
                                    </div>
                                </div>
                                <div class="icon bg-device text-white">
                                    <i class="el-icon-files"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content align-items-center shadow h-100">
                                <div class="align-items-center">
                                    <div class="dot bg-customer"></div>
                                    <div class="text">
                                        <router-link to="/dataCenter/syslog">操作日志</router-link>
                                    </div>
                                </div>
                                <div class="icon bg-customer text-white">
                                    <i class="el-icon-user-solid"></i>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24" class="breadcrumb-container">
                            <strong class="title">{{ $route.name }}</strong>
                            <strong class="breadcrumb-inner">当前客户：{{ customername }}</strong>
                        </el-col>
                        <el-col :span="24" class="content-wrapper">
                            <transition>
                                <router-view></router-view>
                            </transition>
                        </el-col>
                    </el-row>
                </div>
            </section>
        </el-col>
    </el-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import twix from 'twix'
import moment from 'moment'
export default {
    data() {
        return {
            defultimg: require('@/assets/defultimg.png'),
            firstFlag: true,
            showAdvert: true,
            advertsList: [],
            deviceslist: [],
            expiryDevicesList: [],
        }
    },
    computed: {
        ...mapGetters(['permission_routers', 'sidebar', 'username', 'roletype', 'customerid', 'customername', 'usercustomerfatherid', 'usercustomerid']),
        isCollapse() {
            return !this.sidebar.opened
        },
    },

    watch: {
        // 设置是否显示广告
        advertsList: {
            handler: function (newval, oldval) {
                if (newval.length > 0) {
                    this.showAdvert = newval.some((advert) => {
                        return advert.IsEnabled
                    })
                } else {
                    this.showAdvert = false
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['LogOut', 'ToggleSideBar']),
        toggleSideBar() {
            this.ToggleSideBar()
        },

        loadComplete() {
            console.log(this.$store.state.app.firstLoadFlag)
            if (this.$store.state.app.firstLoadFlag) {
                this.getDevices()
                this.$store.commit('NOTFIRSTLOADFLAG')
            }
        },

        //获取设备列表
        getDevices() {
            this.axios
                .post('/user/getdevices', {
                    page: 0,
                    name: '',
                    customerid: this.customerid,
                })
                .then((reply) => {
                    if (reply.data.code === 0) {
                        this.deviceslist = reply.data.values.devices.map((dev) => {
                            dev.day = this.$moment()
                                .twix(dev.expiredat)
                                .count('days')
                            return dev
                        })
                        this.expiryDevicesList = this.deviceslist.filter((d) => d.day < 30)
                        if (this.expiryDevicesList.length) {
                            let deviceArray = [0, 0, 0, 0, 0, 0, 0]
                            let deviceString = ''
                            this.expiryDevicesList.map((d) => {
                                deviceArray[d.devicetype - 1]++
                            })
                            deviceArray.map((t, index) => {
                                if (t != 0) {
                                    switch (index) {
                                        case 0: {
                                            deviceString += `${t}个库房设备 `
                                            break
                                        }
                                        case 1: {
                                            deviceString += `${t}个云库房设备 `
                                            break
                                        }
                                        case 3: {
                                            deviceString += `${t}个冷藏车设备 `
                                            break
                                        }
                                        case 4: {
                                            deviceString += `${t}个报警器设备 `
                                            break
                                        }
                                        default: {
                                            deviceString += `${t}个保温箱设备 `
                                            break
                                        }
                                    }
                                }
                            })
                            this.$message({
                                message: `您有${deviceString}即将到期或已到期`,
                                duration: 0,
                                showClose: true,
                                type: 'warning',
                            })
                        }
                    } else {
                        this.$message({
                            message: `查询出错，错误码${reply.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
        },

        //获取宣传列表
        getAdvert() {
            this.axios.get('/admin/advert').then((reply) => {
                if (reply.data.code === 0) {
                    this.advertsList = reply.data.values.list.map((advert) => {
                        advert.IsEnabled = advert.IsEnabled ? true : false
                        advert.picture = new Image()
                        if (advert.Image != null) {
                            advert.picture.src = '/uploads/' + advert.Image
                        } else {
                            advert.picture.src = this.defaultImg
                        }
                        return advert
                    })
                } else {
                    this.$message({
                        message: `查询出错，错误码${reply.data.code},请联系管理员`,
                        type: 'warning',
                    })
                }
            })
        },

        onSubmit() { },
        handleopen() { },
        handleclose() { },
        handleselect: function (a, b) { },
        //退出登录
        logout: function () {
            this.$confirm('确认退出吗?', '提示', {
                type: 'warning',
            }).then(() => {
                this.LogOut().then(() => {
                    this.$router.push('/login')
                })
            })
        },
        mycustomer: function () {
            this.$router.push('/mycustomer')
        },
        mydevice: function () {
            this.$router.push('/mydevice')
        },
        customerinfo: function () {
            this.$router.push('/customerinfo')
        },
        notice: function () {
            this.$router.push('/notice')
        },
    },

    mounted() {
        this.loadComplete()
        this.getAdvert()
    },
}
</script>

<style scoped lang="scss">
.container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;

    .header {
        height: 60px;
        line-height: 60px;
        background: #1f2d3d;
        color: #c0ccda;
        border-bottom: 5px solid #20a0ff;

        .userinfo {
            text-align: right;
            padding-right: 35px;

            .userinfo-inner {
                color: #c0ccda;
                cursor: pointer;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    margin: 10px 0px 10px 10px;
                    float: left;
                }
            }
        }

        .logo {
            font-size: 22px;

            img {
                width: 42px;
                float: left;
                margin: 10px 10px 10px 18px;
            }

            .txt {
                margin-left: 5px;
                color: #20a0ff;
                font-size: 17px;
                font-weight: bold;
                font-style: normal;
            }
        }
    }

    .main {
        background: #eef1f6;
        position: absolute;
        top: 60px;
        bottom: 0px;
        overflow: hidden;

        .aside {
            height: 100vh;
            border-right: 1px #ebeef5 solid;
        }

        .aside:not(.el-menu--collapse) {
            width: 230px;
        }

        .content-container--collapse {
            left: 64px !important;
        }

        .content-container {
            transition: 0.6s;
            background: #fbfdff;
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            left: 231px;
            overflow-y: scroll;
            padding: 20px;

            .breadcrumb-container {
                margin-bottom: 15px;
                border-left: 5px solid #50bfff;
                padding: 12px;
                background: #fff;

                .title {
                    width: 200px;
                    float: left;
                    color: #475669;
                }

                .breadcrumb-inner {
                    float: right;
                    color: #676669;
                }
            }

            .content-wrapper {
                background-color: #fff;
                box-sizing: border-box;
                padding: 16px
            }
        }
    }
}

.el-col {
    margin-bottom: 20px;
}

.carousel {
    position: relative;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    height: 300px;
    box-shadow: 7px 7px 10px 0 rgba(76, 110, 245, 0.1);
    margin-bottom: 20px;

    .advertcontent {
        display: flex;

        .picture {
            min-width: 530px;

            img {
                width: 100%;
            }
        }

        .introduce {
            flex-grow: 1;
            height: 240px;
            padding: 30px 30px 0 30px;
            overflow: hidden;

            .h3 a {
                color: #555;
            }
        }
    }
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
    padding: 20px;

    .h-100 {
        height: 100%;
    }

    .bg-rtlist {
        background-color: #20a0ff;
    }

    .bg-history {
        background-color: #22b87a;
    }

    .bg-device {
        background-color: #fbb94b;
    }

    .bg-customer {
        background-color: #f44336;
    }

    .align-items-center {
        align-items: center;
        display: flex;
        flex-grow: 1;

        .dot {
            max-width: 10px;
            min-width: 10px;
            max-height: 10px;
            min-height: 10px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .text a {
            font-size: 14px;
            font-weight: 700;
            color: #212529;
            text-decoration: none;
        }
    }

    .icon {
        min-width: 35px;
        max-width: 35px;
        min-height: 35px;
        max-height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-white {
        color: #fff;
    }
}

.shadow {
    box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
}

.ql-editor {
    height: auto;
}</style>
