<!--
 * @Date: 2022-07-22 11:49:05
 * @LastEditors: zhouhua
 * @LastEditTime: 2022-07-29 09:50:57
 * @FilePath: \coldcloud-vue-web\src\pages\cloudwarehouse\alarmTab\BatteryAlarm.vue
-->
<template>
    <div>
        <el-table :data="batteryalarmlist" v-loading="loading" style="width: 100%;" stripe highlight-current-row>
            <el-table-column prop="devicename" label="设备名称" min-width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="devicecode" label="设备号" min-width="130" show-overflow-tooltip></el-table-column>
            <el-table-column prop="alarmphone" label="报警短信号码" min-width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.alarmphone | phoneFilter(phoneObj) }}
                </template>
            </el-table-column>
            <el-table-column prop="threshold" label="报警警戒值(%)" min-width="120"></el-table-column>
            <el-table-column prop="issmsenable" label="短信报警" :formatter="formatSMSEnable" min-width="100"></el-table-column>
            <el-table-column prop="iswechatenable" label="微信报警" :formatter="formatWeChatEnable" min-width="100"></el-table-column>
            <el-table-column prop="isappenable" label="APP报警" :formatter="formatAppEnable" min-width="100"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150" min-width="150">
                <template slot-scope="scope">
                    <el-button :disabled="roletype > 4" size="small" @click="dataFormShow('edit', scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button :disabled="roletype > 4" type="warning" size="small" @click="handleDel(scope.$index, scope.row)">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="toolbar">
            <el-button type="primary" @click="dataFormShow('add')" :disabled="roletype > 4">新增</el-button>
        </el-col>
        <!--编辑低电量报警界面-->
        <el-dialog :title="title" :visible.sync="dataFormVisible" :close-on-click-modal="false">
            <el-form :model="dataForm" label-width="150px" :rules="dataFormRules" ref="dataForm">
                <el-form-item label="所属设备" prop="deviceid">
                    <el-select v-model="dataForm.deviceid" auto-complete="off">
                        <el-option v-for="item in devicemodels" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报警号码" prop="phone">
                    <el-select v-model="dataForm.phone" multiple filterable allow-create :filter-method="handleFilter" placeholder="请选择">
                        <el-option v-for="item in filterOptions" :key="item.name" :label="item.name" :value="item.phone"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="启用短信" prop="issmsenable" required>
                    <el-switch v-model="dataForm.issmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用微信" prop="iswechatenable" required>
                    <el-switch v-model="dataForm.iswechatenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用APP" prop="isappenable" required>
                    <el-switch v-model="dataForm.isappenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="报警警戒值(%)" required>
                    <el-input-number v-model.number="dataForm.threshold" :precision="0" :step="1" :min="1" :max="100"></el-input-number>
                    <span>%</span>
                </el-form-item>
                <el-form-item label="连续报警间隔" prop="alarminterval" required>
                    <el-input-number v-model.number="dataForm.alarminterval" :step="1" :min="1" :max="120"></el-input-number>
                    <span>分钟</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dataFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="comfirmLoading">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import NProgress from 'nprogress'
import validator from 'validator'
export default {
    name: 'BatteryAlarmTable',
    props: {
        formatSMSEnable: { type: Function },
        formatWeChatEnable: { type: Function },
        formatAppEnable: { type: Function },
        validateSMSPhone: { type: Function },
        roletype: { type: Number },
        customerid: { type: Number },
        phoneList: { type: Array },
    },
    filters: {
        phoneFilter(phone, phoneObj) {
            let newArr = phone.split(',').map((e) => (Object.prototype.hasOwnProperty.call(phoneObj, e) ? phoneObj[e] : e))
            return newArr.join()
        },
    },
    data() {
        const validatorPhone = (rule, value, callback) => {
            console.log('🚀 ~ file: BatteryAlarm.vue ~ line 96 ~ validatorPhone ~ value', value)
            if (value.length > 0) {
                value.forEach((phone) => {
                    if (!validator.isMobilePhone(phone, 'zh-CN')) {
                        return callback(new Error('请输入正确的手机号码'))
                    }
                })
            }
            return callback()
        }
        return {
            batteryalarmlist: [], //低电量报警
            sensorsOptionList: [], //新增/编辑 低电量传感器选择下拉列表
            devicemodels: [],
            origindevicemodels: [], //新增/编辑 低电量传感器选择下拉列表
            loading: false,
            dataFormVisible: false,
            title: '',
            formType: {
                add: '新增低电量报警',
                edit: '编辑低电量报警',
            },
            comfirmLoading: false,
            //编辑低电量界面数据
            dataForm: {
                deviceid: '',
                phone: [],
                issmsenable: true,
                iswechatenable: true,
                isappenable: true,
                threshold: 0,
                alarminterval: 0,
            },
            dataFormRules: {
                deviceid: [
                    {
                        required: true,
                        type: 'number',
                        message: '请选择所属设备',
                        trigger: 'change',
                    },
                ],
                phone: [
                    {
                        validator: validatorPhone,
                        trigger: 'change',
                    },
                ],
            },
            phoneObj: {},
            originPhoneList: [],
            filterOptions: [],
        }
    },
    components: {},
    watch: {
        phoneList: function(val) {
            this.originPhoneList = this.filterOptions = val.map((e) => {
                let obj = {
                    name: e.Name,
                    phone: e.Phone,
                }
                return obj
            })
            val.forEach((e) => {
                this.phoneObj[e.Phone] = e.Name
            })
        },
    },
    mounted() {
        this.getCloudBatteryAlarmInfo()
        // this.filterOptions = JSON.parse(JSON.stringify(this.phoneList))
    },

    methods: {
        // 号码人员筛选
        handleFilter(val) {
            console.log(val)
            let restaurants = this.originPhoneList
            let results = val ? restaurants.filter((e) => e.name.includes(val) || e.phone.includes(val)) : restaurants
            console.log('results', results)
            this.filterOptions = results
        },
        //获取低电量报警数据
        getCloudBatteryAlarmInfo() {
            let para = {
                customerid: this.customerid,
            }
            this.batterylistLoading = true
            NProgress.start()
            this.axios.post('/cloudmanagement/getclouddevices', para).then((reply) => {
                this.origindevicemodels = this.devicemodels = reply.data.values.devices.filter((item) => item.devicetype == 6 || item.devicetype == 7)
                this.axios.post('/cloudmanagement/getbatteryalarmruleslist', para).then((reply) => {
                    this.batterylistLoading = false
                    NProgress.done()
                    if (reply.data.code === 0) {
                        if (reply.data.total === 0)
                            this.$message({
                                message: `没有数据`,
                                type: 'warning',
                            })
                        this.batteryalarmlist = reply.data.values.rulelist

                        this.origindevicemodels = this.devicemodels.map((s) => {
                            s.disabled = false
                            if (this.batteryalarmlist.length) {
                                this.batteryalarmlist.map((rule) => {
                                    if (rule.deviceid == s.value) {
                                        s.disabled = true
                                    }
                                })
                            }
                            return s
                        })
                    } else {
                        this.$message({
                            message: `查询出错，错误码${reply.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },
        //删除低电量
        handleDel: function(index, row) {
            this.$confirm('确认删除该设备吗?', '提示', {
                type: 'warning',
            }).then(() => {
                let params = {}
                params = Object.assign({}, row)
                this.loading = true
                NProgress.start()
                this.axios.post('/cloudmanagement/deletebatteryalarmrule', params).then((res) => {
                    this.loading = false
                    NProgress.done()
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success',
                        })
                        this.getCloudBatteryAlarmInfo()
                    } else if (res.data.code === 102) {
                        this.$message({
                            message: '您的权限不足',
                            type: 'warning',
                        })
                    } else {
                        this.$message({
                            message: `删除失败，错误码${res.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },
        //显示低电量界面
        dataFormShow: function(type, row) {
            console.log('🚀 ~ file: BatteryAlarm.vue ~ line 249 ~ row', row)
            this.dataFormVisible = true
            this.blackoutTitle = this.formType[type]
            if (type == 'edit') {
                this.dataForm = Object.assign({}, this.dataForm, row)
                this.dataForm.issmsenable = !!row.issmsenable
                this.dataForm.iswechatenable = !!row.iswechatenable
                this.dataForm.isappenable = !!row.isappenable
                this.dataForm.deviceid = Number(row.deviceid)
                this.dataForm.phone = row.alarmphone.length ? row.alarmphone.split(',') : []
            } else {
                this.dataForm = {
                    deviceid: '',
                    phone: [],
                    issmsenable: true,
                    iswechatenable: true,
                    isappenable: true,
                }
            }
            // 先深拷贝还原初始值
            this.devicemodels = JSON.parse(JSON.stringify(this.origindevicemodels))
            row &&
                this.devicemodels.forEach((s) => {
                    if (row.deviceid == s.value) {
                        s.disabled = false
                    }
                    return s
                })
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate()
            })
        },

        //低电量编辑
        handleSubmit: function() {
            this.$refs.dataForm.validate((valid) => {
                let params = {}
                Object.assign(params, this.dataForm)
                if (!this.validateSMSPhone(params)) {
                    return this.$message({
                        message: `必须输入手机号码`,
                        type: 'warning',
                    })
                }
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.comfirmLoading = true
                        NProgress.start()
                        params.alarmphone = params.phone.length ? params.phone.join(',') : ''
                        params.issmsenable = params.issmsenable ? 1 : 0
                        params.iswechatenable = params.iswechatenable ? 1 : 0
                        params.isappenable = params.isappenable ? 1 : 0
                        this.devicemodels.map((dev) => {
                            if (dev.value == params.deviceid) {
                                params.devicecode = dev.code
                            }
                        })
                        let api = !params.ruleid ? '/cloudmanagement/addbatteryalarmrule' : '/cloudmanagement/editbatteryalarmrule'
                        this.axios
                            .post(api, params)
                            .then((res) => {
                                this.comfirmLoading = false
                                NProgress.done()
                                if (res.data.code === 0) {
                                    this.$notify({
                                        title: '成功',
                                        message: params.ruleid ? '编辑成功' : '新增成功',
                                        type: 'success',
                                    })
                                    this.$refs['dataForm'].resetFields()
                                    this.dataFormVisible = false
                                    this.getCloudBatteryAlarmInfo()
                                } else if (res.data.code === 102) {
                                    this.$message({
                                        message: '您的权限不足',
                                        type: 'warning',
                                    })
                                } else {
                                    this.$message({
                                        message: `保存失败，错误码${res.data.code},请联系管理员`,
                                        type: 'warning',
                                    })
                                }
                            })
                            .catch((e) => {})
                            .finally(() => {
                                this.comfirmLoading = false
                            })
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>
