import { render, staticRenderFns } from "./alarmtable.vue?vue&type=template&id=2566437c"
import script from "./alarmtable.vue?vue&type=script&lang=js"
export * from "./alarmtable.vue?vue&type=script&lang=js"
import style0 from "./alarmtable.vue?vue&type=style&index=0&id=2566437c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports