<template>
  <div>
    <div ref="mapDom" style="width: 100%; height: 100%" />
    <div class="map-control" v-if="controlVisable">
      <div class="btn" @click="lushuPlay" v-if="!play">
        <i class="el-icon-video-play"></i>
      </div>
      <div class="btn" @click="lushuPause" v-else>
        <i class="el-icon-video-pause"></i>
      </div>
      <el-slider class="slider" v-model="jump" @change="jumpTo" :step="1" :max="count - 1"
        :format-tooltip="formatTooltip"></el-slider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    points: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      icon: {
        url: "http://api.map.baidu.com/library/LuShu/1.2/examples/car.png",
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } }
      },
      map: null,
      lushu: null,
      path: [],
      lushuOverlays: {},
      controlVisable: false,
      play: false,
      count: 0,
      jump: 0
    };
  },
  created() {
    console.log("created");
    document.addEventListener("mapLushuStop", this.stopHandler);
    document.addEventListener("mapLushuMoveNext", this.updateHandler);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.lushu) {
      this.lushu.stop();
    }
    this.map.removeEventListener("zoomend", this.zoomHandler);
    this.lushu = null;
    this.map = null;
    document.removeEventListener("mapLushuStop", this.stopHandler);
    document.removeEventListener("mapLushuMoveNext", this.updateHandler);

  },
  watch: {
    points: {
      handler: function (val) {
        this.path = val.map(v => {
          return new BMap.Point(v.longitude, v.latitude);
        });

        let landmarkPoints = val.map(v => {
          return {
            lng: Number(v.longitude),
            lat: Number(v.latitude),
            html: `时间：${v.operdate}${v.temperature ? '<p>温度：' + v.temperature + '<p>' : ''}`
          };
        });
        this.count = this.path.length;
        if (this.count) {
          this.controlVisable = true;
          this.map.clearOverlays();
        } else {
          this.controlVisable = false;
          return
        }
        this.map.setViewport(this.path, { margins: [30, 30, 30, 30] });
        // 重写原型链_moveNext: 修改不能到最后一个的bug
        BMapLib.LuShu.prototype._moveNext = function (index) {
          var me = this;
          if (index < this._path.length - 1) {
            me._move(
              me._path[index],
              me._path[index + 1],
              me._tween.linear,
              index
            );
          }
          if (index == this._path.length - 1) {
            me._move(me._path[index], null, me._tween.linear, index);
          }
          let nextEvent = new Event("mapLushuMoveNext");
          nextEvent.detail = index;
          document.dispatchEvent(nextEvent);
        };

        BMapLib.LuShu.prototype.jumpTo = function (index) {
          let me = this;
          if (me._fromPause == false && me._fromStop == false) {
            me.pause();
          }
          me.start();
          me.i = index;
        };

        BMapLib.LuShu.prototype.stop = function () {
          this.i = 0;
          this._fromStop = true;
          clearInterval(this._intervalFlag);
          this._clearTimeout();
          //重置landmark里边的poi为未显示状态
          for (
            var i = 0, t = this._opts.landmarkPois, len = t.length;
            i < len;
            i++
          ) {
            t[i].bShow = false;
          }
          let stopEvent = new Event("mapLushuStop");
          document.dispatchEvent(stopEvent);
        };
        BMapLib.LuShu.prototype._move = function (
          initPos,
          targetPos,
          effect,
          index
        ) {
          var me = this,
            //当前的帧数
            currentCount = 0,
            //步长，米/秒
            timer = 10,
            step = this._opts.speed / (1000 / timer),
            //初始坐标
            initPos = this._projection.lngLatToPoint(initPos),
            //获取结束点的(x,y)坐标
            targetPos = this._projection.lngLatToPoint(targetPos),
            //总的步长
            count = Math.round(me._getDistance(initPos, targetPos) / step);
          //如果小于1直接移动到下一点
          if (count < 1) {
            me._moveNext(++me.i);
            return;
          }
          //两点之间匀速移动
          me._intervalFlag = setInterval(function () {
            //两点之间当前帧数大于总帧数的时候，则说明已经完成移动
            if (currentCount >= count) {
              clearInterval(me._intervalFlag);
              //移动的点已经超过总的长度
              if (me.i > me._path.length) {
                return;
              }
              //运行下一个点
              me._moveNext(++me.i);
            } else {
              //正在移动
              currentCount++;
              var x = effect(initPos.x, targetPos.x, currentCount, count),
                y = effect(initPos.y, targetPos.y, currentCount, count),
                pos = me._projection.pointToLngLat(new BMap.Pixel(x, y));
              if (currentCount == 1) {
                let proPos = null;
                if (me.i - 1 >= 0) {
                  proPos = me._path[me.i - 1];
                }
                if (
                  me._opts.enableRotation == true &&
                  me.i < me._path.length - 1
                ) {
                  me.setRotation(proPos, me._path[me.i], me._path[me.i + 1]);
                }
                if (me._opts.autoView) {
                  if (!me._map.getBounds().containsPoint(pos)) {
                    me._map.setCenter(pos);
                  }
                }
              }
              //设置marker
              me._marker.setPosition(pos);
              //设置自定义overlay的位置
              me._setInfoWin(pos, index);
              me.showInfoWindow();
            }
          }, timer);
        };

        BMapLib.LuShu.prototype._setInfoWin = function (pos, index) {
          //设置上方overlay的position
          var me = this;
          me._overlay.setPosition(pos, me._marker.getIcon().size);
          var id = me._troughPointIndex(pos, index);
          if (id != -1) {
            clearInterval(me._intervalFlag);
            me._pauseForView(index);
          }

          me._overlay.setHtml(me._opts.landmarkPois[index].html);
          me._overlay.setPosition(pos, me._marker.getIcon().size);
          if (index == this._path.length - 1) {
            me.stop();
          }
        };
        BMapLib.LuShu.prototype._troughPointIndex = function (markerPoi, index) {
          var t = this._opts.landmarkPois,
            distance;
          //landmarkPois中的点没有出现过的话
          distance = this._map.getDistance(
            new BMap.Point(t[index].lng, t[index].lat),
            markerPoi
          );
          //两点距离小于10米，认为是同一个点
          if (distance == 0) {
            return index;
          }
          return -1;
        };
        this.initLushu(this.path, landmarkPoints);
        this.addLushuOverlay(this.path);
      }
    }
  },
  methods: {
    stopHandler() {
      this.play = false;
    },
    updateHandler(info) {
      console.log(info.detail);
      this.jump = info.detail;
      this.playGreenLine(info.detail + 1);
      this.play = true;
    },
    formatTooltip(val) {
      if (val != null) {
        return `${this.points[val].operdate}(${val + 1}/${this.count})`;
      }
    },
    init() {
      if (this.map) {
        return;
      }
      let $el = this.$refs.mapDom;
      const map = new BMap.Map($el);
      this.map = map;
      map.reset();
      map.centerAndZoom(new BMap.Point(116.404, 39.915), 13);

      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      // 添加带有定位的导航控件
      let navigationControl = new BMap.NavigationControl({
        // 靠左上角位置
        anchor: BMAP_ANCHOR_TOP_LEFT,
        // LARGE类型
        type: BMAP_NAVIGATION_CONTROL_LARGE,
        // 启用显示定位
        enableGeolocation: true
      });
      map.addControl(navigationControl);
      map.addEventListener("zoomend", this.zoomHandler);
    },
    zoomHandler(param1, param2) {
      const zoomLevel = this.map.getZoom();
      console.log(param1, param2)
      console.log({ zoomLevel })
    },
    /**
     * 在地图上添加覆盖物
     * @param point：point
     * @param w
     * @param h
     * @param url
     */
    drawMapIcon(point, w, h, url, ba = "") {
      let icon = new BMap.Icon(url, new BMap.Size(w, h), {
        anchor: new BMap.Size(8, 25)
      });
      let marker = new BMap.Marker(point, { icon }); // 创建标注
      marker.ba = ba;
      this.map.addOverlay(marker); // 将标注添加到地图中;
      return marker;
    },
    initLushu(path, landmarkPoints) {
      let carIcon = new BMap.Icon(
        "http://api.map.baidu.com/library/LuShu/1.2/examples/car.png",
        new BMap.Size(52, 26),
        { anchor: new BMap.Size(27, 13) }
      );

      let lushuObj = {
        landmarkPois: [],
        icon: carIcon,
        speed: 100 * this.count, //速度，单位米每秒
        autoView: true,
        enableRotation: true,
        landmarkPois: landmarkPoints
      };
      if (this.lushu != null) {
        this.jump = 0;
        this.lushu.stop();
        this.lushu = null;
      }
      this.lushu = new BMapLib.LuShu(this.map, path, lushuObj); //初始化路书；
    },
    addLushuOverlay(path) {
      let length = path.length;
      let startPoint = path[0];
      let endPoint = path[length - 1];
      let oneStart = this.drawMapIcon(
        startPoint,
        30,
        34,
        "/depart_markers.png",
        "oneStart"
      );
      let oneEnd = this.drawMapIcon(
        endPoint,
        30,
        34,
        "/dest_markers.png",
        "oneEnd"
      );

      var sy = new BMap.Symbol(BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
        scale: 0.5,
        strokeColor: "#cf8",
        strokeWeight: "2"
      });
      var icons = new BMap.IconSequence(sy, "100%", "5%");
      let blueLine = new BMap.Polyline(path, {
        icons: [icons],
        strokeColor: "#2962FF",
        strokeWeight: 6,
        strokeOpacity: 0.8
      });
      blueLine.ba = "blueLine";
      this.map.addOverlay(blueLine); //添加轨迹到地图
      let greenLine = new BMap.Polyline([], {
        strokeColor: "#00F53D",
        strokeWeight: 5,
        strokeOpacity: 0.9
      });
      greenLine.ba = "greenLine";
      this.map.addOverlay(greenLine);
      this.lushuOverlays = { blueLine, greenLine, oneStart, oneEnd }; //自定义的路书覆盖物集合对象；
    },
    lushuPlay() {
      this.lushu.start();

      this.play = true;
    },
    lushuPause() {
      this.lushu.pause();
      this.play = false;
    },
    jumpTo(index) {
      if (index < this.count - 1) {
        this.lushu.jumpTo(index);
      } else {
        this.lushu.stop();
      }
    },
    playGreenLine(index) {
      let oy = this.lushuOverlays.greenLine;
      let oy0 = this.lushuOverlays.blueLine;
      let arr2 = [];
      for (let i in this.path) {
        if (i < index) {
          arr2.push(this.path[i]);
        }
      }
      oy.setPath(arr2);
      this.map.removeOverlay(oy0);
      this.map.addOverlay(oy0);
      this.map.removeOverlay(oy);
      this.map.addOverlay(oy);
    }
  }
};
</script>
<style lang='scss' scoped>
.map-control {
  padding: 7px 15px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 600px;
  transform: translateX(-50%);
  background: rgba($color: #000, $alpha: 0.4);
  box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  border-radius: 7px;
  z-index: 99;
  display: flex;
  align-items: center;

  .btn {
    font-size: 25px;
    margin-top: 7px;
    width: 50px;
    color: #fff;
  }

  .slider {
    flex: 1;

    /deep/.el-slider__runway,
    /deep/.el-slider__bar {
      height: 3px;
    }
  }
}
</style>