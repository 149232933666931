<template>
  <span class="timerbtn" @click="run" :disabled="disable || time > 0">{{ text }}</span>
</template>
<script>
export default {
  name: "timer-btn",
  props: {
    second: {
      type: Number,
      default: 60
    }
  },
  data: function() {
    return {
      time: 0,
      disable: false
    };
  },
  methods: {
    run: function() {
      if (!this.disable) {
        this.$emit("run");
      }
    },
    start: function() {
      this.disable = true;
      this.time = this.second;
      this.timer();
    },
    stop: function() {
      this.time = 0;
      this.disable = false;
    },
    timer: function() {
      if (this.time > 0) {
        this.time -= 1;
        let that = this;
        setTimeout(function() {
          that.timer();
        }, 1000);
      } else {
        this.disable = false;
      }
    }
  },
  computed: {
    text: function() {
      return this.time > 0 ? this.time + "s 后重新获取" : "获取验证码";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.timerbtn {
  line-height: 34px;
  display: inline-block;
  width: 105px;
  text-align: center;
  height: 34px;
  color: #5ca5eb;
  background: #fff;
  border: 0;
  cursor: pointer;
  outline: 0;
}
</style>