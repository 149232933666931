<template>
  <div>
    <div ref="mapDom" style="width: 100%; height: 100%" />
  </div>
</template>

<script>
export default {
  props: {
    points: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      map: null,
      path: [],
      count: 0,
      jump: 0
    };
  },
  created() {
    console.log("created");
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {

    this.map.removeEventListener("zoomend", this.zoomHandler);
    this.map = null;

  },
  watch: {
    points: {
      handler: function (val) {
        // this.path = val.map(v => {
        //   return new BMap.Point(v.longitude, v.latitude);
        // });

        let length = val.length;
        this.drawPathAndPoints(val)
        if (length > 1) {
          let startPoint = new BMap.Point(val[0].longitude, val[0].latitude);
          let endPoint = new BMap.Point(val[length - 1].longitude, val[length - 1].latitude);
          this.centerMapOnPoints(startPoint, endPoint)
        }
      }
    },
  },
  methods: {
    init() {
      if (this.map) {
        return;
      }
      let $el = this.$refs.mapDom;
      const map = new BMap.Map($el);
      this.map = map;
      map.reset();
      map.centerAndZoom(new BMap.Point(116.404, 39.915), 13);

      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      // 添加带有定位的导航控件
      let navigationControl = new BMap.NavigationControl({
        // 靠左上角位置
        anchor: BMAP_ANCHOR_TOP_LEFT,
        // LARGE类型
        type: BMAP_NAVIGATION_CONTROL_LARGE,
        // 启用显示定位
        enableGeolocation: true
      });
      map.addControl(navigationControl);
      map.addEventListener("zoomend", this.zoomHandler);
    },
    zoomHandler(param) {
      // const zoomLevel = this.map.getZoom();
      this.drawPathAndPoints(this.points)
    },

    drawPathAndPoints(points) {
      this.map.clearOverlays();
      const noDuplicatePoints = []
      let duplicateCheck = ""
      for (const point of points) {
        let check = `${parseFloat(point.longitude).toFixed(5)}:${parseFloat(point.latitude).toFixed(5)}`
        if (check == duplicateCheck) {
          continue
        } else {
          noDuplicatePoints.push(point)
          duplicateCheck = check
        }
      }
      let length = noDuplicatePoints.length;
      let sy = new BMap.Symbol(BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
        scale: 0.5,
        strokeColor: "#cf8",
        strokeWeight: "2"
      });
      const path = []
      for (let i = 0; i < length; i++) {
        let point = noDuplicatePoints[i]
        path.push(new BMap.Point(point.longitude, point.latitude))
      }
      let icons = new BMap.IconSequence(sy, "100%", "5%");
      let polyline = new BMap.Polyline(path, { icons: [icons], strokeColor: "#2962FF", strokeWeight: 6, strokeOpacity: 0.8 });
      this.map.addOverlay(polyline);
      let commonIcon = new BMap.Icon('/ic-gps-o.svg', new BMap.Size(15, 15));
      commonIcon.setImageSize(new BMap.Size(15, 15))
      let startIcon = new BMap.Icon('/depart_markers.png', new BMap.Size(25, 27), { anchor: new BMap.Size(13, 17) });
      startIcon.setImageSize(new BMap.Size(25, 27))
      let endIcon = new BMap.Icon('/dest_markers.png', new BMap.Size(25, 27), { anchor: new BMap.Size(13, 17) });
      endIcon.setImageSize(new BMap.Size(25, 27))
      let index = 0
      for (const point of noDuplicatePoints) {
        let icon = commonIcon
        if (index == 0) {
          icon = startIcon
        }
        if (index == length - 1) {
          icon = endIcon
        }
        index++;
        const mapPoint = path[index - 1];
        const marker = new BMap.Marker(mapPoint, { title: `${point.devicename}: ${point.operdate}`, icon: icon });
        this.map.addOverlay(marker);
      }

    },
    centerMapOnPoints(startPoint, endPoint) {
      // 创建起点和终点的百度地图坐标对象
      const bdStartPoint = new BMap.Point(startPoint.lng, startPoint.lat);
      const bdEndPoint = new BMap.Point(endPoint.lng, endPoint.lat);

      // 创建包含起点和终点的矩形区域
      const bounds = new BMap.Bounds(bdStartPoint, bdEndPoint);

      // 调整地图视野使矩形区域内的内容完全显示
      this.map.setViewport(bounds);
      // this.map.setZoom(14);
    }
  }
};
</script>

<style lang='scss' scoped>
.map-control {
  padding: 7px 15px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 600px;
  transform: translateX(-50%);
  background: rgba($color: #000, $alpha: 0.4);
  box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  border-radius: 7px;
  z-index: 99;
  display: flex;
  align-items: center;

  .btn {
    font-size: 25px;
    margin-top: 7px;
    width: 50px;
    color: #fff;
  }

  .slider {
    flex: 1;

    /deep/.el-slider__runway,
    /deep/.el-slider__bar {
      height: 3px;
    }
  }
}
</style>