<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="库房名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getLocalWarehouseInfo">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="roletype > 4" type="primary" @click="handleAdd"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table
      :data="warehouselist"
      v-loading="listLoading"
      style="width: 100%"
      stripe
      highlight-current-row
    >
      <el-table-column type="index" width="54"> </el-table-column>
      <el-table-column
        prop="warehousename"
        label="库房名称"
        width="120"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="devicename"
        label="设备名称"
        width="130"
        min-width="130"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="mintemperature"
        label="温度下限(℃)"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="maxtemperature"
        label="温度上限(℃)"
        min-width="120"
      >
      </el-table-column>
      <el-table-column prop="minhumidity" label="湿度下限(%)" min-width="120">
      </el-table-column>
      <el-table-column prop="maxhumidity" label="湿度上限(%)" min-width="120">
      </el-table-column>
      <el-table-column prop="datainterval" label="记录间隔(分)" min-width="120">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150" min-width="150">
        <template slot-scope="scope">
          <el-button
            :disabled="roletype > 4"
            size="small"
            @click="handleEdit(scope.$index, scope.row)"
          >
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button
            :disabled="roletype > 4"
            type="warning"
            size="small"
            @click="handleDel(scope.$index, scope.row)"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <!--<el-col :span="24" class="toolbar">
			<el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
			</el-pagination>
		</el-col>-->

    <!--编辑界面-->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        label-width="100px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="库房名称" prop="warehousename">
          <el-input v-model="editForm.warehousename"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceid">
          <el-select v-model="editForm.deviceid" auto-complete="off">
            <el-option
              v-for="item in devicemodels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="温度上下限" required>
          <template>
            <el-input-number
              v-model.number="editForm.mintemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃ ~</span>
            <el-input-number
              v-model.number="editForm.maxtemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃</span>
          </template>
        </el-form-item>
        <el-form-item label="湿度上下限" required>
          <template>
            <el-input-number
              v-model.number="editForm.minhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>% ~</span>
            <el-input-number
              v-model.number="editForm.maxhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>%</span>
          </template>
        </el-form-item>
        <el-form-item label="记录间隔" prop="datainterval" required>
          <el-input-number
            v-model.number="editForm.datainterval"
            :step="1"
            :min="1"
            :max="120"
          ></el-input-number>
          <span>分钟</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="editSubmit"
          :loading="editLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-width="100px"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-form-item label="库房名称" prop="warehousename" required>
          <el-input
            v-model="addForm.warehousename"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceid">
          <el-select v-model="addForm.deviceid" auto-complete="off">
            <el-option
              v-for="item in devicemodels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="温度上下限" required>
          <template>
            <el-input-number
              v-model.number="addForm.mintemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃ ~</span>
            <el-input-number
              v-model.number="addForm.maxtemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃</span>
          </template>
        </el-form-item>
        <el-form-item label="湿度上下限" required>
          <template>
            <el-input-number
              v-model.number="addForm.minhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>% ~</span>
            <el-input-number
              v-model.number="addForm.maxhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>%</span>
          </template>
        </el-form-item>
        <el-form-item label="记录间隔" prop="datainterval" required>
          <el-input-number
            v-model.number="addForm.datainterval"
            :step="1"
            :min="1"
            :max="120"
          ></el-input-number>
          <span>分钟</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="addSubmit"
          :loading="addLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      devicemodels: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        shortcuts: [
          {
            text: "一年",
            onClick(picker) {
              picker.$emit(
                "pick",
                new Date(Date.now() + 3600 * 1000 * 24 * 365)
              );
            },
          },
          {
            text: "两年",
            onClick(picker) {
              picker.$emit(
                "pick",
                new Date(Date.now() + 3600 * 1000 * 24 * 730)
              );
            },
          },
          {
            text: "三年",
            onClick(picker) {
              picker.$emit(
                "pick",
                new Date(Date.now() + 3600 * 1000 * 24 * 1095)
              );
            },
          },
        ],
      },
      filters: {
        name: "",
      },
      warehouselist: [],
      total: 0,
      //page: 1,
      listLoading: false,

      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        warehousename: [
          {
            required: true,
            message: "请输入库房名称",
            trigger: "blur",
          },
        ],
      },
      //编辑界面数据
      editForm: {
        warehouseid: "",
        warehousename: "",
        deviceid: "",
        customerid: "",
        devicename: "",
        mintemperature: "",
        maxtemperature: "",
        minhumidity: "",
        maxhumidity: "",
        datainterval: "",
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        warehousename: [
          {
            required: true,
            message: "请输入库房名称",
            trigger: "blur",
          },
        ],
      },
      //新增界面数据
      addForm: {
        warehousename: "",
        deviceid: "",
        mintemperature: "",
        maxtemperature: "",
        minhumidity: "",
        maxhumidity: "",
        datainterval: "",
        customerid: "",
      },
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    localdevicetypeModels: function () {
      // 0:超管，1:保留，2:代理商超管，3:保留，4:客户管理员，5:客户普通用户
      if (this.roletype < 4) {
        // 代理商 管理员登录
        return [
          {
            label: "本地库房pi",
            value: 1,
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getLocalWarehouseInfo();
    },
    //获取本地设备管理列表
    getLocalWarehouseInfo() {
      let para = {
        customerid: this.customerid,
        name: this.filters.name,
      };
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/localmanagement/getlocalwarehouseslist", para)
        .then((reply) => {
          this.listLoading = false;
          NProgress.done();
          if (reply.data.code === 0) {
            if (reply.data.total === 0)
              this.$message({
                message: `没有数据`,
                type: "warning",
              });
            this.warehouselist = reply.data.values.warehouseslist;
          } else {
            this.$message({
              message: `查询出错，错误码${reply.data.code},请联系管理员`,
              type: "warning",
            });
          }
        });
    },
    //删除
    handleDel: function (index, row) {
      this.$confirm(
        "<strong>删除该设备后，原有历史数据将一并删除！此操作将永久删除该设备或库房,请务必谨慎操作！！是否继续?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
          type: "warning",
        }
      ).then(() => {
        let params = {
          warehouseid: row.warehouseid,
          customerid: this.customerid,
        };
        this.listLoading = true;
        NProgress.start();
        this.axios
          .post("/localmanagement/deletelocalwarehouse", params)
          .then((res) => {
            this.listLoading = false;
            NProgress.done();
            if (res.data.code === 0) {
              this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success",
              });
              this.getLocalWarehouseInfo();
            } else if (res.data.code === 102) {
              this.$message({
                message: "您的权限不足",
                type: "warning",
              });
            } else if (res.data.code === 109) {
              this.$message({
                message: "无法删除该库房，该库房仍有绑定的传感器",
                type: "warning",
              });
            } else {
              this.$message({
                message: `删除失败，错误码${res.data.code},请联系管理员`,
                type: "warning",
              });
            }
          });
      });
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign(this.editForm, row);
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.editForm);
            Object.assign(params, {
              customerid: this.customerid,
            });
            this.axios
              .post("/localmanagement/editlocalwarehouse", params)
              .then((res) => {
                this.editLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$notify({
                    title: "成功",
                    message: "编辑成功",
                    type: "success",
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getLocalWarehouseInfo();
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "您的权限不足",
                    type: "warning",
                  });
                } else {
                  this.$message({
                    message: `保存失败，错误码${res.data.code},请联系管理员`,
                    type: "warning",
                  });
                }
              });
          });
        }
      });
    },
    //显示新增界面
    handleAdd: function () {
      this.addFormVisible = true;
      this.addForm = {
        warehousename: "",
        deviceid: "",
        mintemperature: "",
        maxtemperature: "",
        minhumidity: "",
        maxhumidity: "",
        datainterval: "",
        customerid: "",
      };
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.addForm);
            Object.assign(params, {
              customerid: this.customerid,
            });
            this.axios
              .post("/localmanagement/addlocalwarehouse", params)
              .then((res) => {
                this.addLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getLocalWarehouseInfo();
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "保存失败，权限不足,请联系管理员",
                    type: "warning",
                  });
                } else {
                  this.$message({
                    message: `保存出错，错误码${res.data.code},请联系管理员`,
                    type: "warning",
                  });
                }
              });
          });
        }
      });
    },
  },
  mounted() {
    this.getLocalWarehouseInfo();
    let para = {
      customerid: this.customerid,
    };
    this.axios.post("/localmanagement/getlocaldevices", para).then((reply) => {
      this.devicemodels = reply.data.values.devices;
    });
  },
};
</script>

<style>
.toolbar {
  width: 100%;
}
</style>