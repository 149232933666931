<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar el-col el-col-24" style="padding-bottom: 0px;">
      <el-tabs v-model="tabSelected" @tab-click="handleTabClick">
        <el-tab-pane label="本地设备" name="first" v-if="usercustomerid != 76 || usercustomerid != 289 || usercustomerid != 381">
          <el-form :inline="true" :rules="searchRules">
            <el-form-item>
              <div>
                <span>选择设备或仓库</span>
                <el-cascader :options="localSelectedOptions" v-model="search.getdeviceslist" :show-all-levels="false"
                  placeholder="关键字搜索" @change="getSensorsList" clearable change-on-select filterable></el-cascader>
              </div>
            </el-form-item>
            <el-form-item>
              <span>传感器号</span>
              <el-select v-model="search.sensorsOptions" placeholder="请选择传感器" clearable>
                <el-option v-for="item in sensorslist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template>
                <span>起止时间</span>
                <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间"></el-date-picker>
              </template>
            </el-form-item>
            <el-form-item>
              <span>温度</span>
              <el-switch v-model="search.temperflag" @change="temperatureSwitchChange"></el-switch>
            </el-form-item>
            <el-form-item>
              <span>湿度</span>
              <el-switch v-model="search.humiflag" @change="humiditySwitchChange"></el-switch>
            </el-form-item>
            <el-form-item>
              <span>包含报警数据</span>
              <el-switch v-model="search.alarmflag"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="searchHistoryList" :disabled="searchUseable">查询</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="云端设备" name="second">
          <el-form :inline="true" :rules="searchRules">
            <el-form-item>
              <div>
                <span>选择仓库</span>
                <el-cascader :options="cloudSelectedOptions" v-model="search.getdeviceslist" :show-all-levels="false"
                  placeholder="关键字搜索" @change="getSensorsList" clearable filterable></el-cascader>
              </div>
            </el-form-item>
            <el-form-item>
              <span>传感器号</span>
              <el-select v-model="search.sensorsOptions" placeholder="请选择传感器" clearable>
                <el-option v-for="item in sensorslist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template>
                <span>起止时间</span>
                <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间"></el-date-picker>
              </template>
            </el-form-item>
            <el-form-item>
              <span>温度</span>
              <el-switch v-model="search.temperflag" @change="temperatureSwitchChange"></el-switch>
            </el-form-item>
            <el-form-item>
              <span>湿度</span>
              <el-switch v-model="search.humiflag" @change="humiditySwitchChange"></el-switch>
            </el-form-item>
            <el-form-item>
              <span>包含报警数据</span>
              <el-switch v-model="search.alarmflag"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="searchHistoryList" :disabled="searchUseable">查询</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <div id="container" style="height:500px; max-width:100%;"></div>
    </el-col>
    <!--<highcharts :options="highchartoptions" ref="hischarts"></highcharts>-->
  </section>
</template>

<script>
import * as echarts from "echarts";
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tabSelected: "first",
      myChart: null,
      localSelectedOptions: [],
      cloudSelectedOptions: [
        {
          value: 2,
          label: "云库房",
          children: []
        },
        {
          value: 3,
          label: "保温箱",
          children: []
        },
        {
          value: 4,
          label: "冷藏车",
          children: []
        },
        {
          value: 6,
          label: "STL冷链箱",
          children: []
        }
      ],
      sensorslist: [],
      search: {
        getdeviceslist: [],
        sensorsOptions: null,
        daterange: null,
        temperflag: true,
        humiflag: true,
        alarmflag: true
      },
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() < Date.now() - 3600 * 1000 * 24 * 365 *5 - 8.64e7; //最多五年前
        // },
        shortcuts: [
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      listLoading: false,
      searchRules: {},
      deviceName: ""
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid", 'usercustomerid']),
    searchUseable: function () {
      if (
        this.search.getdeviceslist == "" ||
        this.search.daterange == null ||
        this.search.sensorsOptions == null
      )
        return true;
      else return false;
    }
  },
  methods: {
    temperatureSwitchChange: function (status) {
      if (!status) this.search.humiflag = true;
    },
    humiditySwitchChange: function (status) {
      if (!status) this.search.temperflag = true;
    },

    handleTabClick() {
      this.search.getdeviceslist = [];
      this.search.sensorsOptions = null;
      this.deviceName = ""
    },
    handleCurrentChange(val) {
      this.searchHistoryList();
    },

    //获取设备列表
    getDevicesList() {
      let para = {
        customerid: this.customerid
      };
      NProgress.start();
      this.axios.post("/datacenter/getdeviceslist", para).then(res => {
        NProgress.done();
        if (res.data.code === 0) {
          res.data.values.deviceslist.map(device => {
            if (device.devicetype == 1) {
              //本地库房
              this.localSelectedOptions.push({
                value: device.deviceid,
                label: device.devicename,
                children: []
              });
              let len = this.localSelectedOptions.length;
              if (len > 1) {
                if (
                  this.localSelectedOptions[len - 2].value ==
                  this.localSelectedOptions[len - 1].value
                ) {
                  this.localSelectedOptions.pop();
                }
              }
              this.localSelectedOptions.map(opt => {
                if (opt.value == device.deviceid) {
                  opt.children.push({
                    value: device.warehouseid,
                    label: device.warehousename
                  });
                }
                return opt;
              });
            } else {
              this.cloudSelectedOptions.map(opt => {
                if (opt.value == device.devicetype) {
                  opt.children.push({
                    value: device.warehouseid,
                    label: device.warehousename
                  });
                }
                return opt;
              });
            }
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },
    //获取传感器列表
    getSensorsList() {
      let para = {};
      let len = this.search.getdeviceslist.length;
      if (len == 2) {
        para = {
          searchtype: len,
          warehouseid: this.search.getdeviceslist[1]
        };
      } else if (len == 1) {
        para = {
          searchtype: len,
          deviceid: this.search.getdeviceslist[0]
        };
      } else {
        return this.$message({
          message: `请选择仓库`,
          type: "warning"
        });
      }
      NProgress.start();
      this.axios.post("/datacenter/getsensorslist", para).then(res => {
        NProgress.done();
        if (res.data.code === 0) {
          this.sensorslist = [];
          this.search.sensorsOptions = null;
          res.data.values.sensorslist.map(sensor => {
            this.sensorslist.push({
              value: sensor.sensorid,
              label: `${sensor.sensorcode}#`
            });
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },
    searchHistoryList() {
      if (
        this.search.daterange[1] - this.search.daterange[0] >
        31 * 24 * 3600 * 1000 * 2
      ) {
        return this.$message({
          message: `查询时间跨度过大，请选择2个月内的时间范围。`,
          type: "warning"
        });
      }
      let type = this.tabSelected == "first" ? 1 : 2; //用于区分当前查询的类型是本地还是云端
      let customerid = this.customerid;
      let deviceid = this.search.getdeviceslist[0];
      let searchtype = this.search.getdeviceslist.length;
      let warehouseid = searchtype == 2 ? this.search.getdeviceslist[1] : null;
      let fromdate = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let todate = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let sensorid = this.search.sensorsOptions || null;
      let isintervalrecord = this.search.alarmflag ? 1 : 0;
      let params = {
        type,
        searchtype,
        customerid,
        fromdate,
        todate,
        deviceid,
        warehouseid,
        isintervalrecord,
        sensorid
      };
      if (this.tabSelected == "first") {
        for (let deviceKind of this.localSelectedOptions) {
          for (let device of deviceKind.children) {
            if (device.value == this.search.getdeviceslist[1]) {
              this.deviceName = device.label
            }
          }
        }
      } else {
        for (let deviceKind of this.cloudSelectedOptions) {
          for (let device of deviceKind.children) {
            if (device.value == this.search.getdeviceslist[1]) {
              this.deviceName = device.label
            }
          }
        }
      }
      NProgress.start();
      this.axios.post("/datacenter/gethistorygraph", params).then(res => {
        NProgress.done();
        if (res.data.code === 0) {
          this.myChart = echarts.init(document.getElementById("container"));

          let option = {
            title: {
              text: this.deviceName + " 历史图表",
              subtext: "历史温湿度数据变化图表\n 查询间隔时间：" + fromdate + " - " + todate,
              x: "center"
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                animation: false
              }
            },
            toolbox: {
              //工具条
              show: true,
              itemSize: 10,
              right: "5%",
              feature: {
                dataZoom: {
                  yAxisIndex: "none"
                },
                saveAsImage: {
                  show: true
                }
              }
            },
            xAxis: {
              //X轴
              type: "category",
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {
                showMaxLabel: true
              },
              data: []
            },
            yAxis: [
              {
                type: "value",
                scale: true,
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return Math.floor(value.min) - Math.floor((value.max - value.min) / 2);
                },
                max: function (value) {
                  return Math.ceil(value.max) + Math.ceil((value.max - value.min) / 2);
                }
              }
            ],
            series: [], // 数据
            legend: {
              //图例
              orient: "vertical",
              left: "right",
              top: "50%",
              data: []
            },
            color: [
              "#2f4554",
              "#61a0a8",
              "#91c7ae",
              "#749f83",
              "#bda29a",
              "#6e7074",
              "#546570",
              "#c4ccd3"
            ]
          };
          let start = new Date();
          let SensorCode,
            mintemperature,
            maxtemperature,
            minhumidity,
            maxhumidity = null;
          let timeArray = res.data.values.data.map((c, index) => {
            if (index == 0) {
              SensorCode = c.SensorCode;
              mintemperature = c.MinTemperature;
              maxtemperature = c.MaxTemperature;
              minhumidity = c.MinHumidity;
              maxhumidity = c.MaxHumidity;
            }
            return new Date(c.OperDate).format("yyyy-mm-dd HH:MM:ss");
          });
          console.log(SensorCode,
            mintemperature,
            maxtemperature,
            minhumidity,
            maxhumidity)
          timeArray = [...new Set(timeArray)];
          let TemperatureArray = res.data.values.data.map(c => c.Temperature);
          let HumidityArray = res.data.values.data.map(c => c.Humidity);
          for (let i = 0; i < 2; i++) {
            let temperArray = {
              name: "",
              type: "line",
              smooth: true,
              data: [],
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "最大值"
                  },
                  {
                    type: "min",
                    name: "最小值"
                  }
                ]
              },
              markLine: {
                data: [
                  {
                    type: "average",
                    name: "平均值"
                  },
                  {
                    name: "最小值",
                    yAxis: 0
                  },
                  {
                    name: "最大值",
                    yAxis: 0
                  }
                ]
              }
              // markArea: {
              //   itemStyle: {
              //     normal: {
              //       color: "rgba(68, 170, 213, 0.1)",
              //       borderWith: 1
              //     }
              //   },
              //   data: [
              //     [
              //       {
              //         name: "正常温度范围",
              //         yAxis: 20
              //       },
              //       {
              //         yAxis: 0
              //       }
              //     ]
              //   ]
              // }
            };
            let humiArray = {
              name: "",
              type: "line",
              smooth: true,
              data: [],
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "最大值"
                  },
                  {
                    type: "min",
                    name: "最小值"
                  }
                ]
              },
              markLine: {
                data: [
                  {
                    type: "average",
                    name: "平均值"
                  },
                  {
                    name: "最小值",
                    yAxis: 0
                  },
                  {
                    name: "最大值",
                    yAxis: 0
                  }
                ]
              }
              // markArea: {
              //   itemStyle: {
              //     normal: {
              //       color: "rgba(68, 170, 213, 0.1)",
              //       borderWith: 1
              //     }
              //   },
              //   data: [
              //     [
              //       {
              //         name: "正常湿度范围",
              //         yAxis: 35
              //       },
              //       {
              //         yAxis: 75
              //       }
              //     ]
              //   ]
              // }
            };

            temperArray.data = TemperatureArray;
            // temperArray.markArea.data[0][0].yAxis = mintemperature;
            // temperArray.markArea.data[0][1].yAxis = maxtemperature;
            temperArray.markLine.data[1].yAxis = mintemperature;
            temperArray.markLine.data[2].yAxis = maxtemperature;
            temperArray.name = `温度(℃)：${SensorCode}#`;

            humiArray.data = HumidityArray;
            // humiArray.markArea.data[0][0].yAxis = minhumidity;
            // humiArray.markArea.data[0][1].yAxis = maxhumidity;
            humiArray.markLine.data[1].yAxis = minhumidity;
            humiArray.markLine.data[2].yAxis = maxhumidity;
            humiArray.name = `湿度( %)：${SensorCode}#`;
            if (this.search.temperflag) {
              if (!this.search.humiflag) {
                option.series = [];
              }
              option.series.push(temperArray);
              option.legend.data.push(temperArray.name);
              if (!this.search.humiflag) {
                option.title.subtext = "历史温度数据变化图表\n 查询间隔时间：" + fromdate + " - " + todate;

              }
            }
            if (this.search.humiflag) {
              if (!this.search.temperflag) {
                option.series = [];
              }
              option.series.push(humiArray);
              option.legend.data.push(humiArray.name);
              if (!this.search.temperflag) {
                option.title.subtext = "历史湿度数据变化图表\n 查询间隔时间：" + fromdate + " - " + todate;
              }
            }
          }
          option.xAxis.data = timeArray;

          if (option && typeof option === "object") {
            this.myChart.setOption(option, true);
          }
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    }
  },
  mounted() {
    this.getDevicesList();
    if (this.usercustomerid != 76 || this.usercustomerid != 289 || this.usercustomerid != 381) {
      this.tabSelected = 'first'
    } else {
      this.tabSelected = 'second'
    }
  }
};
</script>
<style></style>