<template>
  <section>
    <el-row>
      <el-col :span="24">
        <el-card shadow="hover">
          {{ username }}您好，欢迎使用温湿度本地管理平台, 您的短信余额为
          <b>{{ smsbalance > -1 ? smsbalance : '?' }}</b>条。
          <span v-show="smsbalance > 100">余额充足。</span>
          <span v-show="smsbalance < 100 && smsbalance > 0">余额告急，为保证短信通知功能畅通，请充值。</span>
          <span v-show="smsbalance < 1">已欠费，请及时充值缴费。</span>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>设备信息</span>
          </div>
          <div v-for="item in expiryDevicesList" :key="item.deviceid">
            <el-card shadow="hover" :style="{ margin: '15px' }">
              <span v-if="item.day > 0">
                {{ item.devicetype == 1 ? "库房" : (item.devicetype == 2 ? "云库房" : (item.devicetype == 4 ? "冷藏车" :
                  (item.devicetype == 5 ? "报警器" : "保温箱"))) }}设备
                <router-link to="/mydevice">{{ item.devicecode }}</router-link>
                将在{{ item.day }}天后到期。
              </span>
              <span v-else>
                {{ item.devicetype == 1 ? "库房" : (item.devicetype == 2 ? "云库房" : (item.devicetype == 4 ? "冷藏车" :
                  (item.devicetype == 5 ? "报警器" : "保温箱"))) }}设备
                <router-link to="/mydevice">{{ item.devicecode }}</router-link>
                已过期{{ Math.abs(item.day) }}天。
              </span>
            </el-card>
          </div>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>短信信息</span>
          </div>
          <el-table empty-text="无充值记录" border v-loading="listLoading" :data="rechargeList"
            :default-sort="{ prop: 'operdate', order: 'descending' }">
            <el-table-column prop="operdate" label="充值日期" sortable min-width="100"
              :formatter="formatterDate"></el-table-column>
            <el-table-column prop="username" label="客户"></el-table-column>
            <el-table-column prop="smscount" label="操作记录" width="100">
              <template slot-scope="scope">
                <el-tag :type="scope.row.smscount > 0 ? 'primary' : 'warning'" disable-transitions>{{ scope.row.smscount >
                  0
                  ? '充值' : '消费' }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="smscount" label="数量（条）" sortable>
              <template slot-scope="scope">
                {{ Math.abs(scope.row.smscount) }}
              </template>

            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--列表-->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import twix from "twix";
import moment from "moment";
export default {
  data() {
    return {
      smsbalance: -1,
      rechargeList: [],
      listLoading: false,
      deviceslist: [],
      expiryDevicesList: []
    };
  },
  computed: {
    ...mapGetters(["username", "customerid"])
  },
  methods: {
    formatterDate: (row, column) => {
      return new Date(row.operdate).format("mm-dd HH:MM:ss");
    },
    formatterContent(row, column) {
      let customername = "超级管理员";
      this.agentsList.map(a => {
        if (a.customerid == row.fathercustomerid) customername = a.customername;
      });
      return customername + " " + row.content;
    },
    //获取充值记录列表
    getRechargeList() {
      this.listLoading = true;
      this.axios.get("/user/rechargerecords").then(reply => {
        this.listLoading = false;
        if (reply.data.code === 0) {
          this.rechargeList = reply.data.values.list;
        } else {
          console.log(JSON.stringify(reply.data.error));
          this.$message({
            message: `查询出错，错误码${reply.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },
    //获取设备列表
    getDevices() {
      this.axios
        .post("/user/getdevices", {
          page: 0,
          name: "",
          customerid: this.customerid
        })
        .then(reply => {
          if (reply.data.code === 0) {
            this.deviceslist = reply.data.values.devices.map(dev => {
              dev.day = this.$moment()
                .twix(dev.expiredat)
                .count("days");
              return dev;
            });
            this.expiryDevicesList = this.deviceslist.filter(d => d.day < 30);
          } else {
            this.$message({
              message: `查询出错，错误码${reply.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    }
  },
  mounted() {
    this.getDevices();
    this.getRechargeList();
    this.axios
      .post("/user/smsbalance", { customerid: this.customerid })
      .then(reply => {
        if (reply.data.code == 0) {
          this.smsbalance = reply.data.values;
        } else {
          this.$message({
            message: `查询短信余额出错,请检查网络是否正常。`,
            type: "warning"
          });
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.el-col {
  margin-bottom: 20px;
}

.carousel {
  position: relative;
  width: 1140px;
  margin: 0 auto;
  height: 300px;
  box-shadow: 7px 7px 10px 0 rgba(76, 110, 245, 0.1);
  margin-bottom: 20px;

  .picture {
    width: 475px;
  }

  .introduce {
    background: #fff;
    height: 216px;
    padding: 3em 4em;
  }
}</style>