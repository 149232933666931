<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="用户姓名，手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getUsers">
            <i class="el-icon-search"></i>
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">
            <i class="el-icon-plus"></i>
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="userslist" v-loading="listLoading" style="width: 100%;" stripe highlight-current-row>
      <el-table-column type="index" width="54">
      </el-table-column>
      <el-table-column prop="username" label="用户姓名" width="130" sortable>
      </el-table-column>
      <el-table-column prop="mobilephone" label="手机号码" width="160" min-width="160" sortable>
      </el-table-column>
      <el-table-column prop="usertypeid" label="用户类型" :formatter="formatUserType" width="150" sortable>
      </el-table-column>
      <el-table-column prop="customername" label="所属客户" min-width="180" sortable>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150" min-width="150">
        <template slot-scope="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button type="warning" size="small" @click="handleDel(scope.$index, scope.row)">
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <!--<el-col :span="24" class="toolbar">
			<el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
			</el-pagination>
		</el-col>-->

    <!--编辑界面-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm">
        <el-form-item label="用户姓名" prop="username">
          <el-input v-model="editForm.username" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobilephone">
          <el-input v-model="editForm.mobilephone" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="所属客户" prop="customerid">
          <customer-select :selected="editForm.customerid" @changeVal="toggleEditChange"></customer-select>
        </el-form-item>
        <el-form-item label="用户类型" prop="usertypeid">
          <el-select v-model="editForm.usertypeid" placeholder="请选择用户类型" no-data-text="对不起，您没有权限">
            <el-option v-for="item in usertypeModels" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重置密码" prop="password">
          <el-input type="password" v-model="editForm.password" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm">
        <el-form-item label="用户姓名" prop="username">
          <el-input v-model="addForm.username" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobilephone" required>
          <el-input v-model.number="addForm.mobilephone" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属客户" prop="customerid">
          <customer-select :selected="addForm.customerid" @changeVal="toggleAddChange"></customer-select>
        </el-form-item>
        <el-form-item label="用户类型" prop="usertypeid">
          <el-select v-model="addForm.usertypeid" placeholder="请选择用户类型" no-data-text="对不起，您没有权限">
            <el-option v-for="item in usertypeModels" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="初始密码" prop="password">
          <el-input type="password" v-model="addForm.password" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
import CustomerSelect from "../../components/CustomerSelect";
import validator from 'validator'
export default {
  components: {
    CustomerSelect
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入11位手机号码"));
      }

      if (!Number.isInteger(value)) {
        callback(new Error("请输入数字值"));
      } else if (
        !validator.isMobilePhone(value + "", 'zh-CN')
      )
        callback(new Error("请输入正确的手机号码"));
      else {
        callback();
      }
    };
    return {
      filters: {
        name: ""
      },
      userslist: [],
      // total: 0,
      // page: 1,
      listLoading: false,

      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        username: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请至少输入2个字符的姓名",
            trigger: "blur"
          },
          {
            max: 10,
            message: "请输入少于10个字符的姓名",
            trigger: "blur"
          }
        ],
        usertypeid: [
          {
            type: "number",
            required: true,
            message: "请选择用户类型",
            trigger: "blur"
          }
        ],
        customerid: [
          {
            type: "number",
            required: true,
            message: "请选择所属客户",
            trigger: "blur"
          }
        ]
      },
      //编辑界面数据
      editForm: {
        userid: null,
        username: "",
        mobilephone: "",
        usertypeid: null,
        customerid: null,
        password: ""
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        username: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请至少输入2个字符的姓名",
            trigger: "blur"
          },
          {
            max: 10,
            message: "请输入少于10个字符的姓名",
            trigger: "blur"
          }
        ],
        mobilephone: [
          {
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        usertypeid: [
          {
            type: "number",
            required: true,
            message: "请选择用户类型",
            trigger: "blur"
          }
        ],
        customerid: [
          {
            type: "number",
            required: true,
            message: "请选择所属客户",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入初始密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码不得少于6位",
            trigger: "blur"
          },
          {
            max: 30,
            message: "最大支持30位",
            trigger: "blur"
          }
        ]
      },
      //新增界面数据
      addForm: {
        username: "",
        mobilephone: "",
        usertypeid: null,
        customerid: null,
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    usertypeModels: function () {
      // 0:超管，1:保留，2:代理商超管，3:保留，4:客户管理员，5:客户普通用户
      if (this.roletype == 4) {
        // 客户 管理员登录
        return [
          {
            label: "普通用户",
            value: 5
          }
        ];
      } else if (this.roletype < 4) {
        // 代理商 管理员登录
        return [
          {
            label: "管理员",
            value: 4
          },
          {
            label: "普通用户",
            value: 5
          }
        ];
      } else {
        return [];
      }
    }
  },
  methods: {
    // 0:超管，1:保留，2:代理商超管，3:保留，4:客户管理员，5:客户普通用户
    formatUserType: (row, column) => {
      return row.usertypeid == 0
        ? "超级管理员"
        : row.usertypeid == 2 ? "系统管理员" : row.usertypeid == 4 ? "管理员" : "普通用户";
    },
    // handleCurrentChange(val) {
    // 	this.page = val
    // 	this.getUsers()
    // },
    toggleEditChange(val) {
      this.editForm.customerid = val;
    },
    toggleAddChange(val) {
      this.addForm.customerid = val;
    },
    //获取用户列表
    getUsers() {
      this.listLoading = true;
      NProgress.start();

      this.axios
        .post("/usermanagement/getusers", {
          // page: this.page,
          name: this.filters.name,
          customerid: this.customerid
        })
        .then(reply => {
          this.listLoading = false;
          NProgress.done();
          if (reply.data.code === 0) {
            // this.total = reply.data.values.count
            this.userslist = reply.data.values.users;
          } else {
            this.$message({
              message: `查询出错，错误码${reply.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    },
    //删除
    handleDel: function (index, row) {
      this.$confirm("确认删除该用户吗?", "提示", {
        type: "warning"
      }).then(() => {
        this.listLoading = true;
        NProgress.start();
        this.axios
          .post("/usermanagement/deleteuser", {
            userid: row.userid
          })
          .then(res => {
            this.listLoading = false;
            NProgress.done();
            if (res.data.code === 0) {
              this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success"
              });
              this.getUsers();
            } else {
              this.$message({
                message: `删除失败，错误码${res.data.code},请联系管理员`,
                type: "warning"
              });
            }
          });
      });
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign(this.editForm, row);
    },
    //显示新增界面
    handleAdd: function () {
      this.addFormVisible = true;
      this.addForm = {
        username: "",
        mobilephone: "",
        usertypeid: null,
        customerid: this.customerid,
        password: ""
      };
    },
    //编辑
    editSubmit: function () {
      this.editForm.password = this.editForm.password.trim();
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            this.axios
              .post("/usermanagement/edituser", this.editForm)
              .then(res => {
                this.editLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$notify({
                    title: "成功",
                    message: "编辑成功",
                    type: "success"
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getUsers();
                } else {
                  this.$message({
                    message: `${res.data.message}`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            this.axios
              .post("/usermanagement/adduser", this.addForm)
              .then(res => {
                this.addLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getUsers();
                } else if (res.data.code === 103) {
                  this.$message({
                    message: "手机号码已被其他用户使用，请更换其他手机号码",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存出错，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>