<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="传感器号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getCloudSensorsInfo">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd" :disabled="roletype > 4">新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table
      :data="getcloudsensorslist"
      v-loading="listLoading"
      style="width: 100%"
      stripe
      highlight-current-row
    >
      <el-table-column type="index" min-width="30"></el-table-column>
      <el-table-column prop="sensorcode" label="传感器号" width="120" sortable>
        <template slot-scope="scope">
          <div class="haslink" @click="loadWechatDialog(scope.row)">{{ scope.row.sensorcode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="sn" label="SN" width="175" sortable></el-table-column>
      <el-table-column prop="isenable" label="状态" :formatter="formatEnable" width="70"></el-table-column>
      <el-table-column prop="devicename" label="所属设备" min-width="160"></el-table-column>
      <el-table-column prop="mintemperature" label="温度下限(℃)" min-width="120"></el-table-column>
      <el-table-column prop="maxtemperature" label="温度上限(℃)" min-width="120"></el-table-column>
      <el-table-column prop="minhumidity" label="湿度下限(%)" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.sensorkind == 0">
            {{
            scope.row.minhumidity
            }}
          </span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column prop="maxhumidity" label="湿度上限(%)" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.sensorkind == 0">
            {{
            scope.row.maxhumidity
            }}
          </span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column prop="warningmintemperature" label="预警温度下限(℃)" min-width="130"></el-table-column>
      <el-table-column prop="warningmaxtemperature" label="预警温度上限(℃)" min-width="130"></el-table-column>
      <el-table-column prop="warningminhumidity" label="预警湿度下限(%)" min-width="130">
        <template slot-scope="scope">
          <span v-if="scope.row.sensorkind == 0">
            {{
            scope.row.warningminhumidity
            }}
          </span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column prop="warningmaxhumidity" label="预警湿度上限(%)" min-width="130">
        <template slot-scope="scope">
          <span v-if="scope.row.sensorkind == 0">
            {{
            scope.row.warningmaxhumidity
            }}
          </span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column prop="uploadinterval" label="上传间隔(分)" min-width="145"></el-table-column>
      <el-table-column prop="datainterval" label="记录间隔(分)" min-width="145"></el-table-column>
      <el-table-column prop="overinterval" label="超标记录间隔(分)" min-width="175"></el-table-column>
      <el-table-column prop="createdat" :formatter="formatCreatedAt" label="安装日期" min-width="110"></el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button
            :disabled="roletype > 4"
            size="small"
            @click="handleEdit(scope.$index, scope.row)"
          >
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button
            :disabled="roletype > 4"
            type="warning"
            size="small"
            @click="handleDel(scope.$index, scope.row)"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24">
      <el-pagination
        layout="prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :page-size="20"
        :total="total"
        style="float: right"
      ></el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="160px" :rules="editFormRules" ref="editForm">
        <!--<el-form-item label="传感器id" prop="sensorid">
                        <el-input v-model="editForm.sensorid"></el-input>
        </el-form-item>-->
        <el-form-item label="传感器号" prop="sensorcode">
          <el-input-number v-model="editForm.sensorcode" :min="1" :max="1024"></el-input-number>
        </el-form-item>
        <el-form-item label="所属设备" prop="deviceid">
          <el-select v-model="editForm.deviceid" auto-complete="off">
            <el-option
              v-for="item in devicemodels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Serial No." prop="sn">
          <el-input v-model="editForm.sn">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">
                传感器唯一标识
                <br />可用于绑定微信报警功能
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item label="类型" prop="sensorkind">
          <el-radio-group v-model="editForm.sensorkind" @change="handleHumidityEdit">
            <el-radio :label="0">温湿度传感器</el-radio>
            <el-radio :label="1">温度传感器</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="温度上下限" required>
          <template>
            <el-input-number
              v-model.number="editForm.mintemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃ ~</span>
            <el-input-number
              v-model.number="editForm.maxtemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃</span>
          </template>
        </el-form-item>
        <el-form-item label="湿度上下限" required v-if="!editHumidityDisable">
          <template>
            <el-input-number v-model.number="editForm.minhumidity" :step="1" :min="0" :max="100"></el-input-number>
            <span>% ~</span>
            <el-input-number v-model.number="editForm.maxhumidity" :step="1" :min="0" :max="100"></el-input-number>
            <span>%</span>
          </template>
        </el-form-item>
        <el-form-item label="预警温度上下限" required>
          <template>
            <el-input-number
              v-model.number="editForm.warningmintemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃ ~</span>
            <el-input-number
              v-model.number="editForm.warningmaxtemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃</span>
          </template>
        </el-form-item>
        <el-form-item label="预警湿度上下限" required v-if="!editHumidityDisable">
          <template>
            <el-input-number
              v-model.number="editForm.warningminhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>% ~</span>
            <el-input-number
              v-model.number="editForm.warningmaxhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>%</span>
          </template>
        </el-form-item>
        <el-form-item label="启用" prop="isenable" required>
          <el-switch v-model="editForm.isenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
        </el-form-item>
        <el-form-item label="上传间隔" prop="uploadinterval" required>
          <el-input-number v-model.number="editForm.uploadinterval" :step="1" :min="1" :max="60"></el-input-number>
          <span>分钟</span>
        </el-form-item>
        <el-form-item label="记录间隔" prop="datainterval" required>
          <el-input-number v-model.number="editForm.datainterval" :step="1" :min="1" :max="120"></el-input-number>
          <span>分钟</span>
        </el-form-item>
        <el-form-item label="超标记录间隔" prop="datainterval" required>
          <el-input-number v-model.number="editForm.overinterval" :step="1" :min="1" :max="120"></el-input-number>
          <span>分钟</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" label-width="160px" :rules="addFormRules" ref="addForm">
        <el-form-item label="传感器号" required>
          <el-input-number v-model="addForm.sensorcodestart" :min="1" :max="1024"></el-input-number>-
          <el-input-number v-model="addForm.sensorcodeend" :min="1" :max="1024"></el-input-number>
        </el-form-item>
        <el-form-item label="所属设备" prop="deviceid">
          <el-select v-model="addForm.deviceid" auto-complete="off">
            <el-option
              v-for="item in devicemodels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Serial No." prop="sn">
          <el-input v-model="addForm.sn">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">
                传感器唯一标识
                <br />可用于绑定微信报警功能
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item label="类型" prop="sensorkind">
          <el-radio-group v-model="addForm.sensorkind" @change="handleHumidityAdd">
            <el-radio :label="0">温湿度传感器</el-radio>
            <el-radio :label="1">温度传感器</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="温度上下限" required>
          <template>
            <el-input-number
              v-model.number="addForm.mintemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃ ~</span>
            <el-input-number
              v-model.number="addForm.maxtemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃</span>
          </template>
        </el-form-item>
        <el-form-item label="湿度上下限" v-if="!addHumidityDisable" required>
          <template>
            <el-input-number v-model.number="addForm.minhumidity" :step="1" :min="0" :max="100"></el-input-number>
            <span>% ~</span>
            <el-input-number v-model.number="addForm.maxhumidity" :step="1" :min="0" :max="100"></el-input-number>
            <span>%</span>
          </template>
        </el-form-item>
        <el-form-item label="预警温度上下限" required>
          <template>
            <el-input-number
              v-model.number="addForm.warningmintemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃ ~</span>
            <el-input-number
              v-model.number="addForm.warningmaxtemperature"
              :step="1"
              :min="-250"
              :max="50"
            ></el-input-number>
            <span>℃</span>
          </template>
        </el-form-item>
        <el-form-item label="预警湿度上下限" v-if="!addHumidityDisable" required>
          <template>
            <el-input-number
              v-model.number="addForm.warningminhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>% ~</span>
            <el-input-number
              v-model.number="addForm.warningmaxhumidity"
              :step="1"
              :min="0"
              :max="100"
            ></el-input-number>
            <span>%</span>
          </template>
        </el-form-item>

        <el-form-item label="启用" prop="isenable" required>
          <el-switch v-model="addForm.isenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
        </el-form-item>
        <el-form-item label="上传间隔" prop="uploadinterval" required>
          <el-input-number v-model.number="addForm.uploadinterval" :step="1" :min="1" :max="60"></el-input-number>
          <span>分钟</span>
        </el-form-item>
        <el-form-item label="记录间隔" prop="datainterval" required>
          <el-input-number v-model.number="addForm.datainterval" :step="1" :min="1" :max="120"></el-input-number>
          <span>分钟</span>
        </el-form-item>
        <el-form-item label="超标记录间隔" prop="datainterval" required>
          <el-input-number v-model.number="addForm.overinterval" :step="1" :min="1" :max="120"></el-input-number>
          <span>分钟</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="微信报警管理" :visible.sync="wechatDialogVisible">
      <div v-loading="wechatDialogLoading">
        <div v-if="wechatUserList.length == 0">暂无人员绑定微信报警</div>
        <div v-else>
          <wechat-head
            v-for="item in wechatUserList"
            :key="item.unionid"
            :imagesrc="item.headimgurl"
            :openid="item.openid"
            :sensorid="item.sensorid"
            :nickname="item.nickname"
            @update="loadWechatDialog(item)"
          ></wechat-head>
        </div>
        <div class="weixin" slot="footer">
          <span>还没有绑定微信公众号？</span>
          <span style="color: #20a0ff; text-decoration: underline">现在就扫码</span>
          <img src="../../assets/qrcode_for_gh_8d11da1d3c1b_258.png" />
        </div>
      </div>
    </el-dialog>
  </section>
</template>
<style lang="scss" scoped>
.weixin {
  float: right;
  img {
    position: absolute;
    right: 0px;
    bottom: 30px;
    max-width: 200px;
    transform: scale(0);
    transform-origin: right bottom 2px;
    opacity: 1;
    border-radius: 0.25rem;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 999;
  }
}
.weixin:hover img {
  transform: scale(1);
  opacity: 1;
}
</style>
<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
import WechatHead from "@/components/wechathead";
export default {
  components: { WechatHead },
  data() {
    return {
      filters: {
        name: ""
      },
      getcloudsensorslist: [],
      devicemodels: [],
      total: 0,
      page: 1,
      listLoading: false,

      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editForm: {
        sensorcode: "",
        sensorid: "",
        deviceid: "",
        devicecode: "",
        sn: "",
        isenable: true,
        mintemperature: "",
        maxtemperature: "",
        minhumidity: "",
        maxhumidity: "",
        warningmintemperature: "",
        warningmaxtemperature: "",
        warningminhumidity: "",
        warningmaxhumidity: "",
        sensorkind: 0,
        uploadinterval: "",
        datainterval: "",
        overinterval: ""
      },
      editHumidityDisable: false,
      editFormRules: {
        deviceid: [
          {
            required: true,
            type: "number",
            message: "请选择所属设备",
            trigger: "blur"
          }
        ]
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,

      //新增界面数据
      addForm: {
        sensorcodestart: null,
        sensorcodeend: null,
        sensorcode: "",
        deviceid: "",
        devicecode: "",
        sn: "",
        isenable: true,
        mintemperature: "",
        maxtemperature: "",
        minhumidity: "",
        maxhumidity: "",
        warningmintemperature: "",
        warningmaxtemperature: "",
        warningminhumidity: "",
        warningmaxhumidity: "",
        sensorkind: 0,
        uploadinterval: "",
        datainterval: "",
        overinterval: ""
      },
      addHumidityDisable: false,
      addFormRules: {
        deviceid: [
          {
            required: true,
            type: "number",
            message: "请选择所属设备",
            trigger: "blur"
          }
        ]
      },
      wechatDialogVisible: false,
      wechatDialogLoading: false,
      wechatUserList: []
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    localdevicetypeModels: function() {
      // 0:超管，1:保留，2:代理商超管，3:保留，4:客户管理员，5:客户普通用户
      if (this.roletype < 4) {
        // 代理商 管理员登录
        return [
          {
            label: "云库房card",
            value: 2
          }
        ];
      } else {
        return [];
      }
    }
  },
  methods: {
    loadWechatDialog(sensor) {
      this.wechatDialogVisible = true;
      this.wechatDialogLoading = true;
      this.axios.post("/wechatapi/snuserrelation", sensor).then(res => {
        this.wechatUserList = res.data.values.list;
        this.wechatDialogLoading = false;
        ``;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCloudSensorsInfo();
    },
    formatCreatedAt: (row, column) => {
      return new Date(row.createdat).format("yyyy-mm-dd");
    },

    formatEnable: (row, column) => {
      return row.isenable == 1 ? "启用" : "停用";
    },

    //获取云传感器管理列表
    getCloudSensorsInfo() {
      let para = {
        customerid: this.customerid,
        name: this.filters.name,
        page: this.page
      };
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/cloudmanagement/getcloudsensorslist", para)
        .then(res => {
          this.listLoading = false;
          NProgress.done();
          if (res.data.code === 0) {
            this.getcloudsensorslist = res.data.values.sensorslist;
            this.total = res.data.values.count;
          } else {
            console.log(JSON.stringify(res.data.error));
            this.$message({
              message: `查询出错，错误码${res.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    },
    //删除
    handleDel: function(index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning"
      }).then(() => {
        this.listLoading = true;
        NProgress.start();
        let params = {
          sensorid: []
        };
        params.sensorid.push(row.sensorid);
        this.axios
          .post("/cloudmanagement/deletecloudsensor", params)
          .then(res => {
            this.listLoading = false;
            NProgress.done();
            if (res.data.code === 0) {
              this.$notify({
                title: "成功",
                offset: 45,
                message: "删除成功",
                type: "info"
              });
              this.getCloudSensorsInfo();
            } else {
              this.$message({
                message: `删除失败，错误码${res.data.code},请联系管理员`,
                type: "warning"
              });
            }
          });
      });
    },
    handleHumidityEdit() {
      if (this.editForm.sensorkind == 1) {
        this.editForm.minhumidity = -100;
        this.editForm.maxhumidity = -100;
        this.editForm.warningminhumidity = -100;
        this.editForm.warningmaxhumidity = -100;
        this.editHumidityDisable = true;
      } else {
        if (this.editForm.minhumidity == -100) {
          this.editForm.minhumidity = 0;
          this.editForm.warningminhumidity = 0;
        }
        if (this.editForm.maxhumidity == -100) {
          this.editForm.maxhumidity = 100;
          this.editForm.warningmaxhumidity = 100;
        }
        this.editHumidityDisable = false;
      }
    },

    //显示编辑界面
    handleEdit: function(index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign(this.editForm, row);
      this.editForm.isenable = this.editForm.isenable == 1 ? true : false;
      this.handleHumidityEdit();
    },
    //编辑
    editSubmit: function() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.editForm);
            this.devicemodels.map(d => {
              if (d.value == params.deviceid) params.devicecode = d.code;
            });
            params.isenable = params.isenable ? 1 : 0;
            if (this.editHumidityDisable) {
              params.minhumidity = -100;
              params.maxhumidity = -100;
              params.warningminhumidity = -100;
              params.warningmaxhumidity = -100;
            }
            params = this.rangeJudge(params);
            params = this.rangeJudge(params);
            this.axios
              .post("/cloudmanagement/editcloudsensor", params)
              .then(res => {
                this.editLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$notify({
                    title: "成功",
                    offset: 45,
                    message: "编辑成功",
                    type: "success"
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getCloudSensorsInfo();
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "您的权限不足",
                    type: "warning"
                  });
                } else if (res.data.code === 105) {
                  this.$message({
                    message: "传感器号重复",
                    type: "warning"
                  });
                } else if (res.data.code === 119) {
                  this.$message({
                    message: "传感器SN号重复",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存失败，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    },
    handleHumidityAdd() {
      if (this.addForm.sensorkind == 1) {
        this.addHumidityDisable = true;
      } else {
        this.addHumidityDisable = false;
      }
    },
    //显示新增界面
    handleAdd: function() {
      this.addFormVisible = true;
      this.addForm = {
        sensorcodestart: this.total + 1,
        sensorcodeend: this.total + 1,
        devicecode: "",
        deviceid: "",
        sn: "",
        customerid: "",
        isenable: true,
        mintemperature: 2,
        maxtemperature: 8,
        minhumidity: 35,
        maxhumidity: 75,
        warningmintemperature: 2,
        warningmaxtemperature: 8,
        warningminhumidity: 35,
        warningmaxhumidity: 75,
        uploadinterval: 1,
        sensorkind: 0,
        datainterval: 5,
        overinterval: 2
      };
    },
    rangeJudge(param) {
      if (param.mintemperature > param.maxtemperature) {
        [param.mintemperature, param.maxtemperature] = [
          param.maxtemperature,
          param.mintemperature
        ];
      }
      if (param.minhumidity > param.maxhumidity) {
        [param.minhumidity, param.maxhumidity] = [
          param.maxhumidity,
          param.minhumidity
        ];
      }
      if (param.warningmintemperature > param.warningmaxtemperature) {
        [param.warningmintemperature, param.warningmaxtemperature] = [
          param.warningmaxtemperature,
          param.warningmintemperature
        ];
      }
      if (param.warningminhumidity > param.warningmaxhumidity) {
        [param.warningminhumidity, param.warningmaxhumidity] = [
          param.warningmaxhumidity,
          param.warningminhumidity
        ];
      }
      if (param.warningmintemperature < param.mintemperature) {
        param.warningmintemperature = param.mintemperature;
      }
      if (param.warningmaxtemperature > param.maxtemperature) {
        param.warningmaxtemperature = param.maxtemperature;
      }
      if (param.warningminhumidity < param.minhumidity) {
        param.warningminhumidity = param.minhumidity;
      }
      if (param.warningmaxhumidity > param.maxhumidity) {
        param.warningmaxhumidity = param.maxhumidity;
      }
      return param;
    },
    //新增
    addSubmit: function() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            let params = Object.assign({}, this.addForm);
            params.sensorcode = Array(
              params.sensorcodeend - params.sensorcodestart + 1
            )
              .fill(0)
              .map((v, i) => i + params.sensorcodestart);
            this.devicemodels.map(d => {
              if (d.value == params.deviceid) params.devicecode = d.code;
            });
            params.isenable = params.isenable ? 1 : 0;
            if (this.addHumidityDisable) {
              params.minhumidity = -100;
              params.maxhumidity = -100;
              params.warningminhumidity = -100;
              params.warningmaxhumidity = -100;
            }
            params = this.rangeJudge(params);
            params = this.rangeJudge(params);
            this.axios
              .post("/cloudmanagement/addcloudsensor", params)
              .then(res => {
                this.addLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getCloudSensorsInfo();
                  this.$notify({
                    title: "成功",
                    offset: 45,
                    message: "新增成功",
                    type: "info"
                  });
                } else if (res.data.code === 102) {
                  this.$message({
                    message: "保存失败，权限不足,请联系管理员",
                    type: "warning"
                  });
                } else if (res.data.code === 105) {
                  this.$message({
                    message: "传感器号重复",
                    type: "warning"
                  });
                } else if (res.data.code === 119) {
                  this.$message({
                    message: "传感器SN号重复",
                    type: "warning"
                  });
                } else {
                  this.$message({
                    message: `保存出错，错误码${res.data.code},请联系管理员`,
                    type: "warning"
                  });
                }
              });
          });
        }
      });
    }
  },
  mounted() {
    this.getCloudSensorsInfo();
    let para = {
      customerid: this.customerid
    };
    this.axios.post("/cloudmanagement/getclouddevices", para).then(reply => {
      this.devicemodels = reply.data.values.devices;
    });
  }
};
</script>

<style scoped>
.haslink {
  text-decoration: underline;
  color: #4091ff;
  cursor: pointer;
}
</style>