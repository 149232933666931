<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true">
        <el-form-item label="设备列表">
          <el-select placeholder="请选择设备" v-model="search.deviceid">
            <el-option-group v-for="group in deviceList" :key="group.label" :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.deviceid"
                :label="item.devicename"
                :value="item.deviceid"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template>
            <span>起止时间</span>
            <el-date-picker
              v-model="search.daterange"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            ></el-date-picker>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="handleSearch" :disabled="searchUseable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      :data="resultList"
      v-loading="listLoading"
      style="width: 100%;"
      stripe
      highlight-current-row
    >
      <el-table-column type="index" width="54"></el-table-column>
      <el-table-column
        prop="operdate"
        :formatter="formatTime"
        label="日期"
        width="250"
        sortable
        min-width="150"
      ></el-table-column>
      <el-table-column prop="devicename" label="设备名称" width="250" min-width="200"></el-table-column>
      <el-table-column prop="flag" label="开关门动作标识" min-width="150"></el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :page-size="20"
        :total="total"
        :current-page="page"
        style="float:right;"
      ></el-pagination>
    </el-col>
  </section>
</template>
<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      total: 0,
      page: 1,
      deviceList: [],
      resultList: [],
      listLoading: false,
      search: {
        deviceid: null,
        daterange: null
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 365 *5 - 8.64e7; //最多五年前
        },
        shortcuts: [
          {
            text: "一小时前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    searchUseable: function() {
      if (this.search.deviceid == "" || this.search.daterange == null)
        return true;
      else return false;
    }
  },
  methods: {
    formatTime: (row, column) => {
      return new Date(row.operdate).format("mm月dd日 HH:MM");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchDoorHistory();
    },
    handleSearch() {
      this.page = 1;
      this.searchDoorHistory();
    },
    mountCloudDevices() {
      this.axios
        .post("/datacenter/getdeviceslist", { customerid: this.customerid })
        .then(res => {
          /** 查询结果以deviceid为key 去重 */
          let devicesMap = new Map();
          res.data.values.deviceslist.map(c => {
            if (c.devicetype > 2) {
              devicesMap.set(c.deviceid, c);
            }
          });
          let devices = [...devicesMap.values()];
          /** 查询结果以deviceid为key 去重 END */
          let newArray = [{ label: "STL冷链箱", options: [] }];
          devices.map(device => {
            if (device.devicetype == 6) {
              newArray[0].options.push(device);
            }
          });
          this.deviceList = newArray;
        });
    },
    searchDoorHistory() {
      let params = { customerid: this.customerid, page: this.page };
      params.deviceid = this.search.deviceid;
      params.starttime = this.search.daterange[0].format("yyyy-mm-dd HH:MM");
      params.endtime = this.search.daterange[1].format("yyyy-mm-dd HH:MM");
      this.axios.post("/datacenter/doorhistory", params).then(res => {
        if (res.data.values.count == 0) {
          return this.$message({
            type: "warning",
            message: "查询不到该时间段内的开关门数据"
          });
        } else {
          this.resultList = res.data.values.result;
          this.total = res.data.values.count;
        }
      });
    }
  },
  mounted() {
    this.mountCloudDevices();
  }
};
</script>
