<!--
 * @Date: 2021-06-20 14:23:20
 * @LastEditors: zhouhua
 * @LastEditTime: 2022-07-29 11:17:55
 * @FilePath: \coldcloud-vue-web\src\pages\cloudwarehouse\alarmtable.vue
-->
<template>
    <section>
        <el-col :span="24" class="toolbar" style="padding-bottom: 0px; position: relative;">
            <el-tabs v-model="tabSelected" class="el-col el-col-24">
                <el-tab-pane label="超标报警" name="first">
                    <OverAlarmTable :phoneList="phoneList" :formatSMSEnable="formatSMSEnable"
                        :formatWeChatEnable="formatWeChatEnable" :formatEnable="formatEnable"
                        :formatAppEnable="formatAppEnable" :formatMode="formatMode" :validateSMSPhone="validateSMSPhone"
                        :roletype="roletype" :customerid="customerid" />
                </el-tab-pane>
                <el-tab-pane label="故障报警" name="second">
                    <FaultAlarmTable :phoneList="phoneList" :formatSMSEnable="formatSMSEnable"
                        :formatWeChatEnable="formatWeChatEnable" :formatAppEnable="formatAppEnable"
                        :formatVMSEnable="formatVMSEnable" :validateSMSPhone="validateSMSPhone" :roletype="roletype"
                        :customerid="customerid" />
                </el-tab-pane>
                <el-tab-pane label="断电报警" name="third">
                    <BlackoutAlarmTable :phoneList="phoneList" :formatSMSEnable="formatSMSEnable"
                        :formatWeChatEnable="formatWeChatEnable" :formatAppEnable="formatAppEnable"
                        :formatVMSEnable="formatVMSEnable" :validateSMSPhone="validateSMSPhone" :roletype="roletype"
                        :customerid="customerid" />
                </el-tab-pane>
                <el-tab-pane label="低电量报警" name="four">
                    <BatteryAlarmTable :phoneList="phoneList" :formatSMSEnable="formatSMSEnable"
                        :formatWeChatEnable="formatWeChatEnable" :formatAppEnable="formatAppEnable"
                        :validateSMSPhone="validateSMSPhone" :roletype="roletype" :customerid="customerid" />
                </el-tab-pane>
                <el-tab-pane label="运输超时报警" name="five">
                    <DeliverAlarm :phoneList="phoneList" :roletype="roletype" :customerid="customerid"
                        :validateSMSPhone="validateSMSPhone" />
                </el-tab-pane>
            </el-tabs>
        </el-col>

        <router-link to="/alarmphone">
            <el-button type="text" class="phoneLink">
                报警号码管理
            </el-button>
        </router-link>
    </section>
</template>
<script>
import OverAlarmTable from './alarmTab/OverAlarm'
import BlackoutAlarmTable from './alarmTab/BlackoutAlarm'
import BatteryAlarmTable from './alarmTab/BatteryAlarm'
import DeliverAlarm from './alarmTab/DeliverAlarm'
import FaultAlarmTable from './alarmTab/FaultAlarm'
import { mapGetters } from 'vuex'
export default {
    components: { OverAlarmTable, BlackoutAlarmTable, BatteryAlarmTable, DeliverAlarm, FaultAlarmTable },
    data() {
        return {
            tabSelected: 'first',
            phoneList: [],
        }
    },
    computed: {
        ...mapGetters(['roletype', 'customerid']),
    },

    methods: {
        formatSMSEnable: (row) => {
            return row.issmsenable ? '启用' : '停用'
        },
        formatVMSEnable: (row) => {
            return row.isvmsenable ? '启用' : '停用'
        },
        formatWeChatEnable: (row) => {
            return row.iswechatenable ? '启用' : '停用'
        },
        formatAppEnable: (row) => {
            return row.isappenable ? '启用' : '停用'
        },
        formatEnable: (row, column) => {
            return row[column.property] ? '启用' : '停用'
        },
        formatMode: (row) => {
            return row.alarmmode ? '温度报警' : '温湿度报警'
        },
        validateSMSPhone(param) {
            //短信报警开启且短信报警手机号为空
            if (param.issmsenable) {
                let phone = param.phone ? param.phone : param.alarmphone
                if (phone.length == 0) {
                    return false
                }
            }
            return true
        },

        getUserList() {
            this.axios.post('/user/shortcutlist', { customerid: this.customerid }).then((res) => {
                if (res.data.code === 0) {
                    this.phoneList = res.data.values.shortcutlist
                } else {
                    this.$message({
                        message: `查询出错，错误码${res.data.code},请联系管理员`,
                        type: 'warning',
                    })
                }
            })
        },
    },
    mounted() {
        this.getUserList()
    },
}
</script>
<style>
.phoneLink {
    float: right;
    position: absolute;
    top: 100px;
    right: 52px;
}
</style>
