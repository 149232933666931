<template>
    <section>
        <el-row class="toolbar">
            <!--工具条-->
            <el-form :inline="true" :model="filters">
                <el-form-item prop="customerid" label="客户:">
                    <el-select v-model="filters.customerid" placeholder="选择客户" filterable clearable @change="getDevices">
                        <el-option v-for="item in customersOption" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="deviceid" label="设备:">
                    <el-select v-model="filters.deviceid" placeholder="选择冷链设备" filterable clearable @change="getSensors">
                        <el-option v-for="item in devicesOption" :key="item.value" :label="item.code + ' - ' + item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="时间段:">
                    <el-date-picker v-model="filters.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="queryData" icon="el-icon-search" circle type="primary"
                        :disabled="!queryEnable"></el-button>
                </el-form-item>
                <el-divider>查询可选项</el-divider>
                <el-form-item label="传感器:">
                    <el-select v-model="filters.sensors" placeholder="所有传感器" filterable clearable multiple>
                        <el-option v-for="item in sensorsOption" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="历史标识">
                    <el-select v-model="filters.intervalflag">
                        <el-tooltip class="item" effect="dark" placement="right">
                            <div slot="content">不筛选，查询所有数据。<br />历史标识（IsIntervalRecord）不作为查询条件。</div>
                            <el-option :value="2" label="默认"></el-option>
                        </el-tooltip>
                        <el-option :value="1" label="仅历史"></el-option>
                        <el-option :value="0" label="仅非历史"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="超标标识">
                    <el-select v-model="filters.overflag">
                        <el-tooltip class="item" effect="dark" placement="right">
                            <div slot="content">不筛选，查询所有数据。<br />超标标识（IsOver）不作为查询条件。</div>
                            <el-option :value="0" label="默认"></el-option>
                        </el-tooltip>
                        <el-option :value="1" label="仅超标"></el-option>
                        <el-option :value="2" label="仅不超标"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <el-divider></el-divider>
            <div style="display: flex;">
                <div style="flex: 1">
                    <el-button-group>
                        <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="selectionDelete"
                            @click="handleSelectionDelete"></el-button>
                        <el-button type="primary" icon="el-icon-finished" size="mini" @click="onChangeSubmit"
                            :disabled="!saveEnable"></el-button>
                    </el-button-group>
                </div>
                <el-pagination style="flex: 2" :current-page.sync="page" :total="total"
                    :page-sizes="[10, 20, 30, 40, 50, 100, 500]" :page-size="limit" layout="sizes, prev, pager, next"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
            </div>
            <el-table ref="dataTableRef" :data="tableData" border highlight-current-row style="width: 100%"
                @current-change="rowSelectChange" @row-contextmenu="rowContent" @selection-change="handleSelectionChange"
                v-loading="tableLoading">
                <el-table-column type="selection" width="55" />
                <el-table-column prop="RecordId" label="记录ID" min-width="90" />
                <el-table-column prop="OperDate" label="记录时间" width="190">
                    <template #default="{ row }">
                        <span class="show-span">{{ row.OperDate }}</span>
                        <span class="edit-span">
                            <el-input v-model="row.OperDate" @keyup.enter.native="onChangeSubmit"></el-input>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="WarehouseName" label="库房名" min-width="180" />
                <el-table-column prop="SensorCode" label="传感器号" width="80" />
                <el-table-column prop="Temperature" label="温度" width="90">
                    <template #default="{ row }">
                        <span class="show-span">{{ row.Temperature }}{{ (row.IsOver & 0b0010) > 0 ? " ↑" : ""
                        }}{{ (row.IsOver & 0b0001) > 0 ? " ↓" : "" }}</span>
                        <span class="edit-span">
                            <el-input v-model="row.Temperature" @keyup.enter.native="onChangeSubmit"></el-input>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="Humidity" label="湿度" width="90">
                    <template #default="{ row }">
                        <span class="show-span">{{ row.Humidity }}{{ (row.IsOver & 0b1000) > 0 ? " ↑" : ""
                        }}{{ (row.IsOver & 0b0100) > 0 ? " ↓" : "" }}</span>
                        <span class="edit-span">
                            <el-input v-model="row.Humidity" @keyup.enter.native="onChangeSubmit"></el-input>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="温度上下限" width="160">
                    <template #default="scope">
                        <div>
                            {{ scope.row.MinTemperature }}℃ - {{ scope.row.MaxTemperature }}℃
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="湿度上下限" width="160">
                    <template #default="scope">
                        <div>
                            {{ scope.row.MinHumidity }}% - {{ scope.row.MaxHumidity }}%
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="IsIntervalRecord" label="Interval" width="80">
                    <template #default="{ row }">
                        <span class="show-span">{{ row.IsIntervalRecord }}</span>
                        <span class="edit-span">
                            <el-switch v-model="row.IsIntervalRecord"></el-switch>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="Remarks" label="备注" width="100"> </el-table-column>
                <el-table-column prop="IsDelete" label="软删除" width="120">
                    <template #default="{ row }">
                        <span class="show-span">{{ row.IsDelete }}</span>
                        <span class="edit-span">
                            <el-switch v-model="row.IsDelete" active-color="#ff2200"></el-switch>
                        </span>
                    </template>
                </el-table-column>

            </el-table>
            <el-divider direction="vertical"></el-divider>
        </el-row>
    </section>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            customersOption: [],
            devicesOption: [],
            sensorsOption: [],
            filters: {
                customerid: "",
                deviceid: "",
                sensors: [],
                daterange: null,
                intervalflag: 2,
                overflag: 0,
                deleteflag: 0,
                harddelete: false
            },
            saveEnable: false,
            page: 1,
            limit: 50,
            total: 0,
            tableData: [],
            originalData: [],
            currentRow: null,
            selectionRows: [],
            tableLoading: false
        }
    },
    watch: {
        currentRow: {
            handler: function (newVal) {
                const origins = this.originalData.filter(r => r.RecordId == newVal?.RecordId);
                if (origins.length > 0) {
                    if (origins[0].OperDate != newVal.OperDate
                        || origins[0].Temperature != newVal.Temperature
                        || origins[0].Humidity != newVal.Humidity
                        || origins[0].IsIntervalRecord != newVal.IsIntervalRecord
                        || origins[0].IsDelete != newVal.IsDelete
                        || origins[0].IsOver != newVal.IsOver) {
                        this.saveEnable = true;
                        return;
                    }
                }
                this.saveEnable = false;
            },
            deep: true
        }
    },
    computed: {
        queryEnable() {
            return this.filters.deviceid != "" && this.filters.daterange?.length == 2;
        },
        selectionDelete() {
            if (this.selectionRows.length == 0) {
                return true;
            } else {
                return false;
            }
        },

    },
    methods: {
        OverJudge(t, h, t_l, t_h, h_l, h_h) {
            let result = 0;
            if (t < t_l) {
                result |= 1;
            }
            if (t > t_h) {
                result |= 2;
            }
            if (h < h_l) {
                result |= 4;
            }
            if (h > h_h) {
                result |= 8;
            }
            return result;
        },
        handleSelectionDelete() {
            const params = {
                customerid: this.filters.customerid,
                harddelete: this.filters.harddelete
            };
            params.ids = this.selectionRows.map(r => r.RecordId);
            this.axios.post("/admin/coldchain/deletedata", params).then(res => {
                if (res.data.values) {
                    this.$notify({
                        title: "Success",
                        offset: 75,
                        message: "删除成功",
                        duration: 2000,
                    });
                }
                this.currentRow = null;
                this.queryData();
            })
        },
        onChangeSubmit() {
            if (this.currentRow != null && this.saveEnable == true) {
                let params = {
                    customerid: this.filters.customerid,
                    recordid: this.currentRow.RecordId,
                    operdate: this.currentRow.OperDate,
                    temperature: Number.parseFloat(this.currentRow.Temperature),
                    humidity: Number.parseFloat(this.currentRow.Humidity),
                    isintervalrecord: this.currentRow.IsIntervalRecord,
                    isdelete: this.currentRow.IsDelete
                };
                params.isover = this.OverJudge(
                    this.currentRow.Temperature,
                    this.currentRow.Humidity,
                    this.currentRow.MinTemperature,
                    this.currentRow.MaxTemperature,
                    this.currentRow.MinHumidity,
                    this.currentRow.MaxHumidity
                );
                params.remarks = params.isover > 0 ? '数据超标' : '';

                this.axios.post("/admin/coldchain/updatedata", params).then(res => {
                    if (res.data.values) {
                        this.$notify({
                            title: "Success",
                            offset: 75,
                            message: "修改成功",
                            duration: 2000,
                        });
                    }
                    this.currentRow = null;
                    this.queryData();
                })
            }
            this.$refs.dataTableRef.setCurrentRow(null);
        },
        handleSelectionChange(row) {
            this.selectionRows = row;
        },
        rowContent(row, content, event) {
            event.preventDefault();
        },
        rowSelectChange(currentRow, oldRow) {
            this.currentRow = currentRow;
        },
        handleSizeChange(size) {
            // 每页显示数量由少变多时，可能会引起翻页问题，强制重置页码
            if (this.limit < size) {
                this.page = 1
            }
            this.limit = size
            this.queryData()
        },
        handleCurrentChange(page) {
            this.page = page;
            this.queryData()
        },
        queryData() {
            let customerid = this.filters.customerid;
            let deviceid = this.filters.deviceid;
            let fromdate = moment(this.filters.daterange[0]).format("YYYY-MM-DD HH:mm:ss");
            let todate = moment(this.filters.daterange[1]).format("YYYY-MM-DD HH:mm:ss");

            let sensors = this.filters.sensors;
            if (sensors.length == 0) {
                sensors = this.sensorsOption.map(s => s.value)
            }
            if (sensors.length == 0) {
                this.page = 1
                this.tableData = []
                return
            }
            let intervalflag = this.filters.intervalflag
            let overflag = this.filters.overflag
            let deleteflag = this.filters.deleteflag
            let page = this.page;
            let limit = this.limit;
            const params = {
                customerid, deviceid, fromdate, todate, sensors, intervalflag, overflag, deleteflag, page, limit
            }
            this.tableLoading = true
            this.axios.post("/admin/coldchain/datalist", params).then(res => {
                const { list: { rows, count } } = res.data.values;
                this.tableData = rows.map(r => {
                    r.OperDate = r.OperDate.substr(0, 16);
                    r.IsDelete = !!r.IsDelete;
                    r.IsIntervalRecord = !!r.IsIntervalRecord;
                    return r
                });
                this.originalData = JSON.parse(JSON.stringify(this.tableData));
                this.total = count;
                if (this.total == 0) {
                    this.$message({
                        title: "warning",
                        message: "该时段查询不到有效数据，请修改查询条件后重试",
                        offset: 75,
                        duration: 2000,
                    })
                }
                this.tableLoading = false
            }).catch(() => {
                this.$message({
                    title: "warning",
                    message: "网络故障，请稍候重试",
                    offset: 75,
                    duration: 2000,
                })
                this.tableLoading = false
            })
        },
        getCustomers() {
            this.axios.post("/user/getcustomerslist").then(reply => {
                this.customersOption = reply.data.values.filter(r => !r.disabled);
            });
        },
        getDevices() {
            this.devicesOption = [];
            this.filters.deviceid = "";
            if (this.filters.customerid == "") {
                this.getSensors();
                return
            }
            this.axios.post('/cloudmanagement/getclouddevices', { customerid: this.filters.customerid }).then(
                res => {
                    this.devicesOption = res.data.values.devices;
                }
            )
        },
        getSensors() {
            this.sensorsOption = [];
            this.filters.sensors = [];
            if (this.filters.deviceid == "") {
                return
            }
            this.axios.post('/datacenter/getsensorslist', {
                searchtype: 1,  // required: 按设备id查询
                deviceid: this.filters.deviceid,
            }).then(
                res => {
                    res.data.values.sensorslist.map((sensor) => {
                        this.sensorsOption.push({
                            value: sensor.sensorid,
                            label: `${sensor.sensorcode}#`,
                        });
                    });
                }
            )
        }
    },
    mounted() {
        this.getCustomers()
    }
}
</script>
<style lang="scss" scoped>
.el-table {

    // 默认情况下，即没有选中某一行时，只显示span
    show-span {
        display: block;
    }

    .edit-span {
        display: none;
    }

    // 当点击某一行时，对应的行会添加current-row类，则内部的新的样式会生效
    .current-row {
        .show-span {
            display: none;
        }

        .edit-span {
            display: block;
        }
    }
}
</style>