<template>
  <div class="case-content">
    <div class="case-item">
      <div class="ih-item circle effect1">
        <div class="spinner"></div>
        <div class="img">
          <img :src="imagesrc" :alt="nickname" />
        </div>
        <div class="info">
          <div class="info-back">
            <h3>{{nickname}}</h3>
            <p>
              <el-button size="mini" type="warning" @click="unBindSensor(sensorid,openid)">删除</el-button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.case-content {
  display: inline-block;
  overflow: hidden;
  margin: 10px 0 10px 0;
}
.case-item {
  float: left;
  margin: 10px 10px;
  margin-bottom: 20px;
}
.ih-item * {
  position: relative;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ih-item img {
  width: 100%;
  height: 100%;
}
.ih-item.circle,
.ih-item.circle .img {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.ih-item.circle .img:before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6),
    0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.circle .img img {
  border-radius: 50%;
}
.ih-item.circle .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.ih-item.square {
  position: relative;
  width: 316px;
  height: 216px;
  border: 8px solid #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.ih-item.square .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.ih-item.circle.effect1 .spinner {
  width: 105px;
  height: 105px;
  border: 5px solid #ecab18;
  border-right-color: #1ad280;
  border-bottom-color: #1ad280;
  border-radius: 50%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.ih-item.circle.effect1 .img {
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 5px;
  right: 0;
  width: auto;
  height: auto;
}
.ih-item.circle.effect1 .img:before {
  display: none;
}
.ih-item.circle.effect1.colored .info {
  background: #1a4a72;
  background: rgba(26, 74, 114, 0.6);
}
.ih-item.circle.effect1 .info {
  top: 5px;
  bottom: 0;
  left: 5px;
  right: 0;
  background: #333;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.ih-item.circle.effect1 .info h3 {
  color: #fff;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  font-size: 14px;
  margin: 0 20px;
  padding: 15px 0 0;
  height: 40px;
  text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
}
.ih-item.circle.effect1 .info p {
  padding: 10px 0 0 0;
  margin: 0 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.ih-item.circle.effect1:hover .spinner {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.ih-item.circle.effect1:hover img {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.ih-item.circle.effect1:hover .info {
  opacity: 1;
}
</style>
<script>
export default {
  name: "WechatHead",

  props: {
    imagesrc: {
      type: String,
      default: "",
    },
    sensorid: {
      type: Number,
      default: null,
    },
    openid: {
      type: String,
      default: "",
    },
    nickname: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  watch: {},

  computed: {
    style() {
      let style = {};
      if (this.width) {
        style.width = this.width;
      }
      if (!this.fullscreen) {
        style.marginTop = this.top;
      }
      return style;
    },
  },

  methods: {
    unBindSensor(sensorid, openid) {
      this.$confirm(
        `解除绑定后，该微信用户将收不到传感器的报警通知推送。`,
        "确认解除吗？"
      )
        .then(() => {
          this.axios
            .post("/wechatapi/deleterelation", { sensorid, openid })
            .then((res) => {
              this.$emit("update");
            });
        })
        .catch((e) => {
          return;
        });
    },
  },

  mounted() {},

  destroyed() {},
};
</script>