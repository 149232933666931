<template>
  <el-select v-model="selectedVal" @change="changeEvent" placeholder="请选择客户" filterable>
    <el-option v-for="item in customerModels" :label="item.label" :key="item.value" :disabled="item.disabled" :value="item.value"></el-option>
  </el-select>
</template>
<script>
export default {
  name: "customer-select",
  props: {
    selected: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {
      customerModels: [],
      selectedVal: this.selected,
      selectedName: ""
    };
  },
  methods: {
    changeEvent: function() {
      this.customerModels.forEach(element => {
        if (element.value === this.selectedVal)
          this.selectedName = element.label;
      });
      this.$emit("changeVal", this.selectedVal, this.selectedName);
    },
    firstSelect: function() {
      this.axios.post("/user/getcustomerslist").then(reply => {
        this.customerModels = reply.data.values;
        if (this.customerModels.length > 0) {
          this.selectedVal = this.customerModels[0].value;
          this.selectedName = this.customerModels[0].label;
        }
      });
    }
  },
  mounted() {
    this.axios.post("/user/getcustomerslist").then(reply => {
      this.customerModels = reply.data.values;
    });
  }
};
</script>