<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-tabs v-model="tabSelected" class="el-col el-col-24" @tab-click="handleTabClick">
        <el-tab-pane label="本地设备" name="first"
          v-if="usercustomerid != 76 || usercustomerid != 289 || usercustomerid != 381">
          <el-form :inline="true" :rules="searchRules">
            <el-form-item>
              <div>
                <span>选择设备或仓库</span>
                <el-cascader :options="localSelectedOptions" v-model="search.getdeviceslist" :show-all-levels="false"
                  placeholder="关键字搜索" @change="getSensorsList" clearable change-on-select filterable></el-cascader>
              </div>
            </el-form-item>
            <el-form-item>
              <span>传感器号</span>
              <el-select v-model="search.sensorsOptions" placeholder="所有传感器" clearable @change="SensorChange">
                <el-option v-for="item in sensorslist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template>
                <span>起止时间</span>
                <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间"></el-date-picker>
              </template>
            </el-form-item>
            <el-form-item>
              <span>合并报警数据</span>
              <el-switch v-model="search.alarmflag"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
            </el-form-item>
            <el-form-item v-show="!exportUseable">
              <el-select v-model="exportType" style="width:45%">
                <el-option v-for="item in exportOptions" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
              <el-button @click="exportHistory" :loading="exporting">导出</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="云端设备" name="second">
          <el-form :inline="true" :rules="searchRules">
            <el-form-item>
              <div>
                <span>选择仓库</span>
                <el-cascader :options="cloudSelectedOptions" v-model="search.getdeviceslist" :show-all-levels="false"
                  placeholder="关键字搜索" @change="getSensorsList" clearable filterable></el-cascader>
              </div>
            </el-form-item>
            <el-form-item>
              <span>传感器号</span>
              <el-select v-model="search.sensorsOptions" placeholder="所有传感器" clearable @change="SensorChange">
                <el-option v-for="item in sensorslist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template>
                <span>起止时间</span>
                <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间"></el-date-picker>
              </template>
            </el-form-item>
            <el-form-item>
              <span>合并报警数据</span>
              <el-switch v-model="search.alarmflag"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
            </el-form-item>
            <el-form-item v-show="!exportUseable">
              <el-select v-model="exportType" style="width:35%">
                <el-option v-for="item in exportOptions" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
              <el-button @click="exportHistory" :loading="exporting">导出</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-on:click="$router.push('/datacenter/cardhistory')" type="text">新card</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-col>
    <!--列表-->
    <el-table :data="resultList" v-loading="listLoading" style="width: 100%;" stripe highlight-current-row>
      <el-table-column type="index" width="54"></el-table-column>
      <el-table-column prop="operdate" :formatter="formatTime" label="采集时间" width="140" sortable></el-table-column>
      <el-table-column prop="sensorcode" label="传感器号" width="130" min-width="130" sortable></el-table-column>
      <el-table-column prop="warehousename" label="库房" width="150"></el-table-column>
      <el-table-column label="温度(℃)" width="100" min-width="100">
        <template slot-scope="scope">
          <span style="color:red" v-if="scope.row.isover & 0b0010">{{ Number(scope.row.temperature).toFixed(2) }} ↑</span>
          <span style="color:red" v-else-if="scope.row.isover & 0b0001">{{ Number(scope.row.temperature).toFixed(2) }}
            ↓</span>
          <span v-else>{{ Number(scope.row.temperature).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="humidity" label="湿度(%)" width="100" min-width="100">
        <template slot-scope="scope">
          <span style="color:red" v-if="scope.row.isover & 0b1000">{{ Number(scope.row.humidity).toFixed(2) }} ↑</span>
          <span style="color:red" v-else-if="scope.row.isover & 0b0100">{{ Number(scope.row.humidity).toFixed(2) }}
            ↓</span>
          <span v-else>{{ scope.row.humidity == -100 ? "---" : Number(scope.row.humidity).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="tabSelected !== 'first' && search.getdeviceslist[0] != 6" prop="vcc" label="电压"
        min-width="90">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.vcc >= 3">{{ scope.row.vcc }} V</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.vcc }} V</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="tabSelected !== 'first' && search.getdeviceslist[0] == 6" prop="vcc" label="电量"
        min-width="90">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.vcc * 100 >= 60">{{ parseFloat(scope.row.vcc * 100).toFixed() }}
            %</el-tag>
          <el-tag type="warning" v-else>{{ parseFloat(scope.row.vcc * 100).toFixed() }} %</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="tabSelected !== 'first'" prop="dbm" label="信号" min-width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.dbm }} dBm</span>
        </template>
      </el-table-column>
      <el-table-column prop="mintemperature" label="温度下限" width="105" min-width="105"></el-table-column>
      <el-table-column prop="maxtemperature" label="温度上限" width="105" min-width="105"></el-table-column>
      <el-table-column prop="minhumidity" label="湿度下限" width="105" min-width="105">
        <template slot-scope="scope">
          <span v-if="scope.row.minhumidity == -100">---</span>
          <span v-else>{{ scope.row.minhumidity }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="maxhumidity" label="湿度上限" width="105" min-width="105">
        <template slot-scope="scope">
          <span v-if="scope.row.maxhumidity == -100">---</span>
          <span v-else>{{ scope.row.maxhumidity }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="150"></el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <span>统计：温度：最大值{{ summary.maxt }}℃，最小值{{ summary.mint }}℃，平均值{{ summary.avgt }}℃;湿度：最大值{{ summary.maxh }}%，最小值{{
        summary.minh }}%，平均值{{ summary.avgh }}%</span>
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20"
        :total="total" :current-page="page" style="float:right;"></el-pagination>
    </el-col>

    <el-dialog title="您当前有尚未下载的报告：" :visible.sync="hasUnhandleReports" width="40%" :before-close="handleClose">
      <div v-for="report of unhandleReports" :key="report.id" class="report-table">
        <span>{{ report.fileName }}</span>
        <span>{{ report.updatedAt }}</span>
        <el-button :disabled="report.state !== 'Completed'" type="success" icon="el-icon-download" size="mini"
          @click="handleDownload(report.id, report.fileName)"></el-button>
        <el-button type="info" icon="el-icon-delete" size="mini" @click="handleCancelDownload(report.id)"></el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hasUnhandleReports = false">关 闭</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      exportType: "PDF",
      hasUnhandleReports: false,
      unhandleReports: [],
      exporting: false,
      tabSelected: "first",
      localSelectedOptions: [],
      cloudSelectedOptions: [
        {
          value: 2,
          label: "云库房",
          children: [],
        },
        {
          value: 3,
          label: "保温箱",
          children: [],
        },
        {
          value: 4,
          label: "冷藏车",
          children: [],
        },
        {
          value: 6,
          label: "STL冷链箱",
          children: [],
        },
      ],
      sensorslist: [],
      search: {
        getdeviceslist: [],
        sensorsOptions: null,
        daterange: null,
        alarmflag: true,
      },
      summary: {
        maxt: "--",
        mint: "--",
        avgt: "--",
        maxh: "--",
        minh: "--",
        avgh: "--",
      },
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 3600 * 1000 * 24 * 365 * 5 - 8.64e7
          ); //最多五年前
        },
        shortcuts: [
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      total: 0,
      page: 1,
      listLoading: false,
      searchRules: {},
      resultList: [],
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid", 'usercustomerid']),
    searchUseable: function () {
      if (this.search.getdeviceslist == "" || this.search.daterange == null)
        return true;
      else return false;
    },
    exportUseable: function () {
      if (this.search.daterange == null || this.search.getdeviceslist == "")
        return true;
      else return false;
    },
    exportOptions: function () {
      if (this.roletype > 4) {
        return ['PDF']
      }
      else {
        return ['EXCEL', 'PDF', 'CSV', 'REPORT']
      }
    }
  },
  methods: {
    SensorChange(selected) {
      console.log(selected);
    },
    formatTime: (row, column) => {
      return new Date(row.operdate).format("mm月dd日 HH:MM:ss");
    },
    formatTemper: (row, column) => {
      if (row.temperature < row.mintemperature) return row.temperature + " ↓";
      if (row.temperature > row.maxtemperature) return row.temperature + " ↑";
      return row.temperature;
    },
    formatHumi: (row, column) => {
      if (row.humidity < row.minhumidity) return row.humidity + " ↓";
      if (row.humidity > row.maxhumidity) return row.humidity + " ↑";
      return row.humidity;
    },
    handleTabClick() {
      this.search.getdeviceslist = [];
      this.search.sensorsOptions = null;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchHistoryList();
    },
    resetPageAndSearch() {
      this.page = 1;
      this.searchHistoryList();
    },
    //获取设备列表
    getDevicesList() {
      let para = {
        customerid: this.customerid,
      };
      NProgress.start();
      this.axios.post("/datacenter/getdeviceslist", para).then((res) => {
        NProgress.done();
        if (res.data.code === 0) {
          res.data.values.deviceslist.map((device) => {
            if (device.devicetype == 1) {
              //本地库房
              let matched = 0;
              this.localSelectedOptions.map((opt) => {
                if (opt.value == device.deviceid) {
                  matched = 1;
                  opt.children.push({
                    value: device.warehouseid,
                    label: device.warehousename,
                  });
                }
                return opt;
              });
              if (matched == 0) {
                this.localSelectedOptions.push({
                  value: device.deviceid,
                  label: device.devicename,
                  children: [
                    {
                      value: device.warehouseid,
                      label: device.warehousename,
                    },
                  ],
                });
              }
            } else {
              this.cloudSelectedOptions.map((opt) => {
                if (opt.value == device.devicetype) {
                  opt.children.push({
                    value: device.warehouseid,
                    label: device.warehousename,
                  });
                }
                return opt;
              });
            }
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning",
          });
        }
      });
    },
    getSensorsList() {
      let para = {};
      let len = this.search.getdeviceslist.length;
      if (len == 2) {
        para = {
          searchtype: len,
          warehouseid: this.search.getdeviceslist[1],
        };
      } else if (len == 1) {
        para = {
          searchtype: len,
          deviceid: this.search.getdeviceslist[0],
        };
      } else {
        return this.$message({
          message: `请选择仓库`,
          type: "warning",
        });
      }

      NProgress.start();
      this.axios.post("/datacenter/getsensorslist", para).then((res) => {
        NProgress.done();
        if (res.data.code === 0) {
          this.sensorslist = [];
          this.search.sensorsOptions = null;
          res.data.values.sensorslist.map((sensor) => {
            this.sensorslist.push({
              value: sensor.sensorid,
              label: `${sensor.sensorcode}#`,
            });
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning",
          });
        }
      });
    },
    exportHistory() {
      let type = this.tabSelected == "first" ? 1 : 2; //用于区分当前查询的类型是本地还是云端
      let customerid = this.customerid;
      let deviceid = this.search.getdeviceslist[0];
      let searchtype = this.search.getdeviceslist.length;
      let warehouseid = searchtype == 2 ? this.search.getdeviceslist[1] : null;
      let fromdate = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let todate = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let sensorid = this.search.sensorsOptions || null;
      let isintervalrecord = this.search.alarmflag ? 1 : 0;
      let page = this.page;
      let params = {
        type: this.exportType,
        searchtype,
        customerid,
        fromdate,
        todate,
        deviceid,
        warehouseid,
        isintervalrecord,
        sensorid,
        page,
      };
      NProgress.start();
      this.exporting = true;
      this.axios.post("/datacenter/getexporthistory", params).then(async (res) => {
        NProgress.done();
        this.exporting = false;
        if (res.data.code == 0) {
          if (this.exportType !== 'REPORT') {
            window.open(`/export/${res.data.values.filename}`);
          } else {
            await this.checkReportGenerateState();
          }
        } else {
          this.$message({
            message: res.data.message,
            type: "warning",
          });
        }
      });
    },
    checkReportGenerateState() {
      this.axios.get("/datacenter/report/status").then((res) => {
        const { values } = res.data
        this.unhandleReports = values;
        if (values.length > 0) {
          this.hasUnhandleReports = true;
          if (values.some(report => report.state !== 'Completed')) {
            const that = this
            setTimeout(async () => {
              await that.checkReportGenerateState()
            }, 3000)
          }
        }
        else {
          this.hasUnhandleReports = false;
        }
      })
    },
    handleDownload(reportId, fileName) {
      this.axios.post("/datacenter/report/status", { id: reportId })
      this.checkReportGenerateState()
      window.open(`/report/${fileName}`);
    },
    handleCancelDownload(reportId) {
      this.axios.post("/datacenter/report/status", { id: reportId })
      this.checkReportGenerateState()
    },
    handleClose() {
      this.hasUnhandleReports = false
    },
    searchHistoryList() {
      let type = this.tabSelected == "first" ? 1 : 2; //用于区分当前查询的类型是本地还是云端
      let customerid = this.customerid;
      let deviceid = this.search.getdeviceslist[0];
      let searchtype = this.search.getdeviceslist.length;
      let warehouseid = searchtype == 2 ? this.search.getdeviceslist[1] : null;
      let fromdate = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let todate = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let sensorid = this.search.sensorsOptions || null;
      let isintervalrecord = this.search.alarmflag ? 1 : 0;
      let page = this.page;
      let params = {
        type,
        searchtype,
        customerid,
        fromdate,
        todate,
        deviceid,
        warehouseid,
        isintervalrecord,
        sensorid,
        page,
      };
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/datacenter/gethistorytable", params)
        .then((res) => {
          this.listLoading = false;
          NProgress.done();
          if (res.data.code === 0) {
            this.resultList = res.data.values.data;
            this.total = res.data.values.summary.count;
            if (this.total > 0) {
              this.summary = Object.assign({}, res.data.values.summary);
            }
          } else {
            this.$message({
              message: `查询出错，错误码${res.data.code},请联系管理员`,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$router.push("/login");
        });
    },
  },
  mounted() {
    this.getDevicesList();
    if (this.usercustomerid != 76 || this.usercustomerid != 289 || this.usercustomerid != 381) {
      this.tabSelected = 'first'
    } else {
      this.tabSelected = 'second'
    }
    this.checkReportGenerateState()
  },
};
</script>
<style scoped>
.report-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.report-table span {
  flex: 1;
}

.report-table button {
  margin-left: 10px;
}
</style> 