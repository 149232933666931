<template>
    <section>
        <!--工具条-->
        <!-- <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
            <el-form :inline="true" :model="filters">
                <el-form-item>
                    <el-input v-model="filters.name" placeholder="传感器号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="getCloudSensorsInfo">查询</el-button>
                </el-form-item>

            </el-form>
        </el-col> -->

        <!--列表-->
        <el-table :data="getcloudsensorslist" v-loading="listLoading" style="width: 100%" stripe highlight-current-row>
            <el-table-column type="index" min-width="30"></el-table-column>
            <el-table-column prop="sensorcode" label="传感器号" width="120" sortable>
            </el-table-column>
            <el-table-column prop="devicename" label="所属设备" min-width="160"></el-table-column>
            <el-table-column label="温度上下限(℃)" min-width="120">
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.mintemperature }} - {{ scope.row.maxtemperature }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="温度偏差公式" min-width="260">
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.formula?.temperature }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="湿度上下限(%)" min-width="120">
                <template slot-scope="scope">
                    <div v-if="scope.row.sensorkind == 0" style="overflow: hidden; word-wrap:normal;">
                        {{ scope.row.minhumidity }} - {{ scope.row.maxhumidity }}
                    </div>
                    <span v-else>
                        -----
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="温度偏差公式" min-width="260">
                <template slot-scope="scope">
                    <span v-if="scope.row.sensorkind == 0">
                        {{ scope.row.formula?.humidity }}
                    </span>
                    <span v-else>
                        -----
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="150">
                <template slot-scope="scope">
                    <el-button :disabled="roletype > 4" size="small" @click="handleEdit(scope.$index, scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button v-if="scope.row.formula?.temperature || scope.row.formula?.humidity"
                        @click="handleDelete(scope.row)" size="small" type="warning"> <i
                            class="el-icon-delete"></i></el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24">
            <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20"
                :total="total" style="float: right"></el-pagination>
        </el-col>
        <formula-form ref="formulaFormModal" @reload="getCloudSensorsInfo"></formula-form>

    </section>
</template>
<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
import formulaForm from "./components/formulaForm.vue";
export default {
    components: { formulaForm },
    data() {
        return {
            filters: {
                name: ""
            },
            getcloudsensorslist: [],
            devicemodels: [],
            total: 0,
            page: 1,
            listLoading: false,
            editModalVisible: false,
            editHumidityDisable: false,
            addHumidityDisable: false,
        };
    },
    computed: {
        ...mapGetters(["roletype", "customerid"]),
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
            this.getCloudSensorsInfo();
        },
        //获取云传感器管理列表
        getCloudSensorsInfo() {
            let para = {
                customerid: this.customerid,
                name: this.filters.name,
                page: this.page
            };
            this.listLoading = true;
            NProgress.start();
            this.axios
                .post("/cloudmanagement/sensorextendlist", para)
                .then(res => {
                    this.listLoading = false;
                    NProgress.done();
                    if (res.data.code === 0) {
                        this.getcloudsensorslist = res.data.values.sensorslist;
                        this.total = res.data.values.count;
                    } else {
                        this.$message({
                            message: `查询出错，错误码${res.data.code},请联系管理员`,
                            type: "warning"
                        });
                    }
                });
        },
        handleEdit(id, row) {
            this.$refs['formulaFormModal'].show(row)
        },
        handleDelete(row) {
            this.$confirm('确认删除偏差公式吗?', '提示', {
                type: 'warning',
            }).then(() => {
                NProgress.start()
                this.axios.post("/cloudmanagement/deletesensorextend", { sensorid: row.sensorid }).then(reply => {
                    this.$notify({
                        offset: 45,
                        title: "成功",
                        message: "删除公式成功",
                        type: "success"
                    });
                    NProgress.done()
                    this.getCloudSensorsInfo();
                }).catch(e => {
                    this.$message({
                        message: `删除失败，请联系管理员。`,
                        type: 'warning',
                    })
                });

            })

        }
    },
    mounted() {
        this.getCloudSensorsInfo();
        let para = {
            customerid: this.customerid
        };
        this.axios.post("/cloudmanagement/getclouddevices", para).then(reply => {
            this.devicemodels = reply.data.values.devices;
        });
    }
};
</script>

<style scoped>
.haslink {
    text-decoration: underline;
    color: #4091ff;
    cursor: pointer;
}
</style>